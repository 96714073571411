import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Chemical from "../Assets/chemical.jpg";
import Recycle from "../Assets/recycle.jpg";
import Med from "../Assets/Medicines.jpg";
import { Icon } from "@iconify/react";
function Introduction() {
  useEffect(() => {
    window.scroll(0, 0);
  });
  const intro = [
    {
      icon: (
        <Icon
          icon="fluent-emoji-high-contrast:test-tube"
          className="intro-icons"
        />
      ),
      key: 1,
      name: "Chemicals",
      linkto: "/productsList",
      link: "/productsList",
      img: "https://i.ibb.co/tZNjBBS/Glassmorphism-Zero-Waste-Webinar-Instagram-Post-4.png",
      backimg: Chemical,
      text: "Browse through our comprehensive range of chemicals, carefully curated to provide solutions for various industries. From specialty compounds to essential reagents.",
    },

    {
      icon: <Icon icon="fa:recycle" className="intro-icons" />,
      key: 2,
      name: "Re-Cycling",
      linkto: "/recycle",
      link: "/recycle",
      img: "https://i.ibb.co/7WfbL4Z/Glassmorphism-Zero-Waste-Webinar-Instagram-Post-5.png",
      backimg: Recycle,
      text: "Join us in the mission of sustainability by recycling with us. Our dedicated recycling program ensures that materials like scraps and e-waste are responsibly handled and transformed.",
    },
    {
      icon: <Icon icon="game-icons:medicine-pills" className="intro-icons" />,
      name: "APIs",
      key: 3,
      linkto: "/api",
      link: "/api",
      img: "https://i.ibb.co/7WfbL4Z/Glassmorphism-Zero-Waste-Webinar-Instagram-Post-5.png",
      backimg: Med,
      text: "Active pharmaceutical ingredients (APIs) have pharmacological activity mainly used with combination of other ingredients to diagnose, cure, mitigate, and treat the disease.",
    },
  ];
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className="intro-container">
        <div className="intro-text">
          <div className="intro"></div>
        </div>
      </div>
      <div className="intro-section-bottom">
        {intro.map((item) => (
          <Link to={item.linkto}>
            <div
              className="intro-section-info"
              key={item.key}
              style={{
                background: `linear-gradient(rgba(0,0,0,0.3),rgba(0,0,0,0.3)) ,url(${item.backimg})`,
                backgroundSize: "100% 100%",

                backgroundRepeat: "no-repeat",
              }}
            >
              <h2>
                {item.icon}
                {item.name}
              </h2>

              {/* <img src={item.img} alt="" /> */}
              <p>{item.text}</p>
              <Link
                className="secondary-button"
                to={item.link}
                style={{ textDecoration: "none", width: "12rem" }}
              >
                Explore More
              </Link>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default Introduction;
