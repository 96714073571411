import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
function Iodine() {
  // const one = [
  //   {
  //     name: "",
  //     uni: "1",
  //     img: "http://www.adanipharma.com/images/product/iodine/1.jpg",
  //   },
  //   {
  //     name: "",
  //     uni: "2",
  //     img: "http://www.adanipharma.com/images/product/iodine/01.jpg",
  //   },
  // ];
  const occref = useRef(null);
  const dsoccref = useRef(null);
  const onpref = useRef(null);
  const tissueref = useRef(null);
  const location = useLocation();
  useEffect(() => {
    window.scroll(0, 0);
    if (location.hash === "#scrollocc") {
      occref.current.scrollIntoView({ behaviour: "smooth" });
    }
    if (location.hash === "#scrolldsocc") {
      dsoccref.current.scrollIntoView({ behaviour: "smooth" });
    }
    if (location.hash === "#scrollonp") {
      onpref.current.scrollIntoView({ behaviour: "smooth" });
    }
    if (location.hash === "#scrolltissue") {
      tissueref.current.scrollIntoView({ behaviour: "smooth" });
    }
  }, [location]);
  return (
    <div>
      <div className="work-section-wrapper">
        <div className="work-section-top">
          <h1 className="primary-mainheading">Iodine and it's Derivatives</h1>
        </div>
        <br></br>
        <br></br>
      </div>

      <div className="work-section-top" ref={occref} id="scrollocc">
        <h2 className="primary-heading">
          DI-IODOHYDROXY QUINOLINE (IODOQUINOL)
        </h2>
        <div className="fruit-images">
          <div className="fruit1" style={{ display: "flex" }}>
            <div className="one">
              <img
                src="http://www.adanipharma.com/images/product/iodine/1.jpg"
                alt="is-corn-grain-1544222258"
                border="0"
              />
              <p>Molecular Structure</p>
            </div>
            <div className="one">
              <img
                src="http://www.adanipharma.com/images/product/iodine/01.jpg"
                alt="is-corn-grain-1544222258"
                border="0"
              />
              <p>Product Image</p>
            </div>
          </div>
        </div>

        <p>
          Uses of Product<br></br>
          It acts as anamebicidal and so used in the treatment of amoebiasis,
          Balantidiasis (an infection caused by protozoa).
        </p>
      </div>

      <div className="line-dividing"></div>

      <div className="work-section-top" id="scrolldsocc" ref={dsoccref}>
        <h2 className="primary-heading">
          Iodochloro hydroxy quinoline (Clioquionol)
        </h2>
        <div className="fruit-images">
          <div className="fruit1" style={{ display: "flex" }}>
            <div className="one">
              <img
                src="http://www.adanipharma.com/images/product/iodine/2.jpg"
                alt="is-corn-grain-1544222258"
                border="0"
              />
              <p>Molecular Structure</p>
            </div>
            <div className="one">
              <img
                src="http://www.adanipharma.com/images/product/iodine/02.jpg"
                alt="is-corn-grain-1544222258"
                border="0"
              />
              <p>Product Image</p>
            </div>
          </div>
        </div>
        <p>
          Uses of Product<br></br> It has primarily been used as an
          anti-diarrheal medication. It is used in the treatment of acute and
          chronic amoebic dysentery, colitis, in GItrack infections like
          amoebiasis, in dermatophytosis, mycosis barbae. seborrhoeicdermatitis,
          infected eczema, furunculosis and pityriasis versicoior athlete's
          foot, ermatitis, Impetigo, monilial and trichomonas vaginitis,
          Giardiasis.
        </p>
      </div>
      <div className="line-dividing"></div>

      <div className="work-section-top" id="scrollonp" ref={onpref}>
        <h2 className="primary-heading">Povidone Iodine India</h2>
        <div className="fruit-images">
          <div className="fruit1" style={{ display: "flex" }}>
            <div className="one">
              <img
                src="http://www.adanipharma.com/images/product/iodine/3.jpg"
                alt="is-corn-grain-1544222258"
                border="0"
              />
              <p>Molecular Structure</p>
            </div>
            <div className="one">
              <img
                src="http://www.adanipharma.com/images/product/iodine/03.jpg"
                alt="is-corn-grain-1544222258"
                border="0"
              />
              <p>Product Image</p>
            </div>
          </div>
        </div>
        <p>
          Product Usage<br></br>
          <ul>
            <li>
              It is often used to prevent surgical skin infection in any major
              operations. As a surgical scrub, it is broadly applicable for pre
              and post operative skin cleansing.
            </li>
            <li>It works like antibacterial in dental procedures.</li>
            <li>
              Works great as antiseptic cleanser for surgeons before surgery.
            </li>
            <li>
              Most suitable for the treatment and prevention against infection
              in ulcers, burns and cuts.
            </li>
            <li>
              Effective against the treatment of wounds, yeasts, molds, fungi,
              protozoans, viruses, etc.
            </li>
            <li>
              It is used as a dispersing and suspending agent in drugs and as a
              plasma volume expander
            </li>
          </ul>
        </p>
      </div>
      <div className="line-dividing"></div>

      <div className="work-section-top">
        <h2 className="primary-heading" id="scrolltissue" ref={tissueref}>
          Potassium Iodide
        </h2>
        <div className="fruit-images">
          <div className="fruit1">
            <div className="one">
              <img
                src="http://www.adanipharma.com/images/product/iodine/04.jpg"
                alt="is-corn-grain-1544222258"
                border="0"
              />
              <p>Product Image</p>
            </div>
          </div>
        </div>
        <p>
          Uses of Product<br></br>
          We are global and integrated potassium iodide salts exporters in India
          selling premium range of safe products, at very competitive process.
          We also give custom solutions based on clients’ requirements. Our
          products are available in different grades as per industry usage.
          Being most prominent potassium iodide salts exporters in India, we
          strongly believe in giving quality to our clients not quantity. Our
          quality range of potassium iodide salts is suitable against yeasts,
          molds, fungi, viruses, and protozoan. It is also good for treating
          skin infections and wound treatments.
        </p>
      </div>
      <div className="line-dividing"></div>

      <div className="work-section-top">
        <h2 className="primary-heading">Sodium Iodide</h2>
        <div className="fruit-images">
          <div className="fruit1">
            <div className="one">
              <img
                src="http://www.adanipharma.com/images/product/iodine/06.jpg"
                alt="is-corn-grain-1544222258"
                border="0"
              />
              <p>Product Image</p>
            </div>
          </div>
        </div>
        <p>
          Uses of Product<br></br>
          It is used to treat and prevent iodine deficiency. It is used in
          thyroid function tests and thyroid imaging and to treat
          hyperthyroidism and thyroid carcinoma.
        </p>
      </div>
      <div className="line-dividing"></div>

      <div className="work-section-top">
        <h2 className="primary-heading">Calcium Iodate</h2>
        <div className="fruit-images">
          <div className="fruit1" style={{ display: "flex" }}>
            <div className="one">
              <img
                src="http://www.adanipharma.com/images/product/iodine/7.jpg"
                alt="is-corn-grain-1544222258"
                border="0"
              />
              <p>Molecular Structure</p>
            </div>
            <div className="one">
              <img
                src="http://www.adanipharma.com/images/product/iodine/07.jpg"
                alt="is-corn-grain-1544222258"
                border="0"
              />
              <p>Product Image</p>
            </div>
          </div>
        </div>
        <p>
          Uses of Product<br></br> It is used as a dough conditioner. It is an
          oxidant added to lotions and ointments as an antiseptic, disinfectant
          and deodorant. It is used in animal feed to correct iodine deficiency
          and prevent various animal diseases. In food industry it is used for
          iodisation of table salt.
        </p>
      </div>
    </div>
  );
}

export default Iodine;
