import React, { useEffect, useState } from "react";

function LubMachine() {
  const [activeIndex, setActiveIndex] = useState(null);
  const [subActiveIndex, setSubActiveIndex] = useState(null);

  const handleAccordionClick = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
    setSubActiveIndex(null);
  };

  const handleSubAccordionClick = (index) => {
    setSubActiveIndex(subActiveIndex === index ? null : index);
  };
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div>
      <div className="lubricant">
        <h2 className="primary-subheading">General Machining</h2>
        <p className="primary-information">
          Industry uses variety of machining operations to get their desired
          components. Machining operations are wide and complex ranging from
          making sheets, fasteners to designing complex gear sets and precision
          components.
        </p>
        <p className="primary-information">
          Kyros has a range of products suitable for variety of machining
          operations, whether it’s turning, milling, deep drilling, tapping or
          grinding. Kyros Lubricants range of neat cutting fluids, soluble
          cutting oils and forming oils have been formulated to provide better
          tool life and longer oil sump life, keeping material compatibility in
          focus.
        </p>
      </div>
      <div className="auto-lubricant">
        <div className="auto-lubricant-text">
          <h2>Compressor</h2>
          <img
            src="https://www.bharatpetroleum.in/images/COMPRESSOR%20OILS.jpg"
            alt=""
          />
        </div>
        <div className="accordion">
          <div className="accordion-item">
            <div
              className="accordion-header"
              onClick={() => handleAccordionClick(0)}
            >
              <h3>Compressor Oil</h3>
              <span>{activeIndex === 0 ? "-" : "+"}</span>
            </div>
            {activeIndex === 0 && (
              <div className="accordion-body">
                Superior quality reciprocating air compressor oil
                <br></br>
                Kyros Compressor Oils are high quality, high performance mineral
                oils developed for reciprocating air compressors. They are
                formulated from highly refined, high viscosity index base stocks
                and high performance additives with ashless chemistry.
                Reciprocating compressors operate at high temperatures and
                pressures. The lubricant creates a strong film in order to avoid
                surface wear. These oils have exceptional resistance to
                oxidation and thermal degradation. The thermal stability and
                oxidation resistance of these fluids can help maintain cleaner
                compressors, thereby enabling longer running periods between
                scheduled maintenance and oil changes.
                <div className="sub-accordion">
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(0)}
                    >
                      <h4>Specifications</h4>
                      <span>{subActiveIndex === 0 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 0 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>DIN 51 506 - VDL</li>
                          <li>ISO / DP 6521</li>
                          <li>
                            IS 13256:1992 DAA & DAB type [Reaffirmed 1998]
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(1)}
                    >
                      <h4>Applications</h4>
                      <span>{subActiveIndex === 1 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 1 && (
                      <div className="sub-accordion-body">
                        For high performance reciprocating & rotary air
                        compressors where air discharge temperatures may go up
                        to 220°C.
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(2)}
                    >
                      <h4>Benefits</h4>
                      <span>{subActiveIndex === 2 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 2 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>
                            Longer Oil Life- This oil has strong oxidation
                            resistance and hence is less prone to degradation
                          </li>
                          <li>
                            Lower Maintenance costs: oil has lesser tendency to
                            form sludge & varnish and hence it provides trouble
                            free operation and lower maintenance.
                          </li>
                          <li>
                            Extended maintenance intervals: due to reduced
                            deposit formation on valves, the maintenance
                            intervals for valves and other components increase.
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="accordion-item">
            <div
              className="accordion-header"
              onClick={() => handleAccordionClick(1)}
            >
              <h3>Screwcom</h3>
              <span>{activeIndex === 1 ? "-" : "+"}</span>
            </div>
            {activeIndex === 1 && (
              <div className="accordion-body">
                Premium quality oils for rotary screw air compressors
                <br></br> Kyros Screwcom Oils are high quality, high performance
                mineral oils developed for rotary vane and screw air
                compressors. They are formulated from highly refined, high
                viscosity index base stocks and high performance ashless
                additives. These oils have exceptional resistance to oxidation
                and thermal degradation. The thermal stability and oxidation
                resistance of these fluids can help maintain cleaner
                compressors, thereby enabling longer running periods between
                scheduled maintenance and oil changes.
                <div className="sub-accordion">
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(0)}
                    >
                      <h4>Specifications</h4>
                      <span>{subActiveIndex === 0 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 0 && (
                      <div className="sub-accordion-body">
                        Proprietary grades
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(1)}
                    >
                      <h4>Applications</h4>
                      <span>{subActiveIndex === 1 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 1 && (
                      <div className="sub-accordion-body">
                        Recommended for oil flooded or oil injected, single or
                        two-stage screw compressors powered by Electricity or
                        Diesel
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(2)}
                    >
                      <h4>Benefits</h4>
                      <span>{subActiveIndex === 2 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 2 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>
                            Excellent anti-wear & higher load carrying capacity.
                          </li>
                          <li>Outstanding oxidation and thermal stability</li>
                          <li>Excellent water separation characteristics</li>
                          <li>
                            Prevents rust and corrosion of internal compressor
                            components
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="accordion-item">
            <div
              className="accordion-header"
              onClick={() => handleAccordionClick(2)}
            >
              <h3>Syncom</h3>
              <span>{activeIndex === 2 ? "-" : "+"}</span>
            </div>
            {activeIndex === 2 && (
              <div className="accordion-body">
                Advanced synthetic oils for rotary air compressors
                <br></br>
                Kyros Syncom Oils are advanced fully synthetic oils developed
                for rotary vane and screw air compressors. They are formulated
                from high viscosity index synthetic base stocks (PAO) and high
                performance advanced ashless additives to deliver the highest
                performance lubrication. These oils have outstanding resistance
                to oxidation and thermal degradation. The extremely low level of
                deposit formation help maintain internal surface cleanliness,
                thereby enabling longer running periods between scheduled
                maintenance and oil changes.
                <div className="sub-accordion">
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(0)}
                    >
                      <h4>Specifications</h4>
                      <span>{subActiveIndex === 0 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 0 && (
                      <div className="sub-accordion-body">
                        Proprietary grades
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(1)}
                    >
                      <h4>Applications</h4>
                      <span>{subActiveIndex === 1 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 1 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>
                            Recommended for oil flooded or oil injected, single
                            or multi-stage screw compressors.
                          </li>
                          <li>
                            These oils are suitable where air discharge
                            temperatures can go in excess of 100OC and provides
                            drain cycles of up to 8000 hours under standard
                            conditions of use.
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(2)}
                    >
                      <h4>Benefits</h4>
                      <span>{subActiveIndex === 2 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 2 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>
                            Excellent anti-wear & higher load carrying capacity.
                          </li>
                          <li>Outstanding oxidation and thermal stability</li>
                          <li>Excellent water separation characteristics</li>
                          <li>
                            Prevents rust and corrosion of internal compressor
                            components
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Third */}
      <div className="auto-lubricant">
        <div className="auto-lubricant-text">
          <h2>Gears</h2>
          <img
            src="https://www.bharatpetroleum.in/images/220x220-GEARS.jpg"
            alt=""
          />
        </div>
        <div className="accordion">
          <div className="accordion-item">
            <div
              className="accordion-header"
              onClick={() => handleAccordionClick(7)}
            >
              <h3>Kyros Amocam</h3>
              <span>{activeIndex === 7 ? "-" : "+"}</span>
            </div>
            {activeIndex === 7 && (
              <div className="accordion-body">
                Premium quality high viscosity index, extreme pressure oils for
                industrial gears
                <br></br>
                Kyros Amocam is a range of premium quality, extreme pressure
                industrial gear lubricants blended from high viscosity index
                solvent refined high quality base oils. The superior quality
                sulphur-phosphorus additive chemistry imparts high level of
                antiwear and extreme pressure property. Their high load carrying
                capacity and anti-friction property offer excellent performance
                in industrial gears and other industrial applications.
                <div className="sub-accordion">
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(0)}
                    >
                      <h4>Specification</h4>
                      <span>{subActiveIndex === 0 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 0 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>IS 8406: 1993 (EP Type) [Reaffirmed 2001]</li>
                          <li>IPSS: 1-09-003 • US Steel 224</li>
                          <li>David Brown S.1.53.101</li>
                          <li>DIN 51 517 (Part 3)</li>
                          <li>AGMA 250.04 5EP & AGMA 251.02 5EP</li>
                        </ul>
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(1)}
                    >
                      <h4>Applications</h4>
                      <span>{subActiveIndex === 1 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 1 && (
                      <div className="sub-accordion-body">
                        Recommended for the lubrication of all types of
                        industrial enclosed gear drives operating at high load &
                        speed conditions. Also recommended for the lubrication
                        of worm gear units. Bearing Lubrication where EP type
                        Oil is recommended.
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(2)}
                    >
                      <h4>Benefits</h4>
                      <span>{subActiveIndex === 2 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 2 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>
                            Selected additive chemistry offers extended die /
                            tool life and minimizes down time
                          </li>

                          <li>
                            Provides good surface finish and proper sheared
                            straight edges in fine blanked components.
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="accordion-item">
            <div
              className="accordion-header"
              onClick={() => handleAccordionClick(8)}
            >
              <h3>Kyros Amocam Plus</h3>
              <span>{activeIndex === 8 ? "-" : "+"}</span>
            </div>
            {activeIndex === 8 && (
              <div className="accordion-body">
                Advanced extreme pressure, anti-micropitting oils for heavy duty
                industrial gears
                <br></br>
                Kyros Amocam Plus is a range of advanced, heavy duty, extreme
                pressure industrial gear lubricants blended from high viscosity
                index and high quality base stocks. These oils have been
                developed to deliver optimum value through enhanced wear
                protection, long oil life and high system efficiency. The
                superior quality additive chemistry imparts high level of
                antiwear and extreme pressure property. Their high load carrying
                capacity and anti-friction property offer excellent performance
                in industrial gears and other industrial applications.
                <div className="sub-accordion">
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(0)}
                    >
                      <h4>Specifications</h4>
                      <span>{subActiveIndex === 0 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 0 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>IS 8406: 1993 (EP Type) [Reaffirmed, 2001]</li>
                          <li>US Steel 224 • IPSS:1-09-003</li>
                          <li>DIN 51 517 (Part 3)</li>
                          <li>DAVID BROWN S. 1. 53. 101</li>
                          <li>AGMA 250.04 5EP & AGMA 251.02 5EP</li>
                          <li>Micro pitting performance - Flender AG</li>
                        </ul>
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(1)}
                    >
                      <h4>Applications</h4>
                      <span>{subActiveIndex === 1 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 1 && (
                      <div className="sub-accordion-body">
                        Used for the lubrication of all types of industrial
                        enclosed gear drives operating at high load & speed
                        conditions. The need of such gear oil increases wherever
                        there is possibility of shock loads as in case of
                        enclosed spur, helical, herring bone, bevel and
                        planetary gearboxes.
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(2)}
                    >
                      <h4>Benefits</h4>
                      <span>{subActiveIndex === 2 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 2 && (
                      <div className="sub-accordion-body">
                        Premium quality EP type industrial gear oil, which meets
                        Flender’s requirement for anti-micro pitting gear oil to
                        take care of shock loads.
                        <br></br>
                        Major Approvals:
                        <br></br>
                        <ul>
                          <li>
                            Kyros Amocam Plus 320 - Bhushan Steel for Gear box
                            lubrication in the gear box of their uncoiler and
                            Walchandnagar Inds for their Cement Division.
                          </li>
                          <li>
                            Kyros Amocam Plus 460 - Walchandnagar Inds, Cement
                            Division.
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="accordion-item">
            <div
              className="accordion-header"
              onClick={() => handleAccordionClick(9)}
            >
              <h3>Kyros Syngear</h3>
              <span>{activeIndex === 9 ? "-" : "+"}</span>
            </div>
            {activeIndex === 9 && (
              <div className="accordion-body">
                Advanced synthetic oils for heavy duty industrial gears
                <br></br>
                Kyros Syngear is a range of fully synthetic gear oils with
                outstanding lubrication performance. They are formulated from
                special quality wax-free polyalphaolefin (PAO) synthetic base
                stocks that have exceptional oxidation resistance and thermal
                properties, high viscosity index and excellent low temperature
                fluidity. Their high load carrying capacity and anti-friction
                property offer excellent performance in industrial gears and
                other industrial applications. The high viscosity index and low
                traction coefficient of these oils combine to provide a
                potential reduction in power consumption in many gear systems.
                <div className="sub-accordion">
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(0)}
                    >
                      <h4>Specifications</h4>
                      <span>{subActiveIndex === 0 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 0 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>U.S. Steel 224</li>
                          <li>AGMA 9005-E-02</li>
                          <li>DIN 51517 Part 3</li>
                          <li>Micro-pitting performance - Flender AG</li>
                          <li>David Brown S1.53.101 Type E</li>
                          <li>FAG FE8 test</li>
                        </ul>
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(1)}
                    >
                      <h4>Applications</h4>
                      <span>{subActiveIndex === 1 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 1 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>
                            {" "}
                            Heavily loaded enclosed spur, helical, herringbone,
                            bevel and planetary gearboxes.
                          </li>
                          <li>
                            Application where the OEMs recommends synthetic
                            extreme pressure gear oil.
                          </li>
                          <li>
                            Industrial gear drives where full extreme pressure
                            and micro-pitting performance due to heavy / shock
                            loading is needed.
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(2)}
                    >
                      <h4>Benefits</h4>
                      <span>{subActiveIndex === 2 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 2 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li> High level anti-wear performance</li>
                          <li>Extreme pressure property</li>
                          <li>Micro-pitting performance</li>
                          <li>Excellent oxidation & thermal stability</li>
                          <li>Good low temperature properties</li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="accordion-item">
            <div
              className="accordion-header"
              onClick={() => handleAccordionClick(10)}
            >
              <h3>Kyros Syngear PG</h3>
              <span>{activeIndex === 10 ? "-" : "+"}</span>
            </div>
            {activeIndex === 10 && (
              <div className="accordion-body">
                Kyros SYNGEAR PG 320 is a synthetic gear lubricant based on the
                Polyalkylene Glycol base fluid and selected additives.
                <br></br>
                Polyalkylene Glycols (PAGs) are homopolymers of ethylene oxide
                or propylene oxide, or copolymers of ethylene oxide or propylene
                oxide. Because of the unique structure, PAG’s possess a low
                friction coefficient. Polyglycolbased gear oils offer
                significant advantages over mineral oils when a lot of sliding
                friction occurs in gearboxes and lowers oil temperatures that in
                turn extend oil change intervals. PAG based gear oils are used
                whenever mineral gear oils have reached their performance limit
                and can no longer meet the application requirements
                <div className="sub-accordion">
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(1)}
                    >
                      <h4>Applications</h4>
                      <span>{subActiveIndex === 1 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 1 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>
                            PAG gear oils are recommended for highly stressed
                            crown, bevel, planetary and worm drive gears
                          </li>
                          <li>
                            Poly glycol based gear oils offer significant
                            advantages over mineral oils when a lot of sliding
                            friction occurs in gearboxes and lowers oil
                            temperatures that in turn extend oil change
                            intervals.
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(2)}
                    >
                      <h4>Benefits</h4>
                      <span>{subActiveIndex === 2 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 2 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>High level anti-wear performance</li>
                          <li>Extreme pressure property</li>
                          <li>Micro-pitting performance</li>
                          <li>Excellent oxidation & thermal stability</li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* {Fourth} */}
      <div className="auto-lubricant">
        <div className="auto-lubricant-text">
          <h2>Grease</h2>
          <img
            src="https://www.bharatpetroleum.in/images/220x220-GREASE.jpg"
            alt=""
          />
        </div>
        <div className="accordion">
          <div className="accordion-item">
            <div
              className="accordion-header"
              onClick={() => handleAccordionClick(11)}
            >
              <h3>About</h3>
              <span>{activeIndex === 11 ? "-" : "+"}</span>
            </div>
            {activeIndex === 11 && (
              <div className="accordion-body">
                the properties and characteristics of the grease, such as its
                viscosity, consistency, temperature range, and compatibility
                with different types of materials. It may also include details
                on the intended application of the grease and the specific
                benefits it provides, such as reducing friction, preventing wear
                and tear, and extending the lifespan of the machinery
              </div>
            )}
          </div>
          <div className="accordion-item">
            <div
              className="accordion-header"
              onClick={() => handleAccordionClick(12)}
            >
              <h3>Kyros MP Grease</h3>
              <span>{activeIndex === 12 ? "-" : "+"}</span>
            </div>
            {activeIndex === 12 && (
              <div className="accordion-body">
                High quality multipurpose grease for automotive and industrial
                application
                <br></br>
                Kyros MP Grease is a premium quality lithium based multipurpose
                grease manufactured from superior quality base oils. This grease
                is fortified with a well-balanced additive chemistry comprising
                of anti-oxidant, anti-wear and anti-rust additives making it
                suitable for a wide range of general purpose applications. The
                grease also provides good resistance to water wash-out. It
                offers mechanical stability and is suitable for use in
                applications experiencing moderate temperatures and moderate
                loads.
                <div className="sub-accordion">
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(0)}
                    >
                      <h4>Specification</h4>
                      <span>{subActiveIndex === 0 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 0 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>IS 7623:1993</li>
                          <li>IPSS: 1-09-006-97</li>
                        </ul>
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(1)}
                    >
                      <h4>Applications</h4>
                      <span>{subActiveIndex === 1 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 1 && (
                      <div className="sub-accordion-body">
                        It is recommended for the lubrication of heavy-duty
                        plain and roller bearings operating under heavy loads,
                        moderate temperature and shock loading conditions
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(2)}
                    >
                      <h4>Benefits</h4>
                      <span>{subActiveIndex === 2 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 2 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>
                            Good Oxidation Stability- Gives enhanced life to the
                            grease lubricant and minimum changing intervals
                          </li>

                          <li>
                            High Drop point- This grease has a high drop point
                            values, which makes grease usable at moderate
                            temperatures without any concern of oil coming out
                            of the grease and hence can lubricate efficiently
                            for longer period
                          </li>
                          <li>
                            Good Structural Stability- Provides grease with
                            extra ability to resist early shear and work longer
                            during harsh working conditions
                          </li>
                        </ul>
                        Major Approvals:
                        <ul>
                          <li>
                            Kyros MP Grease 2 is approved by Walchandnagar
                            Inds-Gear Division.
                          </li>
                          <li>
                            Kyros MP Grease 3 is approved by Walchandnagar Inds
                            for its Cement Division, Sugar Division and Gear
                            Division.
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="accordion-item">
            <div
              className="accordion-header"
              onClick={() => handleAccordionClick(13)}
            >
              <h3>Kyros Lanthax EP Grease</h3>
              <span>{activeIndex === 13 ? "-" : "+"}</span>
            </div>
            {activeIndex === 13 && (
              <div className="accordion-body">
                Premium quality extreme pressure grease for automotive and
                industrial application
                <br></br>
                Kyros Lanthax EP Grease is a premium quality lithium based
                grease containing extreme pressure additives. It is formulated
                with high quality mineral base oils and lithium soap thickener.
                This grease is suitable for situations warranting the use of
                extreme pressure grease. Kyros Lanthax EP Grease is designed to
                handle the most severe loads in a wide range of automotive and
                industrial applications. The grease has a recommended operating
                temperature range from -20O C to 130O C. Kyros Lanthax EP Grease
                has high resistance to mechanical breakdown, excellent oxidation
                resistance, good rust protection, and excellent water washout
                resistance.
                <div className="sub-accordion">
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(0)}
                    >
                      <h4>Specifications</h4>
                      <span>{subActiveIndex === 0 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 0 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li> IS 7623 : 1993 EP Type [Reaffirmed 2001]</li>
                          <li>IPSS : 1-09-005-99</li>
                        </ul>
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(1)}
                    >
                      <h4>Applications</h4>
                      <span>{subActiveIndex === 1 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 1 && (
                      <div className="sub-accordion-body">
                        This grease is recommended for all plain & roller
                        bearings in equipment operating under severe shock
                        loading conditions and temperatures up to about 130°C
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(2)}
                    >
                      <h4>Benefits</h4>
                      <span>{subActiveIndex === 2 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 2 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>
                            Good Oxidation Stability- Gives enhanced life to the
                            grease lubricant and minimum changing intervals
                          </li>
                          <li>
                            Excellent Load Carrying abilities- this grease has
                            good extreme pressure properties, making it suitable
                            to protect and lubricate the machine components even
                            under high loads
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* Fifth */}
      <div className="auto-lubricant">
        <div className="auto-lubricant-text">
          <h2>Machining</h2>
          <img
            src="https://www.bharatpetroleum.in/images/Machining-Oils.jpg"
            alt=""
          />
        </div>
        <div className="accordion">
          <div className="accordion-item">
            <div
              className="accordion-header"
              onClick={() => handleAccordionClick(14)}
            >
              <h3>Kyros Multisynth</h3>
              <span>{activeIndex === 14 ? "-" : "+"}</span>
            </div>
            {activeIndex === 14 && (
              <div className="accordion-body">
                Premium fully synthetic metalworking fluid for moderate duty
                machining applications
                <br></br>
                Kyros Multisynth is an advanced fully synthetic premium quality
                soluble metalworking fluid. It offers optimum cooling and
                lubricating performance to the machining operations. Extreme
                pressure additive and lubricity agents can help reduce tool
                regrinding and component rejects. It forms clear and stable
                emulsion with soft and hard water (400 ppm) as well. It also has
                superior tramp oil rejection capability.
                <div className="sub-accordion">
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(0)}
                    >
                      <h4>Specification</h4>
                      <span>{subActiveIndex === 0 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 0 && (
                      <div className="sub-accordion-body">
                        Proprietary Grade
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(1)}
                    >
                      <h4>Applications</h4>
                      <span>{subActiveIndex === 1 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 1 && (
                      <div className="sub-accordion-body">
                        Recommended for general purpose grinding and light to
                        medium duty machining operations on both ferrous and
                        non-ferrous materials
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(2)}
                    >
                      <h4>Benefits</h4>
                      <span>{subActiveIndex === 2 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 2 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>
                            Forms stable emulsion in hard water up to 400 ppm
                            and provides excellent corrosion protection to the
                            machined parts.
                          </li>
                          <li>
                            Special extreme pressure additives to reduce the
                            coefficient of friction between the tool and the
                            work-piece interface.
                          </li>
                          <li>
                            Excellent detergency properties, preventing sticky
                            residue/scum formation on machine internal surfaces.
                          </li>
                          <li>Superior tramp oil rejection performance.</li>
                          <li>
                            Chlorine, phenol and nitrite, secondary amine free
                            formulation, for environmental compliance.
                          </li>
                          <li>
                            Being fully synthetic provides good heat dissipation
                            characteristics.
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="accordion-item">
            <div
              className="accordion-header"
              onClick={() => handleAccordionClick(15)}
            >
              <h3>Kyros Norcam</h3>
              <span>{activeIndex === 15 ? "-" : "+"}</span>
            </div>
            {activeIndex === 15 && (
              <div className="accordion-body">
                Superior metalworking fluids for moderate duty machining of a
                wide range of metals
                <br></br>
                Kyros Norcam range is a group of premium quality, transparent
                inactive cutting oils for a wide range of applications. They are
                blended from highly refined, high viscosity index base oils with
                extreme pressure additive. Compatibility with environmental
                requirements, inactive chemistry of the additives allows these
                oils to perform efficiently with ferrous and non-ferrous metals.
                <div className="sub-accordion">
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(0)}
                    >
                      <h4>Specifications</h4>
                      <span>{subActiveIndex === 0 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 0 && (
                      <div className="sub-accordion-body">
                        Proprietary Grade
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(1)}
                    >
                      <h4>Applications</h4>
                      <span>{subActiveIndex === 1 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 1 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>
                            These oils are used for machining yellow metals.
                            Suitable for less severe classes of machining on
                            ferrous metals and are excellent for automatic
                            lathes, screw cutting machines etc.
                          </li>
                          <li>
                            Kyros NORCAM E & G oils are recommended for use in
                            wide range of machining operations on medium
                            machinability metals and also for severe operations
                            on certain highly machinable steels and alloys.
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(2)}
                    >
                      <h4>Benefits</h4>
                      <span>{subActiveIndex === 2 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 2 && (
                      <div className="sub-accordion-body">
                        <br></br>
                        <ul>
                          <li>
                            No staining of metals: Inactive sulphur does not
                            allow metal to get stained thereby enhancing tool
                            life.
                          </li>
                          <li>
                            Extreme pressure additives: Helps in maintaining a
                            strong lubricating film even at higher loads.
                          </li>
                          <li>
                            Good visibility: Light colour helps in good
                            observation of the processes.
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="accordion-item">
            <div
              className="accordion-header"
              onClick={() => handleAccordionClick(16)}
            >
              <h3>Kyros Sherol B</h3>
              <span>{activeIndex === 16 ? "-" : "+"}</span>
            </div>
            {activeIndex === 16 && (
              <div className="accordion-body">
                Superior multipurpose soluble metalworking fluid with excellent
                cooling, lubricity and rust protection
                <br></br>
                Kyros Sherol B is a premium quality soluble cutting oil blended
                from mineral oil and special additive to provide superior tool
                life and other benefits. It has excellent emulsion stability
                with hard water.
                <div className="sub-accordion">
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(0)}
                    >
                      <h4>Specifications</h4>
                      <span>{subActiveIndex === 0 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 0 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>IS 1115:1986 [Reaffirmed 2002]</li>
                          <li>UK DEFSTAN 91-23 for oil ZX-1 specifications</li>
                        </ul>
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(1)}
                    >
                      <h4>Applications</h4>
                      <span>{subActiveIndex === 1 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 1 && (
                      <div className="sub-accordion-body">
                        This Soluble Cutting Oil is recommended for use in a
                        variety of cutting operations like drilling, forming,
                        planing, milling, sawing and on ferrous and non-ferrous
                        materials. Used at water-to-oil ratios between 20:1 and
                        30:1.
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(2)}
                    >
                      <h4>Benefits</h4>
                      <span>{subActiveIndex === 2 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 2 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>
                            Excellent cooling and lubrication: High wetting
                            property enhances lubrication and helps in extending
                            tool life.
                          </li>
                          <li>
                            Low foaming tendency: Helps in maintaining
                            continuous oil film in between the moving parts thus
                            avoiding metallic contact.
                          </li>
                          <li>
                            Stable emulsion – Helps in getting extended life and
                            also resists bacterial growth. However, care should
                            be taken to avoid entry of Tramp Oil and oil should
                            be kept in circulation to extract maximum life.
                          </li>
                          <li>
                            Good rust protection – Carefully selected additive
                            protects machine parts from rusting.
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="accordion-item">
            <div
              className="accordion-header"
              onClick={() => handleAccordionClick(17)}
            >
              <h3>Kyros Sherol BN</h3>
              <span>{activeIndex === 17 ? "-" : "+"}</span>
            </div>
            {activeIndex === 17 && (
              <div className="accordion-body">
                High quality multipurpose soluble metalworking fluid with
                excellent cooling, lubricity and rust protection
                <br></br>
                Kyros Sherol BN is a superior grade soluble cutting oil blended
                from highly refined base oil and special additive to provide
                superior tool life and other benefits. It has excellent emulsion
                stability with hard water.
                <div className="sub-accordion">
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(0)}
                    >
                      <h4>Specifications</h4>
                      <span>{subActiveIndex === 0 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 0 && (
                      <div className="sub-accordion-body">
                        IS 1115: 1986 [Reaffirmed 2002]
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(1)}
                    >
                      <h4>Applications</h4>
                      <span>{subActiveIndex === 1 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 1 && (
                      <div className="sub-accordion-body">
                        Soluble cutting oil recommended for cutting operations
                        like drilling, forming, planing, milling, sawing on
                        ferrous materials. Used at water-to-oil ratios between
                        20:1 and 30:1
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(2)}
                    >
                      <h4>Benefits</h4>
                      <span>{subActiveIndex === 2 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 2 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>Excellent cooling and lubricating</li>
                          <li>Extended tool life</li>
                          <li>Low foaming tendency</li>
                          <li>Easy emulsification - stable emulsions</li>
                          <li>Good rust protection - machine and parts</li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* Sixth */}
      <div className="auto-lubricant">
        <div className="auto-lubricant-text">
          <h2>Hydraulic Systems</h2>
          <img
            src="https://www.bharatpetroleum.in/images/220x220-HYDRAULIC-OIL.jpg"
            alt=""
          />
        </div>
        <div className="accordion">
          <div className="accordion-item">
            <div
              className="accordion-header"
              onClick={() => handleAccordionClick(18)}
            >
              <h3>Hydrol</h3>
              <span>{activeIndex === 18 ? "-" : "+"}</span>
            </div>
            {activeIndex === 18 && (
              <div className="accordion-body">
                Industrial hydraulic fluid for superior performance and
                protection
                <br></br>
                Kyros Hydrol range is a group of premium quality, transparent
                and antiwear hydraulic oils. They are blended from highly
                refined, high viscosity index base oils with carefully selected
                antiwear additive. These oils are designed to operate over a
                wide range of working conditions including low load and severe
                high load conditions. High rate of water separation, exceptional
                hydrolytic stability, anti-foam and cleanliness allow efficient
                operation of the system.
                <div className="sub-accordion">
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(0)}
                    >
                      <h4>Specification</h4>
                      <span>{subActiveIndex === 0 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 0 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>IS 3098 : 1983 [Reaffirmed 2004]</li>
                          <li>IS 10522 : 1983 [Reaffirmed 2004]</li>
                        </ul>
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(1)}
                    >
                      <h4>Applications</h4>
                      <span>{subActiveIndex === 1 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 1 && (
                      <div className="sub-accordion-body">
                        These are recommended for spindle lubrication as well as
                        hydraulic systems requiring low viscosity grades and a
                        wide variety of circulation systems of industrial and
                        automotive equipment. These are also used in General
                        manufacturing, Metal and Power equipment.
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(2)}
                    >
                      <h4>Benefits</h4>
                      <span>{subActiveIndex === 2 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 2 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>
                            Outstanding Oxidation Stability: Reduces
                            consumption, filter blocking and valve sticking.
                          </li>
                          <li>
                            Good water and foam resistant properties: Separates
                            out water very fast in case of water ingression and
                            releases entrapped air immediately to avoid foaming.
                          </li>
                          <li>
                            Excellent Hydrolytic Stability: This avoids
                            absorption of water in oil and thus enhances fluid
                            life.
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="accordion-item">
            <div
              className="accordion-header"
              onClick={() => handleAccordionClick(19)}
            >
              <h3>Hydrol AW</h3>
              <span>{activeIndex === 19 ? "-" : "+"}</span>
            </div>
            {activeIndex === 19 && (
              <div className="accordion-body">
                Industrial antiwear hydraulic fluid for superior performance
                <br></br>
                Kyros Hydrol AW range is a group of premium quality, transparent
                and antiwear hydraulic oils. They are blended from
                hydro-processed, high viscosity index base oils with carefully
                selected antiwear and other additives. These oils are designed
                to operate over a wide range of working conditions including low
                load and severe high load conditions. High rate of water
                separation, exceptional hydrolytic stability, anti-foam and
                cleanliness allow efficient operation of the system. These oils
                ensures high level of antiwear property and film strength.
                <div className="sub-accordion">
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(0)}
                    >
                      <h4>Specifications</h4>
                      <span>{subActiveIndex === 0 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 0 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>IS 3098:1983 [Reaffirmed 1998]</li>
                          <li>IS 10522:1983 [Reaffirmed 1998]</li>
                          <li>IPSS : 1-09-022</li>
                          <li>Vickers V-104C Vane pump test</li>
                          <li>DIN 51 524 Part 1 HL Type</li>
                        </ul>
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(1)}
                    >
                      <h4>Applications</h4>
                      <span>{subActiveIndex === 1 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 1 && (
                      <div className="sub-accordion-body">
                        These oils are recommended for general hydraulic
                        equipment in control and power transmission systems and
                        for circulating equipment.
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(2)}
                    >
                      <h4>Benefits</h4>
                      <span>{subActiveIndex === 2 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 2 && (
                      <div className="sub-accordion-body">
                        <br></br>
                        <ul>
                          <li>
                            Fortified with very carefully selected anti-wear
                            additives
                          </li>
                          <li>
                            They also show exceptional hydrolytic stability and
                            demulsibility and resist any deterioration due to
                            water contamination.
                          </li>
                          <li>
                            These oils are compatible with seals normally used
                            in hydraulic systems.
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="accordion-item">
            <div
              className="accordion-header"
              onClick={() => handleAccordionClick(20)}
            >
              <h3>Hydrol CE</h3>
              <span>{activeIndex === 20 ? "-" : "+"}</span>
            </div>
            {activeIndex === 20 && (
              <div className="accordion-body">
                Premium ashless hydraulic fluid for long life and improved
                efficiency
                <br></br>
                Kyros Hydrol CE oils are high performance premium quality
                hydraulic oils blended from highly refined, high viscosity index
                Group II plus base stocks with carefully selected ashless
                (Zinc-free) antiwear additive. These oils are designed to
                operate over a wide range of working conditions including wide
                load fluctuation. They possess high FZG rating and provide
                outstanding protection and performance. Outstanding oxidation
                and thermal stability offers long oil life, a high degree of
                protection and minimised deposit formation.
                <div className="sub-accordion">
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(0)}
                    >
                      <h4>Specifications</h4>
                      <span>{subActiveIndex === 0 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 0 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>FZG Niemann EP 11th FLS</li>
                          <li>IS 11656 : 1986 [Reaffirmed 2002]</li>
                          <li>DIN 51524 Part 2 HLP type</li>
                          <li>Denison HF-0 & HF-2</li>
                          <li>Vickers 1-286-S US</li>
                          <li>Steel 127</li>
                          <li>Cincinnati Milacron P-70</li>
                        </ul>
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(1)}
                    >
                      <h4>Applications</h4>
                      <span>{subActiveIndex === 1 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 1 && (
                      <div className="sub-accordion-body">
                        Zinc free ash less type oil suitable for high pressure
                        hydraulic systems, pumps, industrial hydraulic
                        machineries operating at high speeds, loads and
                        temperatures.
                        <br></br>
                        Sophisticated high performance electro-hydraulic or
                        numerically controlled systems found in concrete mixture
                        units used in construction applications. Also suitable
                        for the lubrication of screw and vane type rotary
                        compressors.
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(2)}
                    >
                      <h4>Benefits</h4>
                      <span>{subActiveIndex === 2 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 2 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>
                            Excellent EP properties which leads to better
                            response to high load conditions
                          </li>
                          <li>
                            Extra heavy duty anti-wear oils with outstanding
                            thermal stability
                          </li>
                          <li>
                            Possess excellent compatibility with seals normally
                            used in hydraulic system.
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="accordion-item">
            <div
              className="accordion-header"
              onClick={() => handleAccordionClick(21)}
            >
              <h3>Hydrol HLP</h3>
              <span>{activeIndex === 21 ? "-" : "+"}</span>
            </div>
            {activeIndex === 21 && (
              <div className="accordion-body">
                Industrial extra heavy duty hydraulic fluid for long life,
                excellent performance and protection
                <br></br>
                Kyros Hydrol HLP oils are high performance premium quality
                hydraulic oils. They are blended from highly refined, high
                viscosity index Group II plus base oils with carefully selected
                balanced antiwear additive. These oils are designed to operate
                over a wide range of working conditions including low load and
                severe high load conditions. They provide outstanding protection
                and performance in manufacturing and other operations.
                <div className="sub-accordion">
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(0)}
                    >
                      <h4>Specifications</h4>
                      <span>{subActiveIndex === 0 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 0 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>11th FLS of FZG – Niemann EP test</li>
                          <li>IS 11656: 1986 [Reaffirmed March, 2002]</li>
                          <li>DIN 51 524 Part II HL P Type</li>
                          <li>Denison HF-0 & HF-2</li>
                          <li>Eaton Vickers I-286-S & M-2950-S</li>
                          <li>Eaton Vickers 35VQ25 Pump Test</li>
                          <li>US Steel 127</li>
                          <li>Cincinnati Milacron P-70</li>
                        </ul>
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(1)}
                    >
                      <h4>Applications</h4>
                      <span>{subActiveIndex === 1 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 1 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>
                            Recommended for hydraulic systems and a wide variety
                            of circulation systems of industrial and automotive
                            equipment.
                          </li>
                          <li>
                            Also used in General manufacturing, Metal and Power
                            equipment
                          </li>
                          <li>
                            Suitable for the lubrication of screw and vane type
                            rotary compressors recommended for mining and
                            construction equipment
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(2)}
                    >
                      <h4>Benefits</h4>
                      <span>{subActiveIndex === 2 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 2 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>
                            Outstanding Oxidation Stability: Reduces
                            consumption, filter blocking and valve sticking.
                          </li>
                          <li>
                            Good water and foam resistant properties: Separates
                            out water very fast in case of water ingression
                            releases entrapped air immediately to avoid foaming.
                          </li>
                          <li>
                            Excellent Hydrolytic Stability: This avoids
                            absorption of water in oil and thus enhances fluid
                            life.
                          </li>
                          <li>
                            Excellent Seal Compatibility: Prolongs seal life and
                            reduces maintenance.
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="accordion-item">
            <div
              className="accordion-header"
              onClick={() => handleAccordionClick(22)}
            >
              <h3>Hydrol HVLP</h3>
              <span>{activeIndex === 22 ? "-" : "+"}</span>
            </div>
            {activeIndex === 22 && (
              <div className="accordion-body">
                Industrial high viscosity index extra heavy duty hydraulic fluid
                for excellent performance over wide temperature range
                <br></br>
                Kyros Hydrol HVLP oils are high performance premium quality
                hydraulic oils. They are blended from hydroprocessed, high
                viscosity index Group II plus base oils with proven zinc based
                antiwear additive. These oils are designed with excellent
                viscosity control to operate over a wide range of working
                conditions including severe mechanical stress and wide
                temperature range. They provide outstanding protection and
                performance in manufacturing and other operations subjected to
                wide ranges of ambient and operating temperatures.
                <div className="sub-accordion">
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(0)}
                    >
                      <h4>Specifications</h4>
                      <span>{subActiveIndex === 0 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 0 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>IS 11656 : 1986 [Reaffirmed 2002]</li>
                          <li>
                            DIN 51 524 Part 3 – HVLP Type Denison HF-0 & HF-2
                          </li>
                          <li>Vickers 1-286-S</li>
                          <li>US Steel 127</li>
                          <li>Cincinnati Milacron P-70</li>
                        </ul>
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(1)}
                    >
                      <h4>Applications</h4>
                      <span>{subActiveIndex === 1 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 1 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>
                            Recommended for high pressure systems and power
                            transmission systems requiring very high viscosity
                            index oil, operating under high speeds, load and
                            temperatures.
                          </li>

                          <li>
                            Also recommended for hydraulic applications with
                            sophisticated high performance electro-hydraulic or
                            numerically controlled systems.
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(2)}
                    >
                      <h4>Benefits</h4>
                      <span>{subActiveIndex === 2 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 2 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>
                            Outstanding Anti-wear performance: Ensures minimum
                            wear throughout the range of operations.
                          </li>
                          <li>
                            Outstanding Oxidation Stability: Reduces
                            consumption, filter blocking and valve sticking.
                          </li>
                          <li>
                            Good water and foam resistant properties: Separates
                            out water very fast in case of water ingression
                          </li>
                          <li>Excellent Hydrolytic Stability</li>
                          <li>Excellent Seal Compatibility</li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* Seventh */}
      <div className="auto-lubricant">
        <div className="auto-lubricant-text">
          <h2>Sideways</h2>
          <img
            src="https://www.bharatpetroleum.in/images/220x220-Transformer-oils.jpg"
            alt=""
          />
        </div>
        <div className="accordion">
          <div className="accordion-item">
            <div
              className="accordion-header"
              onClick={() => handleAccordionClick(23)}
            >
              <h3>Kyros Metamol</h3>
              <span>{activeIndex === 23 ? "-" : "+"}</span>
            </div>
            {activeIndex === 23 && (
              <div className="accordion-body">
                Superior quality machine tool slideway lubricating oils
                <br></br>
                Kyros Metamol grades are high quality lubricating oils developed
                from high viscosity index base oil blended with carefully
                selected additive package. The high quality base stock combined
                with the additive package imparts the frictional characteristics
                necessary to prevent stick-slip and jerky motion. These oils
                have very high resistance to oxidation in order to enhance the
                operating life. They also offer excellent oiliness, wear
                resistance and non-drip characteristics. Kyros Metamol oils are
                compatible with the paints normally specified for use in
                industrial machine tool slide way systems with mineral oils.
                <div className="sub-accordion">
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(0)}
                    >
                      <h4>Specification</h4>
                      <span>{subActiveIndex === 0 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 0 && (
                      <div className="sub-accordion-body">
                        Proprietary Grade
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(1)}
                    >
                      <h4>Applications</h4>
                      <span>{subActiveIndex === 1 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 1 && (
                      <div className="sub-accordion-body">
                        Lubrication of slides & tables of machine tools for both
                        horizontal and vertical guide ways.
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(2)}
                    >
                      <h4>Benefits</h4>
                      <span>{subActiveIndex === 2 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 2 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>Exhibits very good oxidation stability</li>
                          <li>
                            Contains Anti-wear, non-drip and oiliness additive.
                          </li>
                        </ul>
                        Major Approvals:
                        <ul>
                          <li>
                            Kyros Metamol 32, 68 & 220 - RDSO for pneumatic
                            tools
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="accordion-item">
            <div
              className="accordion-header"
              onClick={() => handleAccordionClick(24)}
            >
              <h3>Metamol H</h3>
              <span>{activeIndex === 24 ? "-" : "+"}</span>
            </div>
            {activeIndex === 24 && (
              <div className="accordion-body">
                Superior quality lubricating oils for machine tool slideway and
                hydraulic systems
                <br></br>
                Kyros Metamol H grades are high quality lubricating oils
                developed for combined machine slideway and hydraulic systems.
                The high quality base stock combined with the additive package
                imparts the frictional characteristics necessary to prevent
                stick-slip and jerky motion. These oils have very high
                resistance to oxidation in order to enhance the operating life.
                They also offer excellent oiliness, wear resistance and non-drip
                characteristics. Kyros Metamol H oils are compatible with the
                paints normally specified for use in industrial machine tool
                slide way systems with mineral oils.
                <div className="sub-accordion">
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(0)}
                    >
                      <h4>Specification</h4>
                      <span>{subActiveIndex === 0 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 0 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>Cincinnati Milacron specifications</li>
                          <li>GM specification LS2-97</li>
                        </ul>
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(1)}
                    >
                      <h4>Applications</h4>
                      <span>{subActiveIndex === 1 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 1 && (
                      <div className="sub-accordion-body">
                        These oils are suitable for machine tools, which have
                        combined hydraulic and way lubrication system. These
                        oils are not recommended for critical hydraulic systems.
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(2)}
                    >
                      <h4>Benefits</h4>
                      <span>{subActiveIndex === 2 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 2 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>
                            Good anti-wear, anti-corrosion and tackiness
                            properties
                          </li>
                          <li>
                            Also serve as hydraulic media in non-critical
                            hydraulic system
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="accordion-item">
            <div
              className="accordion-header"
              onClick={() => handleAccordionClick(25)}
            >
              <h3>Metamol R</h3>
              <span>{activeIndex === 25 ? "-" : "+"}</span>
            </div>
            {activeIndex === 25 && (
              <div className="accordion-body">
                Premium quality lubricating oils for pneumatic tools
                <br></br>
                Kyros Metamol R grades are high quality lubricating oils
                developed from highly refined high viscosity index paraffinic
                base stock and carefully selected additive package. The high
                quality base stock combined with the additive package imparts
                outstanding ability to maintain oil film and effectively
                lubricate the demanding requirements of pneumatic mechanisms.
                Kyros Metamol R has been exclusively developed to meet the
                special lubrication requirements of all percussion type
                pneumatic tools including those subjected to the most arduous
                conditions. Kyros Metamol R oils maintain oil protective films
                both in low and high pressure and do not emit obnoxious odour
                even with equipment operating in confined spaces. They also
                offer excellent lubricity, wear resistance and adhesive
                characteristics. Kyros Metamol R oils are compatible with the
                paints normally specified for use in industrial pneumatic
                machines with mineral oils.
                <div className="sub-accordion">
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(0)}
                    >
                      <h4>Specification</h4>
                      <span>{subActiveIndex === 0 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 0 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>Cincinnati Milacron specifications</li>
                          <li>GM specification LS2-97</li>
                        </ul>
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(1)}
                    >
                      <h4>Applications</h4>
                      <span>{subActiveIndex === 1 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 1 && (
                      <div className="sub-accordion-body">
                        These oils are suitable for machine tools, which have
                        combined hydraulic and way lubrication system. These
                        oils are not recommended for critical hydraulic systems.
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(2)}
                    >
                      <h4>Benefits</h4>
                      <span>{subActiveIndex === 2 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 2 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>
                            Good anti-wear, anti-corrosion and tackiness
                            properties
                          </li>
                          <li>
                            Also serve as hydraulic media in non-critical
                            hydraulic system
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default LubMachine;
