import React, { useEffect } from "react";

function LubricantAutoMobile() {
  useEffect(() => {
    window.scroll(0, 0);
  });
  return (
    <div className="lubricant-container">
      <div className="lubricant">
        <h2 className="primary-subheading">KYROSePedia</h2>
        <p className="primary-information">
          Manufacturing industries are facing unparalleled challenges during
          these uncertain times. With diversified range of components,
          dependencies in Internal Markets, High capital costs, numerous
          suppliers, and time bound deliveries, Industries need to keep updated
          of the trends of the market.
        </p>
        <p className="primary-information">
          To support the efforts of the Manufacturing Industries, India’s
          leading energy provider, Kyros Immpex Private Limited (KIPL) is
          bringing a series of application and decision-driven webinars —
          KYROSePedia. The webinars are designed to inform, educate and empower
          the entire industry community including Original Equipment
          Manufacturers (OEMs), Tier-1 Suppliers, intermediaries, purchase teams
          and extended partners on applications and future trends in the sector.
          Lubricants’ Technical experts will share their insights, strategic
          inputs, and what BPCL can offer to overcome the challenges ahead.
        </p>
      </div>
    </div>
  );
}

export default LubricantAutoMobile;
