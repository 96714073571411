import React, { useEffect } from "react";
import { Button, Stack } from "@mui/material";
import { Link } from "react-router-dom";

function Api() {
  useEffect(() => {
    window.scroll(0, 0);
  });
  return (
    <div>
      <div className="work-section-wrapper">
        <div className="work-section-top">
          <p className="primary-mainheading">Iodine Derivatives</p>

          <p className="work-spices-text">
            These derivatives possess unique properties and play a crucial role
            in various pharmaceutical applications. They can be found in
            medications such as antiseptics, disinfectants, contrast agents, and
            thyroid hormone replacements.
          </p>
        </div>
        <div className="spices-button">
          <Stack spacing={2} direction="row">
            <Button variant="contained">
              <Link to="/iodine" className="link-products">
                Explore More
              </Link>
            </Button>
          </Stack>
        </div>
        <br></br>
        <hr className="line-dividing"></hr>
        <br></br>
        <br></br>
        <div className="work-section-top">
          <p className="primary-mainheading">Piperazine Derivatives</p>

          <p className="work-spices-text">
            These derivatives exhibit diverse pharmacological properties and are
            used in the treatment of conditions like parasitic infections,
            neurological disorders, and psychiatric conditions modulate
            neurotransmitter receptors and inhibit enzymes.
          </p>
        </div>
        <div className="spices-button">
          <Stack spacing={2} direction="row">
            <Button variant="contained">
              <Link to="/piperazine" className="link-products">
                Explore More
              </Link>
            </Button>
          </Stack>
        </div>
        <br></br>
        <hr className="line-dividing"></hr>
        <br></br>
        <br></br>
        <div className="work-section-top">
          <p className="primary-mainheading">Citrate Derivatives</p>

          <p className="work-spices-text">
            These derivatives offer beneficial properties such as solubility
            enhancement, stability improvement, and bioavailability enhancement.
            They are widely employed in medications such as anticoagulants,
            analgesics, and respiratory treatments
          </p>
        </div>
        <div className="spices-button">
          <Stack spacing={2} direction="row">
            <Button variant="contained">
              <Link to="/citrate" className="link-products">
                Explore More
              </Link>
            </Button>
          </Stack>
        </div>
        <br></br>
        <hr className="line-dividing"></hr>
        <br></br>
        <br></br>
        <div className="work-section-top">
          <p className="primary-mainheading">Other APIs</p>

          <p className="work-spices-text">
            Our company specializes in most of the APIs and they are widely
            employed in medications such as anticoagulants, analgesics, and
            respiratory treatments.
          </p>
        </div>
        <div className="spices-button">
          <Stack spacing={2} direction="row">
            <Button variant="contained">
              <Link to="/other-api" className="link-products">
                Explore More
              </Link>
            </Button>
          </Stack>
        </div>
        <br></br>
        <hr className="line-dividing"></hr>
        <br></br>
        <br></br>
        <div className="work-section-top">
          <p className="primary-mainheading">Spice Oleoresin</p>

          <p className="work-spices-text">
            These oleoresins contain concentrated bioactive compounds with
            potential therapeutic properties, including antioxidant,
            anti-inflammatory, and antimicrobial effects.
          </p>
        </div>
        <div className="spices-button">
          <Stack spacing={2} direction="row">
            <Button variant="contained">
              <Link to="/spiceole" className="link-products">
                Explore More
              </Link>
            </Button>
          </Stack>
        </div>
      </div>
    </div>
  );
}

export default Api;
