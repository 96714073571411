import React from "react";
import Products from "./Products";
import Recycle from "./Recycle";
import Api from "./Api";

function AllProducts() {
  return (
    <div>
      <h1 className="products-heading">Chemicals</h1>
      <Products />
      <h1 className="products-heading">Recycle</h1>
      <Recycle />
      <h1 className="products-heading">API</h1>
      <Api />
    </div>
  );
}

export default AllProducts;
