import React, { useEffect } from "react";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
function FrozenFruits() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const FrozenFruits = [
    {
      name: "Frozen Mango",
      text: "Mango is a delicious and nutritious fruit that is rich in vitamins, minerals, antioxidants, and fiber, and is a convenient and versatile ingredient for smoothies, desserts, and snacks.",
    },
    {
      name: "Frozen Berries",
      text: "Flavorful and nutritious fruit that is packed with antioxidants, vitamins, fiber, and immune-boosting compounds, and are a convenient and versatile ingredient for smoothies, desserts.",
    },
    {
      name: "Frozen pomegranate ",
      text: "Nutrient-dense fruit that is high in antioxidants, fiber, and vitamins, and is a delicious and convenient ingredient for smoothies, salads, and desserts.",
    },
    {
      name: "Frozen Strawberries",
      text: "Tasty and nutritious fruit that are rich in vitamin C, antioxidants, and fiber, and are a convenient and versatile ingredient for smoothies, desserts, and snacks.",
    },
    {
      name: "Frozen Jackfruit",
      text: "Healthy and flavorful fruit that is high in fiber, antioxidants, and nutrients, and is a versatile ingredient for vegan dishes such as curries, tacos, and burgers.",
    },
    {
      name: "Frozen Guava",
      text: "A flavorful and healthy fruit that is loaded with vitamin C, fiber, antioxidants, and other essential nutrients, and can be used in a variety of recipes such as smoothies, jams, and desserts.",
    },
    {
      name: "Frozen blackberry",
      text: "Nutrient-dense fruit that is rich in antioxidants, vitamins, minerals, and fiber, and has potential health benefits for blood sugar control, digestion, immunity, and skin health.",
    },
    {
      name: "Frozen Custurd apple",
      text: "Sweet and creamy fruit that is a good source of vitamins, minerals, and antioxidants, and may help improve digestion, boost immunity, and promote healthy skin and hair.",
    },
    {
      name: "Frozen Litchi",
      text: "Healthy fruit that is packed with vitamin C, potassium, and fiber, and can help boost immunity, aid digestion, and promote healthy skin, and is a delicious addition to smoothies, cocktails, and desserts.",
    },
    {
      name: "Frozen pineapple",
      text: "Sweet and tangy tropical fruit that is packed with vitamins, minerals, antioxidants, and enzymes, and is a convenient and delicious ingredient for smoothies, desserts, and savory dishes.",
    },
    {
      name: "Frozen Figs",
      text: "Nutritious fruit that is high in fiber, vitamins, minerals, and antioxidants, and can be enjoyed as a snack or used as an ingredient in a variety of dishes, such as salads, baked goods, and smoothies.",
    },
    {
      name: "Frozen Tender coconut",
      text: "Hydrating tropical fruit that is rich in electrolytes, vitamins, minerals, and antioxidants, and is a delicious and convenient way to replenish your body's fluids and nutrients.",
    },
    {
      name: "Frozen Papaya",
      text: "Tropical fruit that is packed with nutrients such as vitamin C, vitamin A, fiber, and enzymes, and can be enjoyed as a tasty and refreshing addition to smoothies, yogurt bowls, and salads.",
    },
    {
      name: "Frozen sapodilla",
      text: "Known as chikoo, is a sweet and creamy fruit that is high in fiber, vitamins, and minerals, and can support digestive health, boost immunity, and improve skin and hair health.",
    },
  ];
  return (
    <div>
      <p className="primary-subheading"></p>
      <h1
        className="primary-heading"
        style={{
          color: "#fe9e0d",
          paddingTop: "5%",
          margin: "auto",
          width: "350px",
        }}
      >
        <img
          src="https://i.ibb.co/KLhv7Jg/Dehydrated-Food-1.png"
          alt="Dehydrated-Food-1"
          border="0"
        />
      </h1>
      <div className="work-section-top">
        <h1 className="primary-heading"> Frozen Fruits</h1>
        <div className="fruit-images">
          <div className="fruit-images">
            <div className="fruit1">
              <div className="one">
                <img
                  src="https://i.ibb.co/9bYGDtM/papaya.png"
                  alt="papaya"
                  border="0"
                />

                <p>Papaya</p>
              </div>
              <div className="one">
                <img
                  src="https://i.ibb.co/7QV0fPZ/pineapple.jpg"
                  alt="pineapple"
                  border="0"
                />

                <p>Pineapple</p>
              </div>
              <div className="one ">
                <img
                  src="https://i.ibb.co/8BMRmKj/mango-1.png"
                  alt="mango-1"
                  border="0"
                />
                <p>Mango</p>
              </div>
              <div className="one ">
                <img
                  src="https://i.ibb.co/rcCTRzF/berries.jpg"
                  alt="berries"
                  border="0"
                />

                <p>Berries</p>
              </div>
            </div>
            <div className="fruit1">
              <div className="one ">
                <img
                  src="https://i.ibb.co/L1K9WkN/pomegranate-1.jpg"
                  alt="pomegranate-1"
                  border="0"
                />

                <p>Pomegranate</p>
              </div>
              <div className="one ">
                <img
                  src="https://i.ibb.co/WgTCF4Y/strawberry.jpg"
                  alt="strawberry"
                  border="0"
                />

                <p>Strawberry</p>
              </div>
              <div className="one">
                <img
                  src="https://i.ibb.co/JcTdxdY/anjir.png"
                  alt="anjir"
                  border="0"
                />

                <p>Anjir </p>
              </div>
              <div className="one ">
                <img
                  src="https://i.ibb.co/3v8HXK7/jackfruit.png"
                  alt="jackfruit"
                  border="0"
                />

                <p>JackFruit</p>
              </div>
            </div>
            <div className="fruit1">
              <div className="one ">
                <img
                  src="https://i.ibb.co/bNG668k/gauva.jpg"
                  alt="gauva"
                  border="0"
                />

                <p>Guava</p>
              </div>
              <div className="one">
                <img
                  src="https://i.ibb.co/M9TkTxq/jamun.png"
                  alt="jamun"
                  border="0"
                />

                <p>Jamun</p>
              </div>
              <div className="one ">
                <img
                  src="https://i.ibb.co/HNY1wgG/chikku.jpg"
                  alt="chikku"
                  border="0"
                />

                <p>Mud Apples</p>
              </div>
              <div className="one ">
                <img
                  src="https://i.ibb.co/Ct40QW3/custurd.jpg"
                  alt="custurd"
                  border="0"
                />

                <p>Custurd</p>
              </div>
            </div>
            <div className="fruit1">
              <div className="one ">
                <img
                  src="https://i.ibb.co/QKYgwwt/litchi.png"
                  alt="litchi"
                  border="0"
                />

                <p>Litchi</p>
              </div>
              <div className="one ">
                <img
                  src="https://i.ibb.co/4WqP5g8/coconut.jpg"
                  alt="coconut"
                  border="0"
                />

                <p>Cocunut</p>
              </div>
            </div>
          </div>
        </div>
        <p>
          A valuable way to access nutrients out of season. Freezing fresh
          produce, when in season, is a valuable way to access nutrients like
          vitamin C during the winter months. Studies suggest people who include
          frozen produce in their diets tend to eat more fruit and vegetables
          overal
        </p>
      </div>
      <div className="fruit-container">
        <div className="intro-text">
          <div className="fruit-section-bottom">
            {FrozenFruits.map((item) => (
              <div className="fruit-section-info" key={item.name}>
                <h1 style={{ fontSize: "1.5rem", fontWeight: "500" }}>
                  {item.name}
                </h1>

                <p style={{ fontWeight: "400" }}>{item.text}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="see-more-content">
        <small>
          <u>see more</u>
        </small>
        <KeyboardDoubleArrowRightIcon />
      </div>
      <hr
        style={{
          borderBottom: "1px solid #000",
          width: "100%",
          marginBottom: "5%",
        }}
      ></hr>
    </div>
  );
}

export default FrozenFruits;
