import React from "react";
import { Link } from "react-router-dom";

function LubricantAutoMobile() {
  return (
    <div className="lubricant-container">
      <div className="lubricant">
        <br />
        <h2 className="primary-subheading">Industrial Lubricants</h2>
        <p className="primary-information">
          Our Lubricants are a trusted lubricant brand in the industrial
          segment, catering to specific requirements of textiles, power, sugar,
          steel, off-highway vehicles and many others industries. We leave no
          stone unturned to suit the needs of specific industries and maximize
          performance.
        </p>
        <p className="primary-information">
          To achieve this level of optimization, each of the our lubricants in
          the wide product array is developed in close coordination with these
          industries.
        </p>
      </div>
      <div className="images-information">
        <Link to="/lubricant-industrial-auto">
          <div className="images-container">
            <img src="https://www.bharatpetroleum.in/images/metal.jpg" alt="" />
            <h3>Auto Ancillary</h3>
          </div>
        </Link>
        <Link to="/lubricant-industrial-cement">
          <div className="images-container">
            <img
              src="https://www.bharatpetroleum.in/images/Cement1.jpg"
              alt=""
            />
            <h3>Cement</h3>
          </div>
        </Link>
        <Link to="/lubricant-industrial-machine">
          <div className="images-container">
            <img
              src="https://www.bharatpetroleum.in/images/275x255-DRMT_-37820167.png"
              alt=""
            />
            <h3>General Machining</h3>
          </div>
        </Link>
        <Link to="/lubricant-industrial-lubricants">
          <div className="images-container">
            <img
              src="https://www.bharatpetroleum.in/images/Industrial-Oils(1).jpg"
              alt=""
            />
            <h3>Industrial Oils</h3>
          </div>
        </Link>
        <Link to="/lubricant-industrial-infra">
          <div className="images-container">
            <img
              src="https://www.bharatpetroleum.in/images/Infrastructure(2).jpg"
              alt=""
            />
            <h3>Infrastructure</h3>
          </div>
        </Link>
        <Link to="/lubricant-industrial-power">
          <div className="images-container">
            <img
              src="https://www.bharatpetroleum.in/images/Refrigeration-and-compressor(1).jpg"
              alt=""
            />
            <h3>Power</h3>
          </div>
        </Link>
        <Link to="/lubricant-industrial-steel">
          <div className="images-container">
            <img
              src="https://www.bharatpetroleum.in/images/steel(2).jpg"
              alt=""
            />
            <h3>Steel</h3>
          </div>
        </Link>
        <Link to="/lubricant-industrial-sugar">
          <div className="images-container">
            <img
              src="https://www.bharatpetroleum.in/images/Sugar(1).jpg"
              alt=""
            />
            <h3>Sugar</h3>
          </div>
        </Link>
        <Link to="/lubricant-industrial-textile">
          <div className="images-container">
            <img
              src="https://www.bharatpetroleum.in/images/Textile(1).jpg"
              alt=""
            />
            <h3>Textile</h3>
          </div>
        </Link>
      </div>
    </div>
  );
}

export default LubricantAutoMobile;
