import React, { useEffect, useState } from "react";

function LubTextile() {
  const [activeIndex, setActiveIndex] = useState(null);
  const [subActiveIndex, setSubActiveIndex] = useState(null);

  const handleAccordionClick = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
    setSubActiveIndex(null);
  };

  const handleSubAccordionClick = (index) => {
    setSubActiveIndex(subActiveIndex === index ? null : index);
  };
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div>
      <div className="lubricant">
        <h2 className="primary-subheading">Textile</h2>
        <p className="primary-information">
          From raw cotton to finished material, textile processing is a long
          manufacturing process that depends on reliability and performance of
          different machines. Variety of operations like weaving, knitting,
          spinning use machines which are subject to high loads, high speeds,
          wide temperature ranges and many other factors that require lubricants
          which can give suitable protection and help gain efficiency in the
          processes.
        </p>
        <p className="primary-information">
          Kyros offers a wide range of lubricants for the textile industry, that
          are specifically designed to withstand various criticalities of
          various textile machine operations.
        </p>
      </div>
      <div className="auto-lubricant">
        <div className="auto-lubricant-text">
          <h2>Compressor</h2>
          <img
            src="https://www.bharatpetroleum.in/images/COMPRESSOR%20OILS.jpg"
            alt=""
          />
        </div>
        <div className="accordion">
          <div className="accordion-item">
            <div
              className="accordion-header"
              onClick={() => handleAccordionClick(0)}
            >
              <h3>Compressor Oil</h3>
              <span>{activeIndex === 0 ? "-" : "+"}</span>
            </div>
            {activeIndex === 0 && (
              <div className="accordion-body">
                Superior quality reciprocating air compressor oil
                <br></br>
                Kyros Compressor Oils are high quality, high performance mineral
                oils developed for reciprocating air compressors. They are
                formulated from highly refined, high viscosity index base stocks
                and high performance additives with ashless chemistry.
                Reciprocating compressors operate at high temperatures and
                pressures. The lubricant creates a strong film in order to avoid
                surface wear. These oils have exceptional resistance to
                oxidation and thermal degradation. The thermal stability and
                oxidation resistance of these fluids can help maintain cleaner
                compressors, thereby enabling longer running periods between
                scheduled maintenance and oil changes.
                <div className="sub-accordion">
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(0)}
                    >
                      <h4>Specifications</h4>
                      <span>{subActiveIndex === 0 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 0 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>DIN 51 506 - VDL</li>
                          <li>ISO / DP 6521</li>
                          <li>
                            IS 13256:1992 DAA & DAB type [Reaffirmed 1998]
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(1)}
                    >
                      <h4>Applications</h4>
                      <span>{subActiveIndex === 1 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 1 && (
                      <div className="sub-accordion-body">
                        For high performance reciprocating & rotary air
                        compressors where air discharge temperatures may go up
                        to 220°C.
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(2)}
                    >
                      <h4>Benefits</h4>
                      <span>{subActiveIndex === 2 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 2 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>
                            Longer Oil Life- This oil has strong oxidation
                            resistance and hence is less prone to degradation
                          </li>
                          <li>
                            Lower Maintenance costs: oil has lesser tendency to
                            form sludge & varnish and hence it provides trouble
                            free operation and lower maintenance.
                          </li>
                          <li>
                            Extended maintenance intervals: due to reduced
                            deposit formation on valves, the maintenance
                            intervals for valves and other components increase.
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="accordion-item">
            <div
              className="accordion-header"
              onClick={() => handleAccordionClick(1)}
            >
              <h3>Screwcom</h3>
              <span>{activeIndex === 1 ? "-" : "+"}</span>
            </div>
            {activeIndex === 1 && (
              <div className="accordion-body">
                Premium quality oils for rotary screw air compressors
                <br></br> Kyros Screwcom Oils are high quality, high performance
                mineral oils developed for rotary vane and screw air
                compressors. They are formulated from highly refined, high
                viscosity index base stocks and high performance ashless
                additives. These oils have exceptional resistance to oxidation
                and thermal degradation. The thermal stability and oxidation
                resistance of these fluids can help maintain cleaner
                compressors, thereby enabling longer running periods between
                scheduled maintenance and oil changes.
                <div className="sub-accordion">
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(0)}
                    >
                      <h4>Specifications</h4>
                      <span>{subActiveIndex === 0 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 0 && (
                      <div className="sub-accordion-body">
                        Proprietary grades
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(1)}
                    >
                      <h4>Applications</h4>
                      <span>{subActiveIndex === 1 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 1 && (
                      <div className="sub-accordion-body">
                        Recommended for oil flooded or oil injected, single or
                        two-stage screw compressors powered by Electricity or
                        Diesel
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(2)}
                    >
                      <h4>Benefits</h4>
                      <span>{subActiveIndex === 2 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 2 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>
                            Excellent anti-wear & higher load carrying capacity.
                          </li>
                          <li>Outstanding oxidation and thermal stability</li>
                          <li>Excellent water separation characteristics</li>
                          <li>
                            Prevents rust and corrosion of internal compressor
                            components
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="accordion-item">
            <div
              className="accordion-header"
              onClick={() => handleAccordionClick(2)}
            >
              <h3>Syncom</h3>
              <span>{activeIndex === 2 ? "-" : "+"}</span>
            </div>
            {activeIndex === 2 && (
              <div className="accordion-body">
                Advanced synthetic oils for rotary air compressors
                <br></br>
                Kyros Syncom Oils are advanced fully synthetic oils developed
                for rotary vane and screw air compressors. They are formulated
                from high viscosity index synthetic base stocks (PAO) and high
                performance advanced ashless additives to deliver the highest
                performance lubrication. These oils have outstanding resistance
                to oxidation and thermal degradation. The extremely low level of
                deposit formation help maintain internal surface cleanliness,
                thereby enabling longer running periods between scheduled
                maintenance and oil changes.
                <div className="sub-accordion">
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(0)}
                    >
                      <h4>Specifications</h4>
                      <span>{subActiveIndex === 0 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 0 && (
                      <div className="sub-accordion-body">
                        Proprietary grades
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(1)}
                    >
                      <h4>Applications</h4>
                      <span>{subActiveIndex === 1 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 1 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>
                            Recommended for oil flooded or oil injected, single
                            or multi-stage screw compressors.
                          </li>
                          <li>
                            These oils are suitable where air discharge
                            temperatures can go in excess of 100OC and provides
                            drain cycles of up to 8000 hours under standard
                            conditions of use.
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(2)}
                    >
                      <h4>Benefits</h4>
                      <span>{subActiveIndex === 2 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 2 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>
                            Excellent anti-wear & higher load carrying capacity.
                          </li>
                          <li>Outstanding oxidation and thermal stability</li>
                          <li>Excellent water separation characteristics</li>
                          <li>
                            Prevents rust and corrosion of internal compressor
                            components
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="auto-lubricant">
        <div className="auto-lubricant-text">
          <h2>Knitting Oils</h2>
          <img
            src="https://www.bharatpetroleum.in/images/220x220-KNITTING-OIL.jpg"
            alt=""
          />
        </div>
        <div className="accordion">
          <div className="accordion-item">
            <div
              className="accordion-header"
              onClick={() => handleAccordionClick(3)}
            >
              <h3>Kyros KNIT XL 22</h3>
              <span>{activeIndex === 3 ? "-" : "+"}</span>
            </div>
            {activeIndex === 3 && (
              <div className="accordion-body">
                High performance non-emulsifiable knitting machine oil
                <br></br>
                Kyros Knit XL 22 is formulated from high quality hydro treated,
                water white base oils and special anti-wear and other
                performance enhancing additives. It offers good protection to
                the components of knitting machinery like needle, sinkers, cams
                etc. against wear and rust. This oil has good lubricity and
                oxidation stability and meets the requirements of textile
                industry in terms of increased speed and operating temperature.
                <div className="sub-accordion">
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(0)}
                    >
                      <h4>Specification</h4>
                      <span>{subActiveIndex === 0 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 0 && (
                      <div className="sub-accordion-body">
                        Proprietary grade
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(1)}
                    >
                      <h4>Applications</h4>
                      <span>{subActiveIndex === 1 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 1 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>
                            Designed for lubrication of needles, sinkers & cams
                            of circular knitting machines widely used in Textile
                            industry.
                          </li>
                          <li>
                            Ensures a good wear and rust protection for the
                            components of knitting machinery.
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(2)}
                    >
                      <h4>Benefits</h4>
                      <span>{subActiveIndex === 2 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 2 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>
                            With highly purified base oil, this knitting oil is
                            superior in thermal and oxidation stability.
                          </li>
                          <li>
                            Minimizes wear and abrasion of machine parts with
                            sufficient lubricating film formation at the
                            component surface due to the presence of special
                            composition of lubricity and anti-wear agent.
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="accordion-item">
            <div
              className="accordion-header"
              onClick={() => handleAccordionClick(4)}
            >
              <h3>Kyros Knit Plus 22</h3>
              <span>{activeIndex === 4 ? "-" : "+"}</span>
            </div>
            {activeIndex === 4 && (
              <div className="accordion-body">
                High performance emulsifiable knitting machine oil
                <br></br>
                Kyros Knit Plus 22 is formulated from hydro treated, high
                viscosity index, water white base stocks. It is fortified with
                special anti-wear, emulsifier, lubricity and other performance
                enhancing additives. It offers very good protection to the
                components of knitting machinery against wear and rust. This oil
                has good lubricity and oxidation stability and meets the
                requirements of textile industry in terms of increased speed and
                operating temperature.
                <div className="sub-accordion">
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(0)}
                    >
                      <h4>Specifications</h4>
                      <span>{subActiveIndex === 0 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 0 && (
                      <div className="sub-accordion-body">
                        Proprietary grades
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(1)}
                    >
                      <h4>Applications</h4>
                      <span>{subActiveIndex === 1 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 1 && (
                      <div className="sub-accordion-body">
                        Premium knitting oil Designed for lubrication of
                        needles, sinkers & cams of knitting machines widely used
                        in Textile industry.
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(2)}
                    >
                      <h4>Benefits</h4>
                      <span>{subActiveIndex === 2 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 2 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>
                            Optimum anti -wear property to prolonging the life
                            of stitch- forming elements.
                          </li>
                          <li>
                            Special emulsifying agents facilitating scourability
                            process.
                          </li>
                          <li>
                            Lowers machine temperature and easy cold running
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="accordion-item">
            <div
              className="accordion-header"
              onClick={() => handleAccordionClick(5)}
            >
              <h3>Kyros Knit 22</h3>
              <span>{activeIndex === 5 ? "-" : "+"}</span>
            </div>
            {activeIndex === 5 && (
              <div className="accordion-body">
                Emulsifiable knitting machine oil
                <br></br>
                Kyros Knit 22 is formulated from hydro treated, high viscosity
                index, water white base stocks. It is fortified with special
                anti-wear, emulsifier and other performance enhancing additives.
                It offers very good protection to the components of knitting
                machinery against wear and rust. This oil offers good resistance
                to oxidation and meets the requirements of textile industry in
                terms of increased speed and operating temperature.
                <div className="sub-accordion">
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(0)}
                    >
                      <h4>Specifications</h4>
                      <span>{subActiveIndex === 0 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 0 && (
                      <div className="sub-accordion-body">
                        Proprietary grades
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(1)}
                    >
                      <h4>Applications</h4>
                      <span>{subActiveIndex === 1 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 1 && (
                      <div className="sub-accordion-body">
                        Designed for lubrication of needles, sinkers & cams of
                        knitting machines widely used in Textile industry.
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(2)}
                    >
                      <h4>Benefits</h4>
                      <span>{subActiveIndex === 2 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 2 && (
                      <div className="sub-accordion-body">
                        <ul>
                          Does not leave any carbonaceous deposits
                          <li>
                            Keeps the temperature of needles low by reducing the
                            friction.
                          </li>
                          <li>Excellent misting properties.</li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="accordion-item">
            <div
              className="accordion-header"
              onClick={() => handleAccordionClick(6)}
            >
              <h3>Kyros Stamping Oil</h3>
              <span>{activeIndex === 6 ? "-" : "+"}</span>
            </div>
            {activeIndex === 6 && (
              <div className="accordion-body">
                High quality oil for forming and stamping application
                <br></br>
                Kyros Stamping Oil has been formulated for forming and stamping
                application. Highly refined hydro-processed base stocks and
                multifunctional additives together impart superior antiwear and
                excellent load carrying capability. Inactive type extreme
                pressure characteristics enable to withstand high and shock
                load. Easy-washability formulation of the oil allows easy
                removal of it off the finished surfaces.
                <div className="sub-accordion">
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(0)}
                    >
                      <h4>Specifications</h4>
                      <span>{subActiveIndex === 0 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 0 && (
                      <div className="sub-accordion-body">
                        Proprietary grades
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(1)}
                    >
                      <h4>Applications</h4>
                      <span>{subActiveIndex === 1 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 1 && (
                      <div className="sub-accordion-body">
                        This oil is used as metal forming and stamping lubricant
                        such as Car / Cab body drawing operation.
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(2)}
                    >
                      <h4>Benefits</h4>
                      <span>{subActiveIndex === 2 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 2 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>
                            These oils have anti-wear additives imparting good
                            load carrying ability to withstand loads encountered
                            in metal forming and stamping application.
                          </li>
                          <li>
                            This oil reduces oil consumption and also provides
                            smooth surface finish & increased die life.
                          </li>
                          <li>Excellent rust & corrosion protection.</li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* Third */}
      <div className="auto-lubricant">
        <div className="auto-lubricant-text">
          <h2>Gears</h2>
          <img
            src="https://www.bharatpetroleum.in/images/220x220-GEARS.jpg"
            alt=""
          />
        </div>
        <div className="accordion">
          <div className="accordion-item">
            <div
              className="accordion-header"
              onClick={() => handleAccordionClick(7)}
            >
              <h3>Kyros Amocam</h3>
              <span>{activeIndex === 7 ? "-" : "+"}</span>
            </div>
            {activeIndex === 7 && (
              <div className="accordion-body">
                Premium quality high viscosity index, extreme pressure oils for
                industrial gears
                <br></br>
                Kyros Amocam is a range of premium quality, extreme pressure
                industrial gear lubricants blended from high viscosity index
                solvent refined high quality base oils. The superior quality
                sulphur-phosphorus additive chemistry imparts high level of
                antiwear and extreme pressure property. Their high load carrying
                capacity and anti-friction property offer excellent performance
                in industrial gears and other industrial applications.
                <div className="sub-accordion">
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(0)}
                    >
                      <h4>Specification</h4>
                      <span>{subActiveIndex === 0 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 0 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>IS 8406: 1993 (EP Type) [Reaffirmed 2001]</li>
                          <li>IPSS: 1-09-003 • US Steel 224</li>
                          <li>David Brown S.1.53.101</li>
                          <li>DIN 51 517 (Part 3)</li>
                          <li>AGMA 250.04 5EP & AGMA 251.02 5EP</li>
                        </ul>
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(1)}
                    >
                      <h4>Applications</h4>
                      <span>{subActiveIndex === 1 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 1 && (
                      <div className="sub-accordion-body">
                        Recommended for the lubrication of all types of
                        industrial enclosed gear drives operating at high load &
                        speed conditions. Also recommended for the lubrication
                        of worm gear units. Bearing Lubrication where EP type
                        Oil is recommended.
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(2)}
                    >
                      <h4>Benefits</h4>
                      <span>{subActiveIndex === 2 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 2 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>
                            Selected additive chemistry offers extended die /
                            tool life and minimizes down time
                          </li>

                          <li>
                            Provides good surface finish and proper sheared
                            straight edges in fine blanked components.
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="accordion-item">
            <div
              className="accordion-header"
              onClick={() => handleAccordionClick(8)}
            >
              <h3>Kyros Amocam Plus</h3>
              <span>{activeIndex === 8 ? "-" : "+"}</span>
            </div>
            {activeIndex === 8 && (
              <div className="accordion-body">
                Advanced extreme pressure, anti-micropitting oils for heavy duty
                industrial gears
                <br></br>
                Kyros Amocam Plus is a range of advanced, heavy duty, extreme
                pressure industrial gear lubricants blended from high viscosity
                index and high quality base stocks. These oils have been
                developed to deliver optimum value through enhanced wear
                protection, long oil life and high system efficiency. The
                superior quality additive chemistry imparts high level of
                antiwear and extreme pressure property. Their high load carrying
                capacity and anti-friction property offer excellent performance
                in industrial gears and other industrial applications.
                <div className="sub-accordion">
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(0)}
                    >
                      <h4>Specifications</h4>
                      <span>{subActiveIndex === 0 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 0 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>IS 8406: 1993 (EP Type) [Reaffirmed, 2001]</li>
                          <li>US Steel 224 • IPSS:1-09-003</li>
                          <li>DIN 51 517 (Part 3)</li>
                          <li>DAVID BROWN S. 1. 53. 101</li>
                          <li>AGMA 250.04 5EP & AGMA 251.02 5EP</li>
                          <li>Micro pitting performance - Flender AG</li>
                        </ul>
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(1)}
                    >
                      <h4>Applications</h4>
                      <span>{subActiveIndex === 1 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 1 && (
                      <div className="sub-accordion-body">
                        Used for the lubrication of all types of industrial
                        enclosed gear drives operating at high load & speed
                        conditions. The need of such gear oil increases wherever
                        there is possibility of shock loads as in case of
                        enclosed spur, helical, herring bone, bevel and
                        planetary gearboxes.
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(2)}
                    >
                      <h4>Benefits</h4>
                      <span>{subActiveIndex === 2 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 2 && (
                      <div className="sub-accordion-body">
                        Premium quality EP type industrial gear oil, which meets
                        Flender’s requirement for anti-micro pitting gear oil to
                        take care of shock loads.
                        <br></br>
                        Major Approvals:
                        <br></br>
                        <ul>
                          <li>
                            Kyros Amocam Plus 320 - Bhushan Steel for Gear box
                            lubrication in the gear box of their uncoiler and
                            Walchandnagar Inds for their Cement Division.
                          </li>
                          <li>
                            Kyros Amocam Plus 460 - Walchandnagar Inds, Cement
                            Division.
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="accordion-item">
            <div
              className="accordion-header"
              onClick={() => handleAccordionClick(9)}
            >
              <h3>Kyros Syngear</h3>
              <span>{activeIndex === 9 ? "-" : "+"}</span>
            </div>
            {activeIndex === 9 && (
              <div className="accordion-body">
                Advanced synthetic oils for heavy duty industrial gears
                <br></br>
                Kyros Syngear is a range of fully synthetic gear oils with
                outstanding lubrication performance. They are formulated from
                special quality wax-free polyalphaolefin (PAO) synthetic base
                stocks that have exceptional oxidation resistance and thermal
                properties, high viscosity index and excellent low temperature
                fluidity. Their high load carrying capacity and anti-friction
                property offer excellent performance in industrial gears and
                other industrial applications. The high viscosity index and low
                traction coefficient of these oils combine to provide a
                potential reduction in power consumption in many gear systems.
                <div className="sub-accordion">
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(0)}
                    >
                      <h4>Specifications</h4>
                      <span>{subActiveIndex === 0 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 0 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>U.S. Steel 224</li>
                          <li>AGMA 9005-E-02</li>
                          <li>DIN 51517 Part 3</li>
                          <li>Micro-pitting performance - Flender AG</li>
                          <li>David Brown S1.53.101 Type E</li>
                          <li>FAG FE8 test</li>
                        </ul>
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(1)}
                    >
                      <h4>Applications</h4>
                      <span>{subActiveIndex === 1 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 1 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>
                            {" "}
                            Heavily loaded enclosed spur, helical, herringbone,
                            bevel and planetary gearboxes.
                          </li>
                          <li>
                            Application where the OEMs recommends synthetic
                            extreme pressure gear oil.
                          </li>
                          <li>
                            Industrial gear drives where full extreme pressure
                            and micro-pitting performance due to heavy / shock
                            loading is needed.
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(2)}
                    >
                      <h4>Benefits</h4>
                      <span>{subActiveIndex === 2 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 2 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li> High level anti-wear performance</li>
                          <li>Extreme pressure property</li>
                          <li>Micro-pitting performance</li>
                          <li>Excellent oxidation & thermal stability</li>
                          <li>Good low temperature properties</li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="accordion-item">
            <div
              className="accordion-header"
              onClick={() => handleAccordionClick(10)}
            >
              <h3>Kyros Syngear PG</h3>
              <span>{activeIndex === 10 ? "-" : "+"}</span>
            </div>
            {activeIndex === 10 && (
              <div className="accordion-body">
                Kyros SYNGEAR PG 320 is a synthetic gear lubricant based on the
                Polyalkylene Glycol base fluid and selected additives.
                <br></br>
                Polyalkylene Glycols (PAGs) are homopolymers of ethylene oxide
                or propylene oxide, or copolymers of ethylene oxide or propylene
                oxide. Because of the unique structure, PAG’s possess a low
                friction coefficient. Polyglycolbased gear oils offer
                significant advantages over mineral oils when a lot of sliding
                friction occurs in gearboxes and lowers oil temperatures that in
                turn extend oil change intervals. PAG based gear oils are used
                whenever mineral gear oils have reached their performance limit
                and can no longer meet the application requirements
                <div className="sub-accordion">
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(1)}
                    >
                      <h4>Applications</h4>
                      <span>{subActiveIndex === 1 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 1 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>
                            PAG gear oils are recommended for highly stressed
                            crown, bevel, planetary and worm drive gears
                          </li>
                          <li>
                            Poly glycol based gear oils offer significant
                            advantages over mineral oils when a lot of sliding
                            friction occurs in gearboxes and lowers oil
                            temperatures that in turn extend oil change
                            intervals.
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(2)}
                    >
                      <h4>Benefits</h4>
                      <span>{subActiveIndex === 2 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 2 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>High level anti-wear performance</li>
                          <li>Extreme pressure property</li>
                          <li>Micro-pitting performance</li>
                          <li>Excellent oxidation & thermal stability</li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* {Fourth} */}
      <div className="auto-lubricant">
        <div className="auto-lubricant-text">
          <h2>Grease</h2>
          <img
            src="https://www.bharatpetroleum.in/images/220x220-GREASE.jpg"
            alt=""
          />
        </div>
        <div className="accordion">
          <div className="accordion-item">
            <div
              className="accordion-header"
              onClick={() => handleAccordionClick(11)}
            >
              <h3>About</h3>
              <span>{activeIndex === 11 ? "-" : "+"}</span>
            </div>
            {activeIndex === 11 && (
              <div className="accordion-body">
                the properties and characteristics of the grease, such as its
                viscosity, consistency, temperature range, and compatibility
                with different types of materials. It may also include details
                on the intended application of the grease and the specific
                benefits it provides, such as reducing friction, preventing wear
                and tear, and extending the lifespan of the machinery
              </div>
            )}
          </div>
          <div className="accordion-item">
            <div
              className="accordion-header"
              onClick={() => handleAccordionClick(12)}
            >
              <h3>Kyros MP Grease</h3>
              <span>{activeIndex === 12 ? "-" : "+"}</span>
            </div>
            {activeIndex === 12 && (
              <div className="accordion-body">
                High quality multipurpose grease for automotive and industrial
                application
                <br></br>
                Kyros MP Grease is a premium quality lithium based multipurpose
                grease manufactured from superior quality base oils. This grease
                is fortified with a well-balanced additive chemistry comprising
                of anti-oxidant, anti-wear and anti-rust additives making it
                suitable for a wide range of general purpose applications. The
                grease also provides good resistance to water wash-out. It
                offers mechanical stability and is suitable for use in
                applications experiencing moderate temperatures and moderate
                loads.
                <div className="sub-accordion">
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(0)}
                    >
                      <h4>Specification</h4>
                      <span>{subActiveIndex === 0 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 0 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>IS 7623:1993</li>
                          <li>IPSS: 1-09-006-97</li>
                        </ul>
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(1)}
                    >
                      <h4>Applications</h4>
                      <span>{subActiveIndex === 1 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 1 && (
                      <div className="sub-accordion-body">
                        It is recommended for the lubrication of heavy-duty
                        plain and roller bearings operating under heavy loads,
                        moderate temperature and shock loading conditions
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(2)}
                    >
                      <h4>Benefits</h4>
                      <span>{subActiveIndex === 2 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 2 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>
                            Good Oxidation Stability- Gives enhanced life to the
                            grease lubricant and minimum changing intervals
                          </li>

                          <li>
                            High Drop point- This grease has a high drop point
                            values, which makes grease usable at moderate
                            temperatures without any concern of oil coming out
                            of the grease and hence can lubricate efficiently
                            for longer period
                          </li>
                          <li>
                            Good Structural Stability- Provides grease with
                            extra ability to resist early shear and work longer
                            during harsh working conditions
                          </li>
                        </ul>
                        Major Approvals:
                        <ul>
                          <li>
                            Kyros MP Grease 2 is approved by Walchandnagar
                            Inds-Gear Division.
                          </li>
                          <li>
                            Kyros MP Grease 3 is approved by Walchandnagar Inds
                            for its Cement Division, Sugar Division and Gear
                            Division.
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="accordion-item">
            <div
              className="accordion-header"
              onClick={() => handleAccordionClick(13)}
            >
              <h3>Kyros Lanthax EP Grease</h3>
              <span>{activeIndex === 13 ? "-" : "+"}</span>
            </div>
            {activeIndex === 13 && (
              <div className="accordion-body">
                Premium quality extreme pressure grease for automotive and
                industrial application
                <br></br>
                Kyros Lanthax EP Grease is a premium quality lithium based
                grease containing extreme pressure additives. It is formulated
                with high quality mineral base oils and lithium soap thickener.
                This grease is suitable for situations warranting the use of
                extreme pressure grease. Kyros Lanthax EP Grease is designed to
                handle the most severe loads in a wide range of automotive and
                industrial applications. The grease has a recommended operating
                temperature range from -20O C to 130O C. Kyros Lanthax EP Grease
                has high resistance to mechanical breakdown, excellent oxidation
                resistance, good rust protection, and excellent water washout
                resistance.
                <div className="sub-accordion">
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(0)}
                    >
                      <h4>Specifications</h4>
                      <span>{subActiveIndex === 0 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 0 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li> IS 7623 : 1993 EP Type [Reaffirmed 2001]</li>
                          <li>IPSS : 1-09-005-99</li>
                        </ul>
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(1)}
                    >
                      <h4>Applications</h4>
                      <span>{subActiveIndex === 1 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 1 && (
                      <div className="sub-accordion-body">
                        This grease is recommended for all plain & roller
                        bearings in equipment operating under severe shock
                        loading conditions and temperatures up to about 130°C
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(2)}
                    >
                      <h4>Benefits</h4>
                      <span>{subActiveIndex === 2 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 2 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>
                            Good Oxidation Stability- Gives enhanced life to the
                            grease lubricant and minimum changing intervals
                          </li>
                          <li>
                            Excellent Load Carrying abilities- this grease has
                            good extreme pressure properties, making it suitable
                            to protect and lubricate the machine components even
                            under high loads
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* Fifth */}
      <div className="auto-lubricant">
        <div className="auto-lubricant-text">
          <h2>Heat Treatment</h2>
          <img
            src="https://www.bharatpetroleum.in/images/220x220-HEAT-TREATMENT-OIL.jpg"
            alt=""
          />
        </div>
        <div className="accordion">
          <div className="accordion-item">
            <div
              className="accordion-header"
              onClick={() => handleAccordionClick(14)}
            >
              <h3>Kyros Quenching Oil C</h3>
              <span>{activeIndex === 14 ? "-" : "+"}</span>
            </div>
            {activeIndex === 14 && (
              <div className="accordion-body">
                Compounded heat treatment oil for outstanding performance
                <br></br>
                Kyros Quenching Oil C is a superior quality quenching oil
                formulated exclusively from highly refined base stock and
                special additive. This oil is compounded with fatty oils. It
                offers longer bath life at elevated temperatures. The
                antioxidant additive provides excellent resistance to oxidation
                and break down of the oil and ensures longer operating life.
                <div className="sub-accordion">
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(0)}
                    >
                      <h4>Specification</h4>
                      <span>{subActiveIndex === 0 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 0 && (
                      <div className="sub-accordion-body">
                        IS 2664 : 1980 [Reaffirmed 2014]
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(1)}
                    >
                      <h4>Applications</h4>
                      <span>{subActiveIndex === 1 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 1 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>
                            Kyros Quenching Oil C is suitable for use at bath
                            temperatures up to 80°C. Compounded quenching oils
                            are used to provide good proportion of hardness and
                            toughness and offer least war page with standard
                            steels.
                          </li>
                          <li>
                            This type of quenching oils are used for the
                            hardening of Carbon steel, Gray iron and High-alloy
                            steel.
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(2)}
                    >
                      <h4>Benefits</h4>
                      <span>{subActiveIndex === 2 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 2 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>
                            Exceptionally good oxidation & chemical stability
                          </li>
                          <li>Excellent Thermal stability</li>
                          <li>Controlled hardness</li>
                          <li>Excellent thermal conductivity</li>
                          <li>Non corrosive & non toxic</li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="accordion-item">
            <div
              className="accordion-header"
              onClick={() => handleAccordionClick(15)}
            >
              <h3>Kyros Thermol</h3>
              <span>{activeIndex === 15 ? "-" : "+"}</span>
            </div>
            {activeIndex === 15 && (
              <div className="accordion-body">
                Superior heat treatment oils for outstanding performance
                <br></br>
                Kyros Thermol range is a superior quality quenching oil
                formulated exclusively from highly refined base stock and
                special additive. The antioxidant additive provides excellent
                resistance to oxidation and break down of the oil and ensures
                longer operating life. These oils have very high flash points
                and low volatility. The range covers all quenching processes.
                The quenching oils allow uniform hardening of parts with
                improved grain structure and with minimum distortion. It
                provides consistent and repeatable mechanical and metallurgical
                properties.
                <div className="sub-accordion">
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(0)}
                    >
                      <h4>Specifications</h4>
                      <span>{subActiveIndex === 0 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 0 && (
                      <div className="sub-accordion-body">
                        IS 2664:1980 [Reaffirmed 1998]
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(1)}
                    >
                      <h4>Applications</h4>
                      <span>{subActiveIndex === 1 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 1 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>
                            Kyros Thermol 68 is used as quenching medium in
                            general heat treatment applications where lower
                            quenching speed is desired.
                          </li>
                          <li>
                            Kyros Thermol 22 & 32 are used as quenching medium
                            for accelerated quenching operations.
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(2)}
                    >
                      <h4>Benefits</h4>
                      <span>{subActiveIndex === 2 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 2 && (
                      <div className="sub-accordion-body">
                        <br></br>
                        <ul>
                          <li>
                            Exceptionally good oxidation & chemical stability
                          </li>
                          <li>Excellent Thermal stability</li>
                          <li>Non corrosive & non toxic</li>
                          <li>Controlled hardness</li>
                          <li>Low vapour pressure hence resists cracking</li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="accordion-item">
            <div
              className="accordion-header"
              onClick={() => handleAccordionClick(16)}
            >
              <h3>Kyros Thermol Plus</h3>
              <span>{activeIndex === 16 ? "-" : "+"}</span>
            </div>
            {activeIndex === 16 && (
              <div className="accordion-body">
                Superior accelerated heat treatment oil for outstanding
                performance
                <br></br>
                Kyros Thermol Plus 32 is a superior quality quenching oil
                formulated from highly refined base stocks and specially
                selected additives. The additive system provides excellent
                resistance to oxidative and thermal degradation of the oil and
                offers longer service life. This oil has very high flash point
                and low volatility. The accelerated quenching oil allows uniform
                hardening of parts with improved grain structure and with
                minimum distortion. It provides consistent and repeatable
                mechanical and metallurgical properties.
                <div className="sub-accordion">
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(0)}
                    >
                      <h4>Specifications</h4>
                      <span>{subActiveIndex === 0 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 0 && (
                      <div className="sub-accordion-body">
                        IS 2664:1980 (Reaffirmed 2014)
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(1)}
                    >
                      <h4>Applications</h4>
                      <span>{subActiveIndex === 1 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 1 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>
                            Kyros Thermol plus 32 is an accelerated variety of
                            quenching oil. It is suitable for applications where
                            fast quenching is required and also for parts
                            subject to distortion during heat treatment.
                          </li>
                          <li>
                            Kyros Thermol plus 32 oil is suitable for
                            applications like open tanks, continuous furnaces,
                            batch furnaces and vacuum furnaces.
                          </li>
                          <li>
                            It is recommended for hardening of ferrous metals
                            like carbon steel, grey iron and high-alloy steel.
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(2)}
                    >
                      <h4>Benefits</h4>
                      <span>{subActiveIndex === 2 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 2 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>
                            Exceptionally good oxidation & chemical stability
                          </li>
                          <li>Excellent Thermal stability</li>
                          <li>Non corrosive & non toxic</li>
                          <li>Controlled hardness</li>
                          <li>Low vapour pressure hence resists cracking</li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="accordion-item">
            <div
              className="accordion-header"
              onClick={() => handleAccordionClick(17)}
            >
              <h3>Kyros Thermol UF</h3>
              <span>{activeIndex === 17 ? "-" : "+"}</span>
            </div>
            {activeIndex === 17 && (
              <div className="accordion-body">
                Premium ultra-fast accelerated heat treatment oil
                <br></br>
                Kyros Thermol UF is a premium quality ultra-fast quenching oil
                formulated from highly refined base stocks and highly effective
                accelerator concentrate. The additive system provides excellent
                resistance to oxidative and thermal degradation of the oil and
                offers longer service life along with excellent part. This oil
                has very high flash point and low volatility. The accelerated
                quenching oil allows uniform hardening of parts with improved
                grain structure and with minimum distortion. It provides
                consistent and repeatable mechanical and metallurgical
                properties.
                <div className="sub-accordion">
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(0)}
                    >
                      <h4>Specifications</h4>
                      <span>{subActiveIndex === 0 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 0 && (
                      <div className="sub-accordion-body">
                        Proprietary Grade
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(1)}
                    >
                      <h4>Applications</h4>
                      <span>{subActiveIndex === 1 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 1 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>
                            Kyros Thermol UF is suitable for applications where
                            rapid quenching is required and also for parts
                            subject to distortion during heat treatment.
                          </li>
                          <li>
                            Kyros Thermol UF oil is suitable for applications
                            like open tanks, continuous furnaces, batch furnaces
                            and vacuum furnaces.
                          </li>
                          <li>
                            It is recommended for hardening of ferrous metals
                            like carbon steel, grey iron and high-alloy steel.
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(2)}
                    >
                      <h4>Benefits</h4>
                      <span>{subActiveIndex === 2 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 2 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>
                            {" "}
                            Exceptionally good oxidation & chemical stability
                          </li>
                          <li>Excellent Thermal stability</li>
                          <li>Non corrosive & non toxic</li>
                          <li>Excellent thermal conductivity</li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* Sixth */}
      <div className="auto-lubricant">
        <div className="auto-lubricant-text">
          <h2>Hydraulic Systems</h2>
          <img
            src="https://www.bharatpetroleum.in/images/220x220-HYDRAULIC-OIL.jpg"
            alt=""
          />
        </div>
        <div className="accordion">
          <div className="accordion-item">
            <div
              className="accordion-header"
              onClick={() => handleAccordionClick(18)}
            >
              <h3>Hydrol</h3>
              <span>{activeIndex === 18 ? "-" : "+"}</span>
            </div>
            {activeIndex === 18 && (
              <div className="accordion-body">
                Industrial hydraulic fluid for superior performance and
                protection
                <br></br>
                Kyros Hydrol range is a group of premium quality, transparent
                and antiwear hydraulic oils. They are blended from highly
                refined, high viscosity index base oils with carefully selected
                antiwear additive. These oils are designed to operate over a
                wide range of working conditions including low load and severe
                high load conditions. High rate of water separation, exceptional
                hydrolytic stability, anti-foam and cleanliness allow efficient
                operation of the system.
                <div className="sub-accordion">
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(0)}
                    >
                      <h4>Specification</h4>
                      <span>{subActiveIndex === 0 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 0 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>IS 3098 : 1983 [Reaffirmed 2004]</li>
                          <li>IS 10522 : 1983 [Reaffirmed 2004]</li>
                        </ul>
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(1)}
                    >
                      <h4>Applications</h4>
                      <span>{subActiveIndex === 1 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 1 && (
                      <div className="sub-accordion-body">
                        These are recommended for spindle lubrication as well as
                        hydraulic systems requiring low viscosity grades and a
                        wide variety of circulation systems of industrial and
                        automotive equipment. These are also used in General
                        manufacturing, Metal and Power equipment.
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(2)}
                    >
                      <h4>Benefits</h4>
                      <span>{subActiveIndex === 2 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 2 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>
                            Outstanding Oxidation Stability: Reduces
                            consumption, filter blocking and valve sticking.
                          </li>
                          <li>
                            Good water and foam resistant properties: Separates
                            out water very fast in case of water ingression and
                            releases entrapped air immediately to avoid foaming.
                          </li>
                          <li>
                            Excellent Hydrolytic Stability: This avoids
                            absorption of water in oil and thus enhances fluid
                            life.
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="accordion-item">
            <div
              className="accordion-header"
              onClick={() => handleAccordionClick(19)}
            >
              <h3>Hydrol AW</h3>
              <span>{activeIndex === 19 ? "-" : "+"}</span>
            </div>
            {activeIndex === 19 && (
              <div className="accordion-body">
                Industrial antiwear hydraulic fluid for superior performance
                <br></br>
                Kyros Hydrol AW range is a group of premium quality, transparent
                and antiwear hydraulic oils. They are blended from
                hydro-processed, high viscosity index base oils with carefully
                selected antiwear and other additives. These oils are designed
                to operate over a wide range of working conditions including low
                load and severe high load conditions. High rate of water
                separation, exceptional hydrolytic stability, anti-foam and
                cleanliness allow efficient operation of the system. These oils
                ensures high level of antiwear property and film strength.
                <div className="sub-accordion">
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(0)}
                    >
                      <h4>Specifications</h4>
                      <span>{subActiveIndex === 0 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 0 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>IS 3098:1983 [Reaffirmed 1998]</li>
                          <li>IS 10522:1983 [Reaffirmed 1998]</li>
                          <li>IPSS : 1-09-022</li>
                          <li>Vickers V-104C Vane pump test</li>
                          <li>DIN 51 524 Part 1 HL Type</li>
                        </ul>
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(1)}
                    >
                      <h4>Applications</h4>
                      <span>{subActiveIndex === 1 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 1 && (
                      <div className="sub-accordion-body">
                        These oils are recommended for general hydraulic
                        equipment in control and power transmission systems and
                        for circulating equipment.
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(2)}
                    >
                      <h4>Benefits</h4>
                      <span>{subActiveIndex === 2 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 2 && (
                      <div className="sub-accordion-body">
                        <br></br>
                        <ul>
                          <li>
                            Fortified with very carefully selected anti-wear
                            additives
                          </li>
                          <li>
                            They also show exceptional hydrolytic stability and
                            demulsibility and resist any deterioration due to
                            water contamination.
                          </li>
                          <li>
                            These oils are compatible with seals normally used
                            in hydraulic systems.
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="accordion-item">
            <div
              className="accordion-header"
              onClick={() => handleAccordionClick(20)}
            >
              <h3>Hydrol CE</h3>
              <span>{activeIndex === 20 ? "-" : "+"}</span>
            </div>
            {activeIndex === 20 && (
              <div className="accordion-body">
                Premium ashless hydraulic fluid for long life and improved
                efficiency
                <br></br>
                Kyros Hydrol CE oils are high performance premium quality
                hydraulic oils blended from highly refined, high viscosity index
                Group II plus base stocks with carefully selected ashless
                (Zinc-free) antiwear additive. These oils are designed to
                operate over a wide range of working conditions including wide
                load fluctuation. They possess high FZG rating and provide
                outstanding protection and performance. Outstanding oxidation
                and thermal stability offers long oil life, a high degree of
                protection and minimised deposit formation.
                <div className="sub-accordion">
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(0)}
                    >
                      <h4>Specifications</h4>
                      <span>{subActiveIndex === 0 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 0 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>FZG Niemann EP 11th FLS</li>
                          <li>IS 11656 : 1986 [Reaffirmed 2002]</li>
                          <li>DIN 51524 Part 2 HLP type</li>
                          <li>Denison HF-0 & HF-2</li>
                          <li>Vickers 1-286-S US</li>
                          <li>Steel 127</li>
                          <li>Cincinnati Milacron P-70</li>
                        </ul>
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(1)}
                    >
                      <h4>Applications</h4>
                      <span>{subActiveIndex === 1 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 1 && (
                      <div className="sub-accordion-body">
                        Zinc free ash less type oil suitable for high pressure
                        hydraulic systems, pumps, industrial hydraulic
                        machineries operating at high speeds, loads and
                        temperatures.
                        <br></br>
                        Sophisticated high performance electro-hydraulic or
                        numerically controlled systems found in concrete mixture
                        units used in construction applications. Also suitable
                        for the lubrication of screw and vane type rotary
                        compressors.
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(2)}
                    >
                      <h4>Benefits</h4>
                      <span>{subActiveIndex === 2 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 2 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>
                            Excellent EP properties which leads to better
                            response to high load conditions
                          </li>
                          <li>
                            Extra heavy duty anti-wear oils with outstanding
                            thermal stability
                          </li>
                          <li>
                            Possess excellent compatibility with seals normally
                            used in hydraulic system.
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="accordion-item">
            <div
              className="accordion-header"
              onClick={() => handleAccordionClick(21)}
            >
              <h3>Hydrol HLP</h3>
              <span>{activeIndex === 21 ? "-" : "+"}</span>
            </div>
            {activeIndex === 21 && (
              <div className="accordion-body">
                Industrial extra heavy duty hydraulic fluid for long life,
                excellent performance and protection
                <br></br>
                Kyros Hydrol HLP oils are high performance premium quality
                hydraulic oils. They are blended from highly refined, high
                viscosity index Group II plus base oils with carefully selected
                balanced antiwear additive. These oils are designed to operate
                over a wide range of working conditions including low load and
                severe high load conditions. They provide outstanding protection
                and performance in manufacturing and other operations.
                <div className="sub-accordion">
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(0)}
                    >
                      <h4>Specifications</h4>
                      <span>{subActiveIndex === 0 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 0 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>11th FLS of FZG – Niemann EP test</li>
                          <li>IS 11656: 1986 [Reaffirmed March, 2002]</li>
                          <li>DIN 51 524 Part II HL P Type</li>
                          <li>Denison HF-0 & HF-2</li>
                          <li>Eaton Vickers I-286-S & M-2950-S</li>
                          <li>Eaton Vickers 35VQ25 Pump Test</li>
                          <li>US Steel 127</li>
                          <li>Cincinnati Milacron P-70</li>
                        </ul>
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(1)}
                    >
                      <h4>Applications</h4>
                      <span>{subActiveIndex === 1 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 1 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>
                            Recommended for hydraulic systems and a wide variety
                            of circulation systems of industrial and automotive
                            equipment.
                          </li>
                          <li>
                            Also used in General manufacturing, Metal and Power
                            equipment
                          </li>
                          <li>
                            Suitable for the lubrication of screw and vane type
                            rotary compressors recommended for mining and
                            construction equipment
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(2)}
                    >
                      <h4>Benefits</h4>
                      <span>{subActiveIndex === 2 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 2 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>
                            Outstanding Oxidation Stability: Reduces
                            consumption, filter blocking and valve sticking.
                          </li>
                          <li>
                            Good water and foam resistant properties: Separates
                            out water very fast in case of water ingression
                            releases entrapped air immediately to avoid foaming.
                          </li>
                          <li>
                            Excellent Hydrolytic Stability: This avoids
                            absorption of water in oil and thus enhances fluid
                            life.
                          </li>
                          <li>
                            Excellent Seal Compatibility: Prolongs seal life and
                            reduces maintenance.
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="accordion-item">
            <div
              className="accordion-header"
              onClick={() => handleAccordionClick(22)}
            >
              <h3>Hydrol HVLP</h3>
              <span>{activeIndex === 22 ? "-" : "+"}</span>
            </div>
            {activeIndex === 22 && (
              <div className="accordion-body">
                Industrial high viscosity index extra heavy duty hydraulic fluid
                for excellent performance over wide temperature range
                <br></br>
                Kyros Hydrol HVLP oils are high performance premium quality
                hydraulic oils. They are blended from hydroprocessed, high
                viscosity index Group II plus base oils with proven zinc based
                antiwear additive. These oils are designed with excellent
                viscosity control to operate over a wide range of working
                conditions including severe mechanical stress and wide
                temperature range. They provide outstanding protection and
                performance in manufacturing and other operations subjected to
                wide ranges of ambient and operating temperatures.
                <div className="sub-accordion">
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(0)}
                    >
                      <h4>Specifications</h4>
                      <span>{subActiveIndex === 0 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 0 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>IS 11656 : 1986 [Reaffirmed 2002]</li>
                          <li>
                            DIN 51 524 Part 3 – HVLP Type Denison HF-0 & HF-2
                          </li>
                          <li>Vickers 1-286-S</li>
                          <li>US Steel 127</li>
                          <li>Cincinnati Milacron P-70</li>
                        </ul>
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(1)}
                    >
                      <h4>Applications</h4>
                      <span>{subActiveIndex === 1 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 1 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>
                            Recommended for high pressure systems and power
                            transmission systems requiring very high viscosity
                            index oil, operating under high speeds, load and
                            temperatures.
                          </li>

                          <li>
                            Also recommended for hydraulic applications with
                            sophisticated high performance electro-hydraulic or
                            numerically controlled systems.
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(2)}
                    >
                      <h4>Benefits</h4>
                      <span>{subActiveIndex === 2 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 2 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>
                            Outstanding Anti-wear performance: Ensures minimum
                            wear throughout the range of operations.
                          </li>
                          <li>
                            Outstanding Oxidation Stability: Reduces
                            consumption, filter blocking and valve sticking.
                          </li>
                          <li>
                            Good water and foam resistant properties: Separates
                            out water very fast in case of water ingression
                          </li>
                          <li>Excellent Hydrolytic Stability</li>
                          <li>Excellent Seal Compatibility</li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* Seventh */}
      <div className="auto-lubricant">
        <div className="auto-lubricant-text">
          <h2>Transformers</h2>
          <img
            src="https://www.bharatpetroleum.in/images/220x220-Transformer-oils.jpg"
            alt=""
          />
        </div>
        <div className="accordion">
          <div className="accordion-item">
            <div
              className="accordion-header"
              onClick={() => handleAccordionClick(23)}
            >
              <h3>About</h3>
              <span>{activeIndex === 23 ? "-" : "+"}</span>
            </div>
            {activeIndex === 23 && (
              <div className="accordion-body">
                It consists of two coils of wire, known as the primary and
                secondary windings, which are wound around a common magnetic
                core. When an alternating current (AC) is passed through the
                primary winding, it creates a magnetic field that induces a
                voltage in the secondary winding. The ratio of the number of
                turns in the primary winding to the number of turns in the
                secondary winding determines the amount of voltage change that
                occurs.
              </div>
            )}
          </div>
          <div className="accordion-item">
            <div
              className="accordion-header"
              onClick={() => handleAccordionClick(24)}
            >
              <h3>Hivoltol</h3>
              <span>{activeIndex === 24 ? "-" : "+"}</span>
            </div>
            {activeIndex === 24 && (
              <div className="accordion-body">
                High performance uninhibited electrical insulating oil
                <br></br>
                Kyros Hivoltol is a high quality mineral uninhibited electrical
                insulating oil. It is manufactured from severely hydrotreated
                low aromatic oil and does not have polar compounds. It has very
                good dielectric property, high resistance to oxidation and
                aging. It has excellent low temperature property, low viscosity
                and provides efficient heat transfer. Kyros Hivoltol has a high
                resistance to thermal and chemical degradation and offers very
                high breakdown voltage. Special care is taken to achieve very
                low water content in the oil. It is noncorrosive towards copper
                and does not require passivation.
                <div className="sub-accordion">
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(0)}
                    >
                      <h4>Specification</h4>
                      <span>{subActiveIndex === 0 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 0 && (
                      <div className="sub-accordion-body">
                        IS 335:2018 (Type II) – Uninhibited
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(1)}
                    >
                      <h4>Applications</h4>
                      <span>{subActiveIndex === 1 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 1 && (
                      <div className="sub-accordion-body">
                        It is used as a quenching media for power and
                        distribution electrical apparatus such as transformers,
                        switch gears, circuit breakers, capacitors and other
                        allied equipment.
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(2)}
                    >
                      <h4>Benefits</h4>
                      <span>{subActiveIndex === 2 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 2 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>
                            Outstanding Oxidation Stability :No sludge
                            formation, Improves life of oil & solid insulation
                          </li>
                          <li>
                            High volume Resistivity : Gives high insulation
                            resistance between transformer windings
                          </li>
                          <li>
                            Very low dielectric dissipation factor: Improves
                            efficiency of transformer
                          </li>
                          <li>
                            Highly refined Group II plus oil : low aromatic and
                            sulphur content and have high initial interfacial
                            tension
                          </li>
                          <li>
                            Non corrosive and low acidity formation : Enhance
                            life of oil and transformer
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="accordion-item">
            <div
              className="accordion-header"
              onClick={() => handleAccordionClick(25)}
            >
              <h3>Benefits</h3>
              <span>{activeIndex === 25 ? "-" : "+"}</span>
            </div>
            {activeIndex === 25 && (
              <div className="accordion-body">
                Voltage regulation: Transformers can regulate voltage levels in
                electrical circuits. , Electrical isolation:which helps prevent
                electrical noise and interference from disrupting sensitive
                electronic devices. , Efficiency: transferring electrical power
                from one circuit to another, with minimal power loss during the
                process. , Safety: Transformers help improve electrical safety
                by isolating the input and output circuits.
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default LubTextile;
