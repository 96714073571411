import React, { useEffect } from "react";

function LubricantAutoMobile() {
  useEffect(() => {
    window.scroll(0, 0);
  });
  return (
    <div className="lubricant-container">
      <div className="lubricant">
        <h2 className="primary-subheading">
          Exploring Opportunities Towards Success
        </h2>
        <p className="primary-information">
          Kyros Immpex Private Limited (KIPL) is a private company also, KIPL is
          a Fortune 500 company which has also claimed its rightful place in the
          Forbes 2000 listing.
        </p>
        <p className="primary-information">
          We have a network of 24 marketing laboratories spread across India.
          All these testing laboratories are equipped with the state-of-the-art
          test facilities armed with most modern equipment for calibration,
          testing, and validation. We are pioneers in India to obtain NABL
          accreditation for our Sewree facility in Mumbai as a 'Proficiency Test
          Provider' for petroleum products. Recently, we have started producing
          ‘Reference Material’ for petroleum products at this facility. The
          reference materials are produced as per ISO:17034:2016. We have been
          accredited as the first Reference Material Producer (RMP) in the
          country as per ISO:17034:2016 by NABL (National Accreditation Board
          for Testing and Calibration Laboratories), a Constituent Board of
          Quality Council of India, an autonomous body under the Department of
          Industrial Policy and Promotion, Govt. of India.
        </p>
        <p className="primary-information">
          KIPL RMP is a part of KIPL QA Lab, Sewree, Mumbai. The sophisticated
          lab is NABL-accredited as per ISO 17025:2005 and is involved in
          testing and validating petroleum products. All three set-ups,
          consisting of Testing Laboratory, Proficiency Test Provider and
          Reference Material Producer, are located under one single roof, which
          enhances the synergy of operation. This not only increases efficiency,
          but also helps in quicker turnaround of testing and results.
        </p>
        <p className="primary-information">
          For further information, or if you need any assistance, please get in
          touch with us at the following contact details.
          <br></br>
          Email: director.kyros@gmail.com <br></br>Tel: +91-8888936424
        </p>
        <h3 className="primary-subheading">What is a Reference Material?</h3>
        <img
          src="https://www.bharatpetroleum.in/images/900x420-Proficiency-testing.png"
          alt=""
        ></img>
        <ul>
          <li> to monitor laboratory performance</li>
          <li>to validate methods</li>
          <li>to enable comparison of methods</li>
          <li>to demonstrate quality of culture media</li>
          <li>to demonstrate consistent performance of kits</li>
        </ul>

        <h3 className="primary-subheading">Why go for KIPL</h3>
        <img
          src="https://www.bharatpetroleum.in/images/900x420-Proficiency-testing-2.png"
          alt=""
        />
        <ul>
          <li>
            {" "}
            These materials are certified in accordance with ISO:17034:2016
          </li>
          <li>CRMs are traceable to national/international standards</li>
          <li>Affordable pricing</li>
          <li>Hassle-free procurement</li>
        </ul>
      </div>
    </div>
  );
}

export default LubricantAutoMobile;
