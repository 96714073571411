import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

function Piperazine() {
  const occref = useRef(null);
  const dsoccref = useRef(null);
  const onpref = useRef(null);
  const tissueref = useRef(null);
  const location = useLocation();
  useEffect(() => {
    window.scroll(0, 0);
    if (location.hash === "#scrollocc") {
      occref.current.scrollIntoView({ behaviour: "smooth" });
    }
    if (location.hash === "#scrolldsocc") {
      dsoccref.current.scrollIntoView({ behaviour: "smooth" });
    }
    if (location.hash === "#scrollonp") {
      onpref.current.scrollIntoView({ behaviour: "smooth" });
    }
    if (location.hash === "#scrolltissue") {
      tissueref.current.scrollIntoView({ behaviour: "smooth" });
    }
  }, [location]);
  return (
    <div>
      <div className="work-section-wrapper">
        <div className="work-section-top">
          <h1 className="primary-mainheading">Piperazine Derivatives</h1>
        </div>
        <br></br>
        <br></br>
      </div>

      <div className="work-section-top" ref={occref} id="scrollocc">
        <h2 className="primary-heading">Piperazine Citrate</h2>
        <div className="fruit-images">
          <div className="fruit1" style={{ display: "flex" }}>
            <div className="one">
              <img
                src="http://www.adanipharma.com/images/product/piperazine/1.jpg"
                alt="is-corn-grain-1544222258"
                border="0"
              />
              <p>Molecular Structure</p>
            </div>
            <div className="one">
              <img
                src="http://www.adanipharma.com/images/product/piperazine/01.jpg"
                alt="is-corn-grain-1544222258"
                border="0"
              />
              <p>Product Image</p>
            </div>
          </div>
        </div>
        <p>
          Uses of Product<br></br>
          <ul>
            <li>It is used as a dough conditioner.</li>
            <li>
              It is an oxidant added to lotions and ointments as an antiseptic,
              disinfectant and deodorant.
            </li>
            <li>
              It is used in animal feed to correct iodine deficiency and prevent
              various animal diseases.
            </li>
            <li>In food industry it is used for iodization of table salt.</li>
          </ul>
        </p>
        <p>
          Piperazine manufacturers and exporters India<br></br>
          How can we use piperazine derivatives? If this question arises in your
          mind again and again then we executives of Kyros pharmaceutical
          company is here to help and introduce with the it.
          <br></br>
          <ul>
            <li>
              If you like conditioning then they are really made up for you.
              Dough conditioning is possible due to the efforts of piperazine
              manufacturers and piperazine exporters.
            </li>
            <li>
              It is added to ointments and lotion due to its property of
              oxidation.
            </li>
            <li>It is used as an antiseptic, deodorant, disinfectant.</li>
            <li>
              Iodine deficiency is prevented among the animals by included
              piperzine into them.
            </li>
            <li>
              Table salt is also a food industry product which helps us to
              prevent from the deficiency of iodine.
            </li>
          </ul>
        </p>
      </div>
      <div className="line-dividing"></div>

      <div className="work-section-top" id="scrolldsocc" ref={dsoccref}>
        <h2 className="primary-heading">Piperazine Phosphate</h2>
        <div className="fruit-images">
          <div className="fruit1" style={{ display: "flex" }}>
            <div className="one">
              <img
                src="http://www.adanipharma.com/images/product/piperazine/2.jpg"
                alt="is-corn-grain-1544222258"
                border="0"
              />
              <p>Molecular Structure</p>
            </div>
            <div className="one">
              <img
                src="http://www.adanipharma.com/images/product/piperazine/02.jpg"
                alt="is-corn-grain-1544222258"
                border="0"
              />
              <p>Product Image</p>
            </div>
          </div>
        </div>
        <p>
          Certain types Piperazine Phosphate medicines are present in the market
          but most of the seller prefer products from the Kyros Pharmaceutical
          industries to sell. It takes to defend against the infection like
          pinworms and roundworms. Sienna, piperazine and many other ingredients
          contained with the pharmaceutical are with pripsen molecules.
          <br></br>
          Laxative medicine like sienna drugs are taken to paralyze the worms
          and the drugs we offer, immediately act on them and completely remove
          it. The powder form is present for the children also.
          <br></br>
          <b>Range for consumers</b>
          <br></br>
          Always, we try to give our 100 percent attention when we are working,
          not just for our reputation and money making view. We work for the
          welfare of people because our single mistake can destroy the life of
          consumers and this is definitely taken care by us.
          <br></br>
          We suggest all the clients that always use pure product with the
          expert advice. Otherwise this will spoil your health in any manner.
          Effective elimination of round warms and thread warms from the
          children’s bowl is possible with the pripsen piperazine phosphate
          sachet packs.
        </p>
      </div>
      <div className="line-dividing"></div>

      <div className="work-section-top" id="scrollonp" ref={onpref}>
        <h2 className="primary-heading">Piperazine Adipate</h2>
        <div className="fruit-images">
          <div className="fruit1" style={{ display: "flex" }}>
            <div className="one">
              <img
                src="http://www.adanipharma.com/images/product/piperazine/3.jpg"
                alt="is-corn-grain-1544222258"
                border="0"
              />
              <p>Molecular Structure</p>
            </div>
            <div className="one">
              <img
                src="http://www.adanipharma.com/images/product/piperazine/03.jpg"
                alt="is-corn-grain-1544222258"
                border="0"
              />
              <p>Product Image</p>
            </div>
          </div>
        </div>
        <p>
          Product Usage<br></br>
          Piperazine adipate is good to treat intestinal roundworms or pinworm
          infection for both inside humans and farm animals too.
        </p>
      </div>
      <div className="line-dividing"></div>

      <div className="work-section-top">
        <h2 className="primary-heading" id="scrolltissue" ref={tissueref}>
          Piperazine Hexahydrate
        </h2>
        <div className="fruit-images">
          <div className="fruit1" style={{ display: "flex" }}>
            <div className="one">
              <img
                src="http://www.adanipharma.com/images/product/piperazine/4.jpg"
                alt="is-corn-grain-1544222258"
                border="0"
              />
              <p>Molecular Structure</p>
            </div>
            <div className="one">
              <img
                src="http://www.adanipharma.com/images/product/piperazine/04.jpg"
                alt="is-corn-grain-1544222258"
                border="0"
              />
              <p>Product Image</p>
            </div>
          </div>
        </div>
        <p>
          Uses of Product<br></br>
          Piperazine is used for treating common roundworms and pinworms
          infection. Kyros Pharma offers a premium range of piperazine
          hexahydrate at best prices. You can share your requirement with us and
          avail our products. We are supplying our products across the globe.
        </p>
      </div>
      <div className="line-dividing"></div>

      <div className="work-section-top">
        <h2 className="primary-heading">Piperazine Di HCI</h2>
        <div className="fruit-images">
          <div className="fruit1" style={{ display: "flex" }}>
            <div className="one">
              <img
                src="http://www.adanipharma.com/images/product/piperazine/5.jpg"
                alt="is-corn-grain-1544222258"
                border="0"
              />
              <p>Molecular Structure</p>
            </div>
            <div className="one">
              <img
                src="http://www.adanipharma.com/images/product/piperazine/05.jpg"
                alt="is-corn-grain-1544222258"
                border="0"
              />
              <p>Product Image</p>
            </div>
          </div>
        </div>
        <p>
          Uses of Product<br></br>
          It is used as a one day wormer, either in water or feed, for the
          control of Round worms in poultry, swine, and horses. It also controls
          nodular worms in swine, and small strongyles in horses.
        </p>
      </div>
      <div className="line-dividing"></div>

      <div className="work-section-top">
        <h2 className="primary-heading">Di-Piperazine Sulphate</h2>
        <div className="fruit-images">
          <div className="fruit1" style={{ display: "flex" }}>
            <div className="one">
              <img
                src="http://www.adanipharma.com/images/product/piperazine/6.jpg"
                alt="is-corn-grain-1544222258"
                border="0"
              />
              <p>Molecular Structure</p>
            </div>
            <div className="one">
              <img
                src="http://www.adanipharma.com/images/product/piperazine/06.jpg"
                alt="is-corn-grain-1544222258"
                border="0"
              />
              <p>Product Image</p>
            </div>
          </div>
        </div>
        <p>
          Uses of Product<br></br> For the removal of large roundworms
          (Ascaridia spp.) from turkeys, chickens, and large roundworms
          (Ascarissumm) and nodular worms (Oesophagostomum spp.) from swine.
        </p>
      </div>
    </div>
  );
}

export default Piperazine;
