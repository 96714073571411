import "./App.css";
import React, { useEffect } from "react";
//import Home from "./Components/Home";
import About from "./Components/About";
//import Work from "./Components/Work";
//import Testimonial from "./Components/Testimonial";
//import $ from "jquery";
import "jquery-ui/ui/widgets/draggable";
import Contact from "./Components/Contact";
import Footer from "./Components/Footer";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./Components/Navbar";
import Slider from "./Components/Slider";
import SolventProduct from "./Components/Solvent_Product";
import AlcoholProduct from "./Components/Alcohol_Products";
import SuperFoodProduct from "./Components/SuperFood_Products";
import VegetablesProduct from "./Components/Vegetables_Product";
import Intro from "./Components/Introduction";
import GuarProducts from "./Components/Guar_Products";
import More from "./Components/More";
import FrozenFruit from "./Components/FrozenFruits";
import Partner from "./Components/Partner";
import Rice from "./Components/Rice";
import Lubricants from "./Components/Lubricants/Lubricants";
import LubricantAutoMobile from "./Components/Lubricants/LubricantAutoMobile";
import LubIndustrial from "./Components/Lubricants/IndustrialLub";
import LubInfrastructure from "./Components/Lubricants/LubInfrastructure";
import LubConnect from "./Components/Lubricants/LubConnect";
import LubAbout from "./Components/Lubricants/LubAbout";
import LubCenter from "./Components/Lubricants/LubCenter";
import LubPartner from "./Components/Lubricants/LubPart";
import LubTest from "./Components/Lubricants/LubTest";
import LubRd from "./Components/Lubricants/LubRd";
import LubEpedia from "./Components/Lubricants/LubEpedia";
import LubAuto from "./Components/Lubricants/Industrial/LubAuto";
import LubCement from "./Components/Lubricants/Industrial/LubCement";
import LubMachine from "./Components/Lubricants/Industrial/LubMachine";
import LubLubricant from "./Components/Lubricants/Industrial/LubLubricant";
import Scrap from "./Components/Scrap";
import Youtube from "./Components/Youtube";
import Ewaste from "./Components/Ewaste";
import Paper from "./Components/Paper";
import Hero from "./Components/Hero";
import Products from "./Components/Products";
import Recycle from "./Components/Recycle";
import Iodine from "./Components/API/Iodine";
import Piperazine from "./Components/API/Piperazine";
import Citrate from "./Components/API/Citrate";
import OtherApi from "./Components/API/OtherApi";
import Spiceole from "./Components/API/Spiceole";
import Api from "./Components/Api";
import LunInfra from "./Components/Lubricants/Industrial/LunInfra";
import LubPower from "./Components/Lubricants/Industrial/LubPower";
import LubSteel from "./Components/Lubricants/Industrial/LubSteel";
import LubSugar from "./Components/Lubricants/Industrial/LubSugar";
import LubTextile from "./Components/Lubricants/Industrial/LubTextile";
import Castor from "./Components/Castor";
import AllProducts from "./Components/AllProducts";
function App() {
  useEffect(() => {
    window.scrollTo(0, 0);
    // $(".whatsapp_float").draggable();
  }, []);
  return (
    <Router>
      <div className="App">
        <div id="google_translate_element"></div>
        <Navbar />

        <div className="whatsapp_float">
          <a
            href="https://wa.me/919028796410"
            target="_blank"
            alt="link"
            rel="noreferrer"
          >
            <img
              src="https://i.ibb.co/7QFcnbP/unnamed-1.png"
              width={50}
              alt="unnamed-1"
              border="0"
            />
          </a>
        </div>

        <Routes>
          <Route
            path="/"
            element={
              <>
                {/* <Home /> */}
                <Slider />
                <Hero />
                <Intro />
                {/* <Work /> */}

                <About />
                {/* <Testimonial /> */}
                <Youtube />

                <Footer />
              </>
            }
          />
          <Route path="/about" element={<More />} />

          <Route path="/castor" element={<Castor />} />
          <Route path="/productsList" element={<Products />} />
          <Route path="/products" element={<AllProducts />} />
          <Route path="/recycle" element={<Recycle />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/solvent" element={<SolventProduct />} />
          <Route path="/alcohol" element={<AlcoholProduct />} />
          <Route path="/super-food" element={<SuperFoodProduct />} />
          <Route path="/vegetables" element={<VegetablesProduct />} />
          <Route path="/learn-more" element={<More />}></Route>
          <Route path="/guar-gum-powder" element={<GuarProducts />}></Route>
          <Route path="/frozen-fruit" element={<FrozenFruit />}></Route>
          <Route path="/partner" element={<Partner />}></Route>
          <Route path="/rice" element={<Rice />}></Route>
          <Route path="/lubricants" element={<Lubricants />}></Route>
          <Route path="/scrap" element={<Scrap />}></Route>
          <Route path="/ewaste" element={<Ewaste />}></Route>
          <Route path="/paper" element={<Paper />}></Route>
          <Route
            path="/lubricant-automobile"
            element={<LubricantAutoMobile />}
          ></Route>
          <Route
            path="/lubricant-industrial"
            element={<LubIndustrial />}
          ></Route>
          <Route
            path="/lubricant-infra"
            element={<LubInfrastructure />}
          ></Route>
          <Route path="/lubricant-connect" element={<LubConnect />}></Route>
          <Route path="/lubricant-about" element={<LubAbout />}></Route>
          <Route path="/lubricant-center" element={<LubCenter />}></Route>
          <Route path="/lubricant-partner" element={<LubPartner />}></Route>
          <Route path="/lubricant-testing" element={<LubTest />}></Route>
          <Route path="/lubricant-rd" element={<LubRd />}></Route>
          <Route path="/lubricant-epedia" element={<LubEpedia />}></Route>

          {/* LubricantIndustrial Start */}
          <Route
            path="/lubricant-industrial-auto"
            element={<LubAuto />}
          ></Route>
          <Route
            path="/lubricant-industrial-cement"
            element={<LubCement />}
          ></Route>
          <Route
            path="/lubricant-industrial-machine"
            element={<LubMachine />}
          ></Route>
          <Route
            path="/lubricant-industrial-lubricants"
            element={<LubLubricant />}
          ></Route>
          <Route
            path="/lubricant-industrial-infra"
            element={<LunInfra />}
          ></Route>
          <Route
            path="/lubricant-industrial-power"
            element={<LubPower />}
          ></Route>
          <Route
            path="/lubricant-industrial-steel"
            element={<LubSteel />}
          ></Route>
          <Route
            path="/lubricant-industrial-sugar"
            element={<LubSugar />}
          ></Route>
          <Route
            path="/lubricant-industrial-textile"
            element={<LubTextile />}
          ></Route>

          {/*LubricantIndustrial Ends*/}
          {/* API starts */}
          <Route path="/api" element={<Api />}></Route>
          <Route path="/iodine" element={<Iodine />}></Route>
          <Route path="/piperazine" element={<Piperazine />}></Route>
          <Route path="/citrate" element={<Citrate />}></Route>
          <Route path="/other-api" element={<OtherApi />}></Route>
          <Route path="/spiceole" element={<Spiceole />}></Route>
          {/* API ends */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
