import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

function Paper() {
  const occ = [
    {
      name: "OCC",
      uni: "1",
      img: "https://3.imimg.com/data3/DY/CK/MY-11971463/occ-old-corrugated-containers-500x500.jpg",
    },
    {
      name: "OCC",
      uni: "2",
      img: "https://media.smurfitkappa.com/-/m/images/products-images-740x-740/recycling/recycling/105recoveredpaper-jpeg.jpg",
    },
  ];
  const dsocc = [
    {
      name: "DSOCC",
      uni: "3",
      img: "https://5.imimg.com/data5/SELLER/Default/2022/6/HA/QR/HY/116603386/dsocc-occ-12-grade-500x500.jpeg",
    },
    {
      name: "DSOCC",
      uni: "4",
      img: "https://tekascrapltd.com/wp-content/uploads/2019/11/Double-Sorted-Old-Corrugated-Cartons.jpg",
    },
  ];
  const onp = [
    {
      name: "ONP",
      uni: "5",
      img: "https://img3.exportersindia.com/product_images/bc-full/2022/2/9479958/occ-onp-oinp-waste-paper-scrap-1644347147-6193297.jpeg",
    },
    {
      name: "ONP",
      uni: "6",
      img: "https://web.tradekorea.com/product/317/1637317/Waste%20Paper%20_%20ONP_%20OINP_%20OCC_1.jpg",
    },
  ];
  const tissue = [
    {
      name: "Tissue",
      uni: "7",
      img: "https://5.imimg.com/data5/SELLER/Default/2023/3/296828885/BD/ZQ/CM/151109119/2-500x500.jpg",
    },
    {
      name: "Tissue",
      uni: "8",
      img: "https://5.imimg.com/data5/LL/CZ/LL/ANDROID-42398965/product-jpeg-500x500.jpg",
    },
  ];
  const bbc = [
    {
      name: "BBC",
      uni: "9",
      img: "https://i.ibb.co/S7Fvxsd/Whats-App-Image-2023-05-13-at-16-37-40.jpg",
    },
    {
      name: "BBC",
      uni: "10",
      img: "https://is2.ecplaza.com/ecplaza1/offers/a/a4/a46/1695033929/buy-waste-paper.jpg",
    },
  ];
  const sow = [
    {
      name: "SOW",
      uni: "11",
      img: "https://asset.balebid.com/uploads/auction_images/zlpqjyqmYR4CBSEJ_1601539778.jpg",
    },
    {
      name: "SOW",
      uni: "12",
      img: "https://asset.balebid.com/uploads/auction_images/AymxOaou81ws3jo5_1601539777.jpg",
    },
  ];
  const swl = [
    {
      name: "SWL",
      uni: "13",
      img: "https://d1shuhu6tm6s0s.cloudfront.net/productimages/071019004205_IMG_4231.jpg",
    },
    {
      name: "SWL",
      uni: "14",
      img: "https://d1shuhu6tm6s0s.cloudfront.net/productimages/071019004204_IMG_4232.jpg",
    },
  ];
  const ncc = [
    {
      name: "NCC",
      uni: "15",
      img: "https://5.imimg.com/data5/IOS/Default/2021/6/YB/WL/AQ/44795817/product-jpeg-1000x1000.png",
    },
    {
      name: "NCC",
      uni: "16",
      img: "https://5.imimg.com/data5/EL/QI/MY-5374665/ncc-waste-paper-500x500.jpg",
    },
  ];
  const ndklc = [
    {
      name: "NDKLC",
      uni: "18",
      img: "https://www.wastepaperbrokers.com/wp-content/uploads/2022/02/DLK.jpg",
    },
    {
      name: "NDKLC",
      uni: "17",
      img: "https://5.imimg.com/data5/BJ/QH/MY-5374665/ndlkc-waste-paper-500x500.jpg",
    },
  ];
  const occref = useRef(null);
  const dsoccref = useRef(null);
  const onpref = useRef(null);
  const tissueref = useRef(null);
  const location = useLocation();
  useEffect(() => {
    window.scroll(0, 0);
    if (location.hash === "#scrollocc") {
      occref.current.scrollIntoView({ behaviour: "smooth" });
    }
    if (location.hash === "#scrolldsocc") {
      dsoccref.current.scrollIntoView({ behaviour: "smooth" });
    }
    if (location.hash === "#scrollonp") {
      onpref.current.scrollIntoView({ behaviour: "smooth" });
    }
    if (location.hash === "#scrolltissue") {
      tissueref.current.scrollIntoView({ behaviour: "smooth" });
    }
  }, [location]);
  return (
    <div>
      <div className="work-section-wrapper">
        <div className="work-section-top">
          <h1 className="primary-mainheading">Paper Scrap</h1>
        </div>
        <br></br>
        <br></br>
        <div className="metal-image">
          <img
            src="https://i.ibb.co/wrH9jkY/Green-Pastel-Maximalism-Zero-Waste-Instagram-Post-1.png"
            alt="Green-Pastel-Maximalism-Zero-Waste-Instagram-Post-1"
            border="0"
          />
        </div>
        <div className="work-section-top" ref={occref} id="scrollocc">
          <h2 className="primary-heading">OCC (Old Corrugated Containers)</h2>
        </div>
        <div className="fruit-container">
          <div className="intro-text">
            <div className="fruit-section-bottom">
              {occ.map((item) => (
                <div
                  className="fruit-section-info"
                  key={item.uni}
                  style={{ backgroundImage: `url(${item.img})` }}
                >
                  <h1>{item.name}</h1>
                  <p>{item.text}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* <div className="fruit-images">
          <div className="fruit1">
            <div className="one">
              <img
                src="https://3.imimg.com/data3/DY/CK/MY-11971463/occ-old-corrugated-containers-500x500.jpg"
                alt="is-corn-grain-1544222258"
                border="0"
              />
            </div>
            <div className="one">
              <img
                src="https://media.smurfitkappa.com/-/m/images/products-images-740x-740/recycling/recycling/105recoveredpaper-jpeg.jpg"
                alt="is-corn-grain-1544222258"
                border="0"
              />
            </div>
          </div>
        </div> */}
        <div className="work-section-top">
          <p>
            OCC is a common grade of paper scrap that includes used corrugated
            boxes and other cardboard packaging materials. OCC is widely
            recycled and used to manufacture new paper products.
          </p>

          <br></br>
          <div className="metal-content-bottom">
            <b>MOQ: </b>5 x 40ft FCL
            <br></br>
            <b>Loading per 40ft HC FCL: </b>
            22-28MT
          </div>
        </div>
        <div className="line-dividing-metal" />
        <div className="work-section-top" id="scrolldsocc" ref={dsoccref}>
          <h2 className="primary-heading">
            DSOCC (Double Sorted Old Corrugated Containers)
          </h2>
        </div>
        .
        <div className="fruit-container">
          <div className="intro-text">
            <div className="fruit-section-bottom">
              {dsocc.map((item) => (
                <div
                  className="fruit-section-info"
                  key={item.uni}
                  style={{ backgroundImage: `url(${item.img})` }}
                >
                  <h1>{item.name}</h1>
                  <p>{item.text}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* <div className="fruit-images">
          <div className="fruit1">
            <div className="one">
              <img
                src="https://5.imimg.com/data5/SELLER/Default/2022/6/HA/QR/HY/116603386/dsocc-occ-12-grade-500x500.jpeg"
                alt="is-corn-grain-1544222258"
                border="0"
              />
            </div>
            <div className="one">
              <img
                src="https://tekascrapltd.com/wp-content/uploads/2019/11/Double-Sorted-Old-Corrugated-Cartons.jpg"
                alt="is-corn-grain-1544222258"
                border="0"
              />
            </div>
          </div>
        </div> */}
        <div className="work-section-top">
          <p>
            DSOCC is a higher quality grade of OCC that has been sorted to
            remove any non-corrugated materials, such as paperboard or mixed
            paper.
          </p>

          <br></br>
          <div className="metal-content-bottom">
            <b>MOQ: </b>5 x 40ft FCL
            <br></br>
            <b>Loading per 40ft HC FCL: </b>
            22-28MT
          </div>
        </div>
        <div className="line-dividing-metal" />
        <div className="work-section-top" id="scrollonp" ref={onpref}>
          <h2 className="primary-heading">
            ONP/OINP (Old Newspaper/Old Magazine)
          </h2>
        </div>
        <div className="fruit-container">
          <div className="intro-text">
            <div className="fruit-section-bottom">
              {onp.map((item) => (
                <div
                  className="fruit-section-info"
                  key={item.uni}
                  style={{ backgroundImage: `url(${item.img})` }}
                >
                  <h1>{item.name}</h1>
                  <p>{item.text}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* <div className="fruit-images">
          <div className="fruit1">
            <div className="one">
              <img
                src="https://img3.exportersindia.com/product_images/bc-full/2022/2/9479958/occ-onp-oinp-waste-paper-scrap-1644347147-6193297.jpeg"
                alt="is-corn-grain-1544222258"
                border="0"
              />
            </div>
            <div className="one">
              <img
                src="https://web.tradekorea.com/product/317/1637317/Waste%20Paper%20_%20ONP_%20OINP_%20OCC_1.jpg"
                alt="is-corn-grain-1544222258"
                border="0"
              />
            </div>
          </div>
        </div> */}
        <div className="work-section-top">
          <p>
            ONP/OINP is a grade of paper scrap that includes used newspapers and
            magazines. ONP/OINP is typically recycled into newsprint or other
            low-grade paper products.
          </p>

          <br></br>
          <div className="metal-content-bottom">
            <b>MOQ: </b>5 x 40ft FCL
            <br></br>
            <b>Loading per 40ft HC FCL: </b>
            22-28MT
          </div>
        </div>
        <div className="line-dividing-metal" />
        <div className="work-section-top">
          <h2 className="primary-heading" id="scrolltissue" ref={tissueref}>
            Tissue Paper Scrap
          </h2>
        </div>
        <div className="fruit-container">
          <div className="intro-text">
            <div className="fruit-section-bottom">
              {tissue.map((item) => (
                <div
                  className="fruit-section-info"
                  key={item.uni}
                  style={{ backgroundImage: `url(${item.img})` }}
                >
                  <h1>{item.name}</h1>
                  <p>{item.text}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* <div className="fruit-images">
          <div className="fruit1">
            <div className="one">
              <img
                src="https://5.imimg.com/data5/SELLER/Default/2023/3/296828885/BD/ZQ/CM/151109119/2-500x500.jpg"
                alt="is-corn-grain-1544222258"
                border="0"
              />
            </div>
            <div className="one">
              <img
                src="https://5.imimg.com/data5/LL/CZ/LL/ANDROID-42398965/product-jpeg-500x500.jpg"
                alt="is-corn-grain-1544222258"
                border="0"
              />
            </div>
          </div>
        </div> */}
        <div className="work-section-top">
          <p>
            Tissue paper scrap includes used tissues, napkins, paper towels, and
            other paper products. Tissue paper scrap is typically recycled into
            new tissue paper products.
          </p>

          <br></br>
          <div className="metal-content-bottom">
            <b>MOQ: </b>5 x 40ft FCL
            <br></br>
            <b>Loading per 40ft HC FCL: </b>
            22-28MT
          </div>
        </div>
        <div className="line-dividing-metal" />
        <div className="work-section-top">
          <h2 className="primary-heading">BBC (Bulk Books and Catalogues)</h2>
        </div>
        <div className="fruit-container">
          <div className="intro-text">
            <div className="fruit-section-bottom">
              {bbc.map((item) => (
                <div
                  className="fruit-section-info"
                  key={item.uni}
                  style={{ backgroundImage: `url(${item.img})` }}
                >
                  <h1>{item.name}</h1>
                  <p>{item.text}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* <div className="fruit-images">
          <div className="fruit1">
            <div className="one">
              <img
                src="https://i.ibb.co/S7Fvxsd/Whats-App-Image-2023-05-13-at-16-37-40.jpg"
                alt="is-corn-grain-1544222258"
                border="0"
              />
            </div>
            <div className="one">
              <img
                src="https://is2.ecplaza.com/ecplaza1/offers/a/a4/a46/1695033929/buy-waste-paper.jpg"
                alt="is-corn-grain-1544222258"
                border="0"
              />
            </div>
          </div>
        </div> */}
        <div className="work-section-top">
          <p>
            BBC is a grade of paper scrap that includes books, catalogues, and
            other bound materials. BBC is typically recycled into new paper
            products or used as fuel for energy production.
          </p>

          <br></br>
          <div className="metal-content-bottom">
            <b>MOQ: </b>5 x 40ft FCL
            <br></br>
            <b>Loading per 40ft HC FCL: </b>
            22-28MT
          </div>
        </div>
        <div className="line-dividing-metal" />
        <div className="work-section-top">
          <h2 className="primary-heading">SOW (Sorted Office Waste)</h2>
        </div>
        <div className="fruit-container">
          <div className="intro-text">
            <div className="fruit-section-bottom">
              {sow.map((item) => (
                <div
                  className="fruit-section-info"
                  key={item.uni}
                  style={{ backgroundImage: `url(${item.img})` }}
                >
                  <h1>{item.name}</h1>
                  <p>{item.text}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* <div className="fruit-images">
          <div className="fruit1">
            <div className="one">
              <img
                src="https://asset.balebid.com/uploads/auction_images/zlpqjyqmYR4CBSEJ_1601539778.jpg"
                alt="is-corn-grain-1544222258"
                border="0"
              />
            </div>
            <div className="one">
              <img
                src="https://asset.balebid.com/uploads/auction_images/AymxOaou81ws3jo5_1601539777.jpg"
                alt="is-corn-grain-1544222258"
                border="0"
              />
            </div>
          </div>
        </div> */}
        <div className="work-section-top">
          <p>
            SOW is a grade of paper scrap that includes office paper, envelopes,
            and other clean paper products. SOW is typically recycled into new
            paper products or used as a raw material for tissue paper
            production.
          </p>

          <br></br>
          <div className="metal-content-bottom">
            <b>MOQ: </b>5 x 40ft FCL
            <br></br>
            <b>Loading per 40ft HC FCL: </b>
            22-28MT
          </div>
        </div>
        <div className="line-dividing-metal" />
        <div className="work-section-top">
          <h2 className="primary-heading">SWL (Soft White Ledger)</h2>
        </div>
        <div className="fruit-container">
          <div className="intro-text">
            <div className="fruit-section-bottom">
              {swl.map((item) => (
                <div
                  className="fruit-section-info"
                  key={item.uni}
                  style={{ backgroundImage: `url(${item.img})` }}
                >
                  <h1>{item.name}</h1>
                  <p>{item.text}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* <div className="fruit-images">
          <div className="fruit1">
            <div className="one">
              <img
                src="https://d1shuhu6tm6s0s.cloudfront.net/productimages/071019004205_IMG_4231.jpg"
                alt="is-corn-grain-1544222258"
                border="0"
              />
            </div>
            <div className="one">
              <img
                src="https://d1shuhu6tm6s0s.cloudfront.net/productimages/071019004204_IMG_4232.jpg"
                alt="is-corn-grain-1544222258"
                border="0"
              />
            </div>
          </div>
        </div> */}
        <div className="work-section-top">
          <p>
            SWL is a grade of paper scrap that includes high-quality white
            paper, such as printer paper and writing paper. SWL is typically
            recycled into new high-grade paper products.
          </p>

          <br></br>
          <div className="metal-content-bottom">
            <b>MOQ: </b>5 x 40ft FCL
            <br></br>
            <b>Loading per 40ft HC FCL: </b>
            22-28MT
          </div>
        </div>
        <div className="line-dividing-metal" />
        <div className="work-section-top">
          <h2 className="primary-heading">
            NCC (Newspaper Cutting and Corrugated Cutting)
          </h2>
        </div>
        <div className="fruit-container">
          <div className="intro-text">
            <div className="fruit-section-bottom">
              {ncc.map((item) => (
                <div
                  className="fruit-section-info"
                  key={item.uni}
                  style={{ backgroundImage: `url(${item.img})` }}
                >
                  <h1>{item.name}</h1>
                  <p>{item.text}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="work-section-top">
          <p>
            NCC is a grade of paper scrap that includes unused or offcuts of
            newspaper and corrugated paper. NCC is widely recycled and used to
            manufacture new paper products.
          </p>

          <br></br>
          <div className="metal-content-bottom">
            <b>MOQ: </b>5 x 40ft FCL
            <br></br>
            <b>Loading per 40ft HC FCL: </b>
            22-28MT
          </div>
        </div>
        {/* <div className="fruit-images">
          <div className="fruit1">
            <div className="one">
              <img
                src="https://5.imimg.com/data5/IOS/Default/2021/6/YB/WL/AQ/44795817/product-jpeg-1000x1000.png"
                alt="is-corn-grain-1544222258"
                border="0"
              />
            </div>
            <div className="one">
              <img
                src="https://5.imimg.com/data5/EL/QI/MY-5374665/ncc-waste-paper-500x500.jpg"
                alt="is-corn-grain-1544222258"
                border="0"
              />
            </div>
          </div>
        </div> */}
        <div className="line-dividing-metal" />
        <div className="work-section-top">
          <h2 className="primary-heading">
            NDKLC (New Double Kraft Liner Cutting)
          </h2>
        </div>
        <div className="fruit-container">
          <div className="intro-text">
            <div className="fruit-section-bottom">
              {ndklc.map((item) => (
                <div
                  className="fruit-section-info"
                  key={item.uni}
                  style={{ backgroundImage: `url(${item.img})` }}
                >
                  <h1>{item.name}</h1>
                  <p>{item.text}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* <div className="fruit-images">
          <div className="fruit1">
            <div className="one">
              <img
                src="https://d1shuhu6tm6s0s.cloudfront.net/productimages/250x250/210320031518_12%20DSOCC%20(5).jpg"
                alt="is-corn-grain-1544222258"
                border="0"
              />
            </div>
            <div className="one">
              <img
                src="https://www.wastepaperbrokers.com/wp-content/uploads/2022/02/DLK.jpg"
                alt="is-corn-grain-1544222258"
                border="0"
              />
            </div>
          </div>
        </div> */}
        <div className="work-section-top">
          <p>
            NDKLC is a higher quality grade of paper scrap that includes unused
            or offcuts of kraft paper, typically used in packaging applications.
          </p>

          <br></br>
          <div className="metal-content-bottom">
            <b>MOQ: </b>5 x 40ft FCL
            <br></br>
            <b>Loading per 40ft HC FCL: </b>
            22-28MT
          </div>
        </div>
        <br></br>
        <br></br>
      </div>
    </div>
  );
}

export default Paper;
