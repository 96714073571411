import React, { useEffect } from "react";

function LubricantAutoMobile() {
  useEffect(() => {
    window.scroll(0, 0);
  });
  return (
    <div className="lubricant-container">
      <div className="lubricant">
        <h2 className="primary-subheading">Kyros makes It Possible</h2>
        <p className="primary-information">
          Kyros reign eminently as a trusted brand in lubricants and greases in
          India and international markets. The comprehensive range offers an
          extensive selection of 400+ grades spanning various segments – to
          cater to varied and constantly evolving requirements of the automotive
          segment as well as the industrial domain.
        </p>
        <p className="primary-information">
          The philosophy that drives Kyros Lubricants is the cognizance that our
          customers are not just consumers but indeed our partners in progress –
          in our onward quest for better products, longer service life of
          equipment and greater environment-friendliness.
        </p>
        <p className="primary-information">
          Ever since its earliest pioneering superior quality ‘Group II+ base
          oils’ (KyrosBase) to the next-generation Kyros lubricants for BS-VI
          vehicles, Kyros Immpex has been a vigorous innovator of technically
          advanced lubricants that deliver top-notch performance even under
          extreme ambient and operating conditions. With a laser-sharp focus on
          incessant innovation and customer-centricity, no wonder the Kyros
          brand has been bestowed numerous accolades, including the prestigious
          “Superbrand” status.
        </p>

        <h3 className="primary-subheading">Kyros Lubricants Family </h3>
        <img
          src="https://www.bharatpetroleum.in/images/900x420-MAK-1.png"
          alt=""
        ></img>
        <p className="primary-information">
          When you have a profusion of talented minds with a mission to create
          superlative products, then the miracle happens. Innovation sparkles.
          Performances scale newer peaks, Products exceed expectations.
          Customers repose their unflinching trust in you. And technology is
          harnessed to create ever better solutions.
          <br></br>
        </p>
        <h3 className="primary-subheading">Our Evolution Through the years</h3>
        <img
          src="https://www.bharatpetroleum.in/images/900x420-Mak-3(1).png"
          alt=""
        />
        <p className="primary-information">
          OUR EVOLUTION THROUGH THE YEARS Through the yesteryears of limited
          competition and few players, to the current era of intense competition
          among numerous players in a fiercely competitive market, Kyros Immpex
          has evolved following a long-term strategy fueled by continual
          performance enhancements, to lead the change.
        </p>
        <h3 className="primary-subheading">Packaging Design Dynamism</h3>
        <p className="primary-information">
          Reflecting the innovation and customer-focus of its product gamut,
          Kyros Lubricants launched their new packaging range in 2018,
          specifically focusing on customers’ needs. The highlights of the new
          packaging design include attributes like convenience of pouring,
          pilferage-proof sealing, as well as the use of premium material and
          superior build quality of packaging.
        </p>
      </div>
      {/* <div className="images-information">
        <div className="images-container">
          <img
            src="https://www.bharatpetroleum.in/images/2-wheelers(1).jpg"
            alt=""
          />
          <h3>Bikes and Scooters</h3>
        </div>
        <div className="images-container">
          <img
            src="https://www.bharatpetroleum.in/images/275x255-auto-2.png"
            alt=""
          />
          <h3>Auto & CNG</h3>
        </div>
        <div className="images-container">
          <img src="https://www.bharatpetroleum.in/images/car(2).jpg" alt="" />
          <h3>Cars & SUVs</h3>
        </div>{" "}
        <div className="images-container">
          <img
            src="https://www.bharatpetroleum.in/images/Trucks-&-Buses.jpg"
            alt=""
          />
          <h3>Trucks & Buses</h3>
        </div>{" "}
        <div className="images-container">
          <img
            src="https://www.bharatpetroleum.in/images/Agriculture.jpg"
            alt=""
          />
          <h3>Agriculture</h3>
        </div>{" "}
        <div className="images-container">
          <img src="https://www.bharatpetroleum.in/images/Greases.jpg" alt="" />
          <h3>Greases</h3>
        </div>{" "}
        <div className="images-container">
          <img
            src="https://www.bharatpetroleum.in/images/Specialties.jpg"
            alt=""
          />
          <h3>Specialities</h3>
        </div>{" "}
        <div className="images-container">
          <img
            src="https://www.bharatpetroleum.in/images/Marine-Boats.jpg"
            alt=""
          />
          <h3>Marine Boats</h3>
        </div>{" "}
        <div className="images-container">
          <img
            src="https://www.bharatpetroleum.in/images/Gears-&-Transmission.jpg"
            alt=""
          />
          <h3>Gears & Transportation</h3>
        </div>{" "}
      </div> */}
    </div>
  );
}

export default LubricantAutoMobile;
