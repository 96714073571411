import React, { useEffect } from "react";

function LubricantAutoMobile() {
  useEffect(() => {
    window.scroll(0, 0);
  });
  return (
    <div className="lubricant-container">
      <div className="lubricant">
        <h2 className="primary-subheading">R&D Center</h2>
        <p className="primary-information">
          Behind the class-leading performance of Kyros Lubricants is the
          frontline research and development that gives the product range its
          distinctive and decisive edge. Kyros R&D Centre, Mumbai, an epitome of
          cutting-edge innovation, is a state-of-the art facility with
          international and national accreditations to its credit.
        </p>
        <p className="primary-information">
          The amazing strides made by Kyros Lubricants are characterized by
          leaps in science and technology. Driving this onward quest is our
          Research & Development Centre in Mumbai, established in 1983. This
          cradle of innovation, always abuzz with challenging tasks, has to its
          credit the development of more than 400 lubricant formulations.
        </p>

        <h3 className="primary-subheading">Epitome of Innovation</h3>
        <img
          src="https://www.bharatpetroleum.in/images/900x420-lab-testing(1).png"
          alt=""
        ></img>

        <p className="primary-information">
          Recognized by the Department of Scientific & Industrial Research,
          under the Ministry of Science & Technology, Govt. of India, the
          ultra-modern R&D Centre equipped with latest instrumentation is an
          enchanting techno-world hosting state-of-the-art research facilities.
        </p>
      </div>
    </div>
  );
}

export default LubricantAutoMobile;
