import React, { useEffect } from "react";

function LubricantAutoMobile() {
  useEffect(() => {
    window.scroll(0, 0);
  });
  return (
    <div className="lubricant-container">
      <div className="lubricant">
        <h2 className="primary-subheading">Kryos Connect</h2>
        <p className="primary-information">
          Walk into even a small retail vender of automobile products in a city,
          town or a rural area, and you would easily find Kyros Lubricants
          displayed prominently on shelves. Accessibility of the product range
          across the nation is one more strength of Kyros Lubricants. Available
          across the 13,900-strong network of Kyros Immpex retail outlets,
        </p>
        <p className="primary-information">
          Kyros Lubricants are marketed by 700+ automotive, industrial and rural
          distributors, having presence at thousands of retail counters,
          exclusive Kyros Lube Shoppes and auto spare part shops, workshops and
          garages. Through a virtually omnipresent off-the-shelf availability,
          Kyros Lubricants have been easily accessible to its millions of
          customers across automobile and industrial segments.
        </p>

        <h3 className="primary-subheading">One day Wonder(ODW)</h3>
        <img
          src="https://www.bharatpetroleum.in/images/900x420-wadi.png"
          alt=""
        ></img>
        <p className="primary-information">
          This is one/two-day event is held at retail outlets or open markets
          where consumers are offered attractive propositions to choose from.
          The offers range from education kiosks on lubricants, free oil
          changes, attractive assured gifts and sweepstakes. This also forms a
          collaborative platform for Dealers and Distributors to create
          cross-selling opportunities.
          <br></br>
          While each sales officer conducts at least one ODW per month
          currently, many of our dealers repeat such events on their own as
          follow-up programmes.
          <br></br>
        </p>
        <h3 className="primary-subheading">Kyros Mechanic meets</h3>
        <img
          src="https://www.bharatpetroleum.in/images/MAK-connect-Manthan.png"
          alt=""
        />
        <p className="primary-information">
          Mechanic enrolment programmes are regularly conducted across domestic
          geographies to impart technical knowledge on lubricants. Select
          mechanics are the advanced to the next level where they are imparted
          further training and get to visit the Refinery, R&D and Blending
          plant. This unique Mechanic engagement programme called as ‘Kyros
          Manthan’ successfully creates multiple Brand Ambassadors in different
          parts of the country.
        </p>
        <h3 className="primary-subheading"> Industry Seminars</h3>
        <p className="primary-information">
          Lubrication practices vary from industry to industry. However, there
          is usually one common resources to manage maintenance and lubrication.
          In order to keep them updated and informed about latest developments
          and lubes-handling practices, the KIPL Kyros brand conducts technical
          seminars via in-house faculties at various potential industries.
        </p>
        <h3 className="primary-subheading">Kyros Quality Check</h3>
        <img
          src="https://www.bharatpetroleum.in/images/900x420-ABout-MAK-Lubricants-THUMBNAIL.png"
          alt=""
        />
        <p className="primary-information">
          Kyros Lubricants is confident of making a sustained impact with its
          substantial and sustained efforts and investments. Kyros aims to
          synergise the business between channels, improve quality and quantity
          standards, invest in R&D for new product developments and aggressively
          build brands that result in maximising value addition for both the
          business and its customers.
        </p>
      </div>
      {/* <div className="images-information">
        <div className="images-container">
          <img
            src="https://www.bharatpetroleum.in/images/2-wheelers(1).jpg"
            alt=""
          />
          <h3>Bikes and Scooters</h3>
        </div>
        <div className="images-container">
          <img
            src="https://www.bharatpetroleum.in/images/275x255-auto-2.png"
            alt=""
          />
          <h3>Auto & CNG</h3>
        </div>
        <div className="images-container">
          <img src="https://www.bharatpetroleum.in/images/car(2).jpg" alt="" />
          <h3>Cars & SUVs</h3>
        </div>{" "}
        <div className="images-container">
          <img
            src="https://www.bharatpetroleum.in/images/Trucks-&-Buses.jpg"
            alt=""
          />
          <h3>Trucks & Buses</h3>
        </div>{" "}
        <div className="images-container">
          <img
            src="https://www.bharatpetroleum.in/images/Agriculture.jpg"
            alt=""
          />
          <h3>Agriculture</h3>
        </div>{" "}
        <div className="images-container">
          <img src="https://www.bharatpetroleum.in/images/Greases.jpg" alt="" />
          <h3>Greases</h3>
        </div>{" "}
        <div className="images-container">
          <img
            src="https://www.bharatpetroleum.in/images/Specialties.jpg"
            alt=""
          />
          <h3>Specialities</h3>
        </div>{" "}
        <div className="images-container">
          <img
            src="https://www.bharatpetroleum.in/images/Marine-Boats.jpg"
            alt=""
          />
          <h3>Marine Boats</h3>
        </div>{" "}
        <div className="images-container">
          <img
            src="https://www.bharatpetroleum.in/images/Gears-&-Transmission.jpg"
            alt=""
          />
          <h3>Gears & Transportation</h3>
        </div>{" "}
      </div> */}
    </div>
  );
}

export default LubricantAutoMobile;
