import React, { useEffect } from "react";

function LubricantAutoMobile() {
  useEffect(() => {
    window.scroll(0, 0);
  });
  return (
    <div className="lubricant-container">
      <div className="lubricant">
        <h2 className="primary-subheading">Kryos Makes It Possible</h2>
        <p className="primary-information">
          The Kyros lubricant supply chain is a very wide network consisting of
          four manufacturing plants and 52 depots, supported by thousands of
          dealers and distributors spread across the length and breadth of the
          nation. The product range is supported by a highly efficient logistic
          team that enables easy availability of various grades to our customers
          anywhere in the country. Serving customers to their utmost
          satisfaction is our primary business goal, and the smile on their
          faces is our ultimate reward!
        </p>
        <p className="primary-information">
          Lube Oil Blending Plant Budge Budge with its rich heritage extending
          over a span of more than 90+ years. Plant is constructed in 1927,
          upgraded in 2002 with present capacity 30 TMT / YEAR. Budge Budge is
          located in the south western suburbs of Kolkata on the eastern bank of
          River Ganges. Plant is handling 350 + SKUs & catering needs of various
          Automotive Engine oils and Industrial oils of ER, Part of WR, NR and
          Bangladesh, Nepal export market. Plant is accredited with ISO
          9001/14001/50001 & OHSAS 18001 certifications.
        </p>

        <h3 className="primary-subheading">Wadilube Installation </h3>
        <img
          src="https://www.bharatpetroleum.in/images/900x420-wadi.png"
          alt=""
        ></img>
        <p className="primary-information">
          Wadilube Installation is the one of the largest and oldest blending
          plant of India. This plant caters to more than 75% requirement of
          KIPL. Aprrox 275 grades and 525 SKUs are being produced from this
          plant.
          <br></br>
          The Installation has been awarded with following certifications
          <br></br>
          <ul>
            <li>
              {" "}
              Green Channel Certification – First and only plant in India for
              defence supply.{" "}
            </li>
            <li>ERDMP Certified Plant – by DMI, Bhopal.</li>
            <li>Best Lube Plant of KIPL – 2017-18, 2018-19</li>
            <li>
              ISO 9001:2015, ISO 14001:2015, ISO 50001:2011 & OHSAS 18001:2007 –
              Integrated Management System (IMS)
            </li>
            <li>First lube plant in industry to undergo OISD 237 audit.</li>
            <li>The Best Place to Work 2018-19 by HRS West</li>
            <li> Plant is certified as Zero Waste to Landfill.</li>
          </ul>
          The plant is spread in an area of 15 acres, with a central warehouse
          facility housed in 3 Acres . The plant has a blending capacity of 203
          TMT/year and a filling capacity of 82 TMT/year. The same is supported
          with tankage capacities as follows Blending tanks – 6,000 KL, 37 Nos
          <br></br>
          Finished Product Storage tanks – 750 KL, 26 Nos<br></br>
          Base oil: 60,000 KL , 21 Nos<br></br>
          Additives: 4300 MT, 22 Nos<br></br>
          <ul>
            <li>Road transport: Bulk tank lorries and packed truck</li>
            <li>Coastal Movement: Mainly base oil from CPCL / import</li>
          </ul>
          <br></br>
          Product dispatch is done through
          <ul>
            <li>Road Transport: Bulk and packed trucks</li>
            <li> Coastal movement: Mainly base oil</li>
            <li>Pipe Line Transfer: Base oil to HPCL lube plant</li>
          </ul>
          Apart from regular supplies the plant also achieved:
          <ul>
            <li>
              Export to around 13 countries viz. Sri Lanka, Nepal, Bangladesh,
              Kuwait, Bhutan, Qatar, Oman, UAE, Africa etc.
            </li>
            <li>Manufacturing of transformer oil</li>
            <li> Manufacturing of LLPO (light liquid transformer oil</li>
          </ul>
        </p>
        <h3 className="primary-subheading">
          Budge Budge Lube oil Blending Plant
        </h3>
        <img
          src="https://www.bharatpetroleum.in/images/900x420-budge5.png"
          alt=""
        />
        <p className="primary-information">
          Lube Oil Blending Plant Budge Budge with its rich heritage extending
          over a span of more than 90+ years. Plant is constructed in 1927,
          upgraded in 2002 with present capacity 30 TMT / YEAR. Budge Budge is
          located in the south western suburbs of Kolkata on the eastern bank of
          River Ganges. Plant is handling 350 + SKUs & catering needs of various
          Automotive Engine oils and Industrial oils of ER, Part of WR, NR and
          Bangladesh, Nepal export market. Plant is accredited with ISO
          9001/14001/50001 & OHSAS 18001 certifications.
        </p>
      </div>
      {/* <div className="images-information">
        <div className="images-container">
          <img
            src="https://www.bharatpetroleum.in/images/2-wheelers(1).jpg"
            alt=""
          />
          <h3>Bikes and Scooters</h3>
        </div>
        <div className="images-container">
          <img
            src="https://www.bharatpetroleum.in/images/275x255-auto-2.png"
            alt=""
          />
          <h3>Auto & CNG</h3>
        </div>
        <div className="images-container">
          <img src="https://www.bharatpetroleum.in/images/car(2).jpg" alt="" />
          <h3>Cars & SUVs</h3>
        </div>{" "}
        <div className="images-container">
          <img
            src="https://www.bharatpetroleum.in/images/Trucks-&-Buses.jpg"
            alt=""
          />
          <h3>Trucks & Buses</h3>
        </div>{" "}
        <div className="images-container">
          <img
            src="https://www.bharatpetroleum.in/images/Agriculture.jpg"
            alt=""
          />
          <h3>Agriculture</h3>
        </div>{" "}
        <div className="images-container">
          <img src="https://www.bharatpetroleum.in/images/Greases.jpg" alt="" />
          <h3>Greases</h3>
        </div>{" "}
        <div className="images-container">
          <img
            src="https://www.bharatpetroleum.in/images/Specialties.jpg"
            alt=""
          />
          <h3>Specialities</h3>
        </div>{" "}
        <div className="images-container">
          <img
            src="https://www.bharatpetroleum.in/images/Marine-Boats.jpg"
            alt=""
          />
          <h3>Marine Boats</h3>
        </div>{" "}
        <div className="images-container">
          <img
            src="https://www.bharatpetroleum.in/images/Gears-&-Transmission.jpg"
            alt=""
          />
          <h3>Gears & Transportation</h3>
        </div>{" "}
      </div> */}
    </div>
  );
}

export default LubricantAutoMobile;
