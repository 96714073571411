import React, { useEffect } from "react";

function Castor() {
  useEffect(() => {
    window.scroll(0, 0);
  });
  return (
    <div>
      <div className="work-section-wrapper">
        <div className="work-section-top">
          <h1 className="primary-mainheading">Castor Oil</h1>
          <br></br>
          <h1 className="primary-heading" id="scrollmango">
            CASTOR OIL BSS GRADE
          </h1>
          <div className="alcohol-img">
            <img
              src="https://i.ibb.co/BrVcnNC/Whats-App-Image-2023-05-28-at-17-50-41.jpg"
              alt="Whats"
              border="0"
            />
          </div>
          <div className="alcohol-img">
            <img src="https://i.ibb.co/wyMF8GD/yy.png" alt="yy" border="0" />
          </div>
          <p>
            Packaging<br></br>
            200 Kg Nett MS Drums :16.0 MT Loose / Palletized/20’ ctr <br></br>
            225 Kg Nett MS Drums :18.0 MT Loose / Palletized/20’ ctr<br></br>20’
            ISO Ctr :21.5 MT (+/- 5%) <br></br> 20’ Flexi bag :21.5 MT (+/-5%)
          </p>
          <p>
            Application <br></br>Castor oil serves as an industrial raw material
            for the manufacture of number of Castor oil derivatives(HCO, 12 H S
            A, RIC ACID), Leather Industry, Textile Industry, Resin
            Manufacturing, Paints, Cosmetics, etc.
          </p>
        </div>
        <img src="https://i.ibb.co/qsprKjN/bb.png" alt="bb" border="0"></img>
      </div>
      <br></br>
      <br></br>
      <div className="line-dividing"></div>
      <div className="work-section-wrapper">
        <div className="work-section-top">
          <h1 className="primary-heading" id="scrollmango">
            CASTOR OIL 40
          </h1>
          <div className="alcohol-img">
            <img
              src="https://i.ibb.co/BrVcnNC/Whats-App-Image-2023-05-28-at-17-50-41.jpg"
              alt="Whats"
              border="0"
            />
          </div>
          <div className="alcohol-img">
            <img
              src="https://i.ibb.co/GPVYyS2/Screenshot-2023-05-29-165043.png"
              alt="Screenshot-2023-05-29-165043"
              border="0"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Castor;
