import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

function OtherApi() {
  const occref = useRef(null);
  const dsoccref = useRef(null);
  const onpref = useRef(null);
  const tissueref = useRef(null);
  const location = useLocation();
  useEffect(() => {
    window.scroll(0, 0);
    if (location.hash === "#scrollocc") {
      occref.current.scrollIntoView({ behaviour: "smooth" });
    }
    if (location.hash === "#scrolldsocc") {
      dsoccref.current.scrollIntoView({ behaviour: "smooth" });
    }
    if (location.hash === "#scrollonp") {
      onpref.current.scrollIntoView({ behaviour: "smooth" });
    }
    if (location.hash === "#scrolltissue") {
      tissueref.current.scrollIntoView({ behaviour: "smooth" });
    }
  }, [location]);
  return (
    <div>
      <div className="work-section-wrapper">
        <div className="work-section-top">
          <h1 className="primary-mainheading">Other APIs</h1>
        </div>
        <br></br>
        <br></br>
      </div>

      <div className="work-section-top" ref={occref} id="scrollocc">
        <h2 className="primary-heading">Oxyclozanide (VET)</h2>
        <div className="fruit-images">
          <div className="fruit1" style={{ display: "flex" }}>
            <div className="one">
              <img
                src="http://www.adanipharma.com/images/product/other%20apis/1.jpg"
                alt="is-corn-grain-1544222258"
                border="0"
              />
              <p>Molecular Structure</p>
            </div>
            <div className="one">
              <img
                src="http://www.adanipharma.com/images/product/other%20apis/01.jpg"
                alt="is-corn-grain-1544222258"
                border="0"
              />
              <p>Product Image</p>
            </div>
          </div>
        </div>
        <p>
          Uses of Product<br></br>
          It is used in the treatment and control of Fascioliasis in ruminants
          like Cattle, Sheep and Goats, used to treat cestode, trematode, or
          other flatworm infestations in man or animals, used to expel or
          destroy tapeworms in domestic animals. It is an anthelmintic used in
          grazing animals for fasciola and cestode infestations.
        </p>
      </div>
      <div className="line-dividing"></div>

      <div className="work-section-top" id="scrolldsocc" ref={dsoccref}>
        <h2 className="primary-heading">Diloxanide Furoate India</h2>
        <div className="fruit-images">
          <div className="fruit1" style={{ display: "flex" }}>
            <div className="one">
              <img
                src="http://www.adanipharma.com/images/product/other%20apis/3.jpg"
                alt="is-corn-grain-1544222258"
                border="0"
              />
              <p>Molecular Structure</p>
            </div>
            <div className="one">
              <img
                src="http://www.adanipharma.com/images/product/other%20apis/03.jpg"
                alt="is-corn-grain-1544222258"
                border="0"
              />
              <p>Product Image</p>
            </div>
          </div>
        </div>
        <p>
          Uses of Diloxanide furoate<br></br>
          Diloxanide Furoate India is effective treatment for intestinal
          Amebiasis in both conditions either chronic or acute amoebic
          dysentery. The drug is also utilized as antiprotozoal to eradicate any
          cysts inside tissues.
          <br></br>
          We are prominent Diloxanide Furoate exporter ad manufacturers in India
          selling premium quality range of products in overseas market at very
          affordable prices.Diloxanide Furoate is crystalline powder usually
          available in white color. It is odorless and suitable to treat various
          tissue diseases and other health problems.
          <br></br>
          It is also good for intestinal treatment, chronic and acute amoebic
          dysentery. Client can avail our wide range of Diloxanide Furoate
          products at very affordable prices.
        </p>
      </div>
      <div className="line-dividing"></div>

      <div className="work-section-top" id="scrollonp" ref={onpref}>
        <h2 className="primary-heading">Niclosamide</h2>
        <div className="fruit-images">
          <div className="fruit1" style={{ display: "flex" }}>
            <div className="one">
              <img
                src="http://www.adanipharma.com/images/product/other%20apis/4.jpg"
                alt="is-corn-grain-1544222258"
                border="0"
              />
              <p>Molecular Structure</p>
            </div>
            <div className="one">
              <img
                src="http://www.adanipharma.com/images/product/other%20apis/04.jpg"
                alt="is-corn-grain-1544222258"
                border="0"
              />
              <p>Product Image</p>
            </div>
          </div>
        </div>
        <p>
          Product Usage<br></br>
          It is used as an anthelmintic against Taenia saginata, Hymenolepis
          nana and other tapeworms (Hymenolepis diminuta, Dipylidium caninum),
          In Intestinal fluke infections (Fasciolopsis buski, Heterophyes
          heterophyes).
        </p>
      </div>
      <div className="line-dividing"></div>

      <div className="work-section-top">
        <h2 className="primary-heading">ANTIMALARIAL</h2>

        <ul>
          <li>Quinine Sulphate (CEP 2005-117)</li>
          <li>Quinine hydrochloride (CEP 2005-114)</li>
          <li>Artensunate with EDMF</li>
          <li>Amodiaquine Hydrochloride</li>
          <li>Amodiaquine Base</li>
          <li>Primaquinine phosphate</li>
          <li>Cinchona Alkaloids on Request</li>
          <li>Dihydro Artemisinin with EDMF</li>
        </ul>
      </div>
      <div className="line-dividing"></div>
      <div className="work-section-top">
        <h2 className="primary-heading">ANTITHEMALTICS</h2>

        <ul>
          <li>Piperazine Citrate</li>
          <li>Piperzine Phosphate</li>
        </ul>
      </div>
      <div className="line-dividing"></div>
      <div className="work-section-top">
        <h2 className="primary-heading">
          ERECTILE DYSFUNCTION & MYDRIATIC AGENT
        </h2>

        <ul>
          <li>Yohimbine Hydrochloride</li>
          <li>Yohimbine Extract 2%</li>
          <li>Yohimbine Extract 8%</li>
        </ul>
      </div>
      <div className="line-dividing"></div>
      <div className="work-section-top">
        <h2 className="primary-heading">ANTI-CANCER INTERMEDIATES</h2>

        <ul>
          <li>10-De Acetyl Baccatin II (10-DAB) 98%</li>
          <li>Camptothicine</li>
        </ul>
      </div>
      <div className="line-dividing"></div>
      <div className="work-section-top">
        <h2 className="primary-heading">ANTIFUNGAL</h2>

        <ul>
          <li>Bifonazole</li>
        </ul>
      </div>
      <div className="line-dividing"></div>
      <div className="work-section-top">
        <h2 className="primary-heading">CARDIAC DRUGS</h2>

        <ul>
          <li>Nimesulide</li>
        </ul>
      </div>
      <div className="line-dividing"></div>
      <div className="work-section-top">
        <h2 className="primary-heading">MUSCLE RELAXANT</h2>

        <ul>
          <li>Thio-Colchicoside</li>
        </ul>
      </div>
      <div className="line-dividing"></div>
      <div className="work-section-top">
        <h2 className="primary-heading">DECONGESTANTS</h2>

        <ul>
          <li>Phenylpropanolamine Hydrochloride</li>
          <li>Phenylephirine Hydrochloride</li>
        </ul>
      </div>
    </div>
  );
}

export default OtherApi;
