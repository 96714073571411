import React from "react";
function Youtube() {
  return (
    <div>
      <iframe
        className="youtube-video"
        src="https://www.youtube.com/embed/wCaMqVGeCOY"
        title="YouTube video player"
        border="0"
        allow="accelerometer;clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      ></iframe>
    </div>
  );
}

export default Youtube;
