/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

//import img1 from "../Assets/images-1.jpeg";
import alcohol96 from "../Assets/Alcohol.jpg";
import alcohol99 from "../Assets/Alcohol99.jpg";
import isopropyl from "../Assets/isopropyl.jpg";

function Alcohol() {
  const mangoref = useRef(null);
  const grapesref = useRef(null);
  const pomegranateref = useRef(null);
  const bananaref = useRef(null);
  const strawberryref = useRef(null);
  const blueberryref = useRef(null);
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    if (location.hash === "#scrollmango") {
      mangoref.current.scrollIntoView({ behaviour: "smooth" });
    }
    if (location.hash === "#scrollgrapes") {
      grapesref.current.scrollIntoView({ behaviour: "smooth" });
    }
    if (location.hash === "#scrollpomegranate") {
      pomegranateref.current.scrollIntoView({ behaviour: "smooth" });
    }
    if (location.hash === "#scrollbanana") {
      bananaref.current.scrollIntoView({ behaviour: "smooth" });
    }
    if (location.hash === "#scrollstrawberry") {
      strawberryref.current.scrollIntoView({ behaviour: "smooth" });
    }
    if (location.hash === "#scrollblueberry") {
      blueberryref.current.scrollIntoView({ behaviour: "smooth" });
    }
  }, [location]);

  return (
    <div>
      <div className="work-section-wrapper">
        <div className="work-section-top">
          <h1 className="primary-mainheading">Alcohol</h1>
          <br></br>
          <h1 className="primary-heading" ref={mangoref} id="scrollmango">
            Extra Neutral Alcohol 96%
          </h1>
          <div className="alcohol-img">
            <img src={alcohol96} alt="" />
          </div>
          <p>
            It is a pure ethanol solution that contains 96% alcohol by volume,
            produced by distilling fermented molasses or grains. It is used as a
            base ingredient in alcoholic beverages, hand sanitizers,
            disinfectants, and other industrial and pharmaceutical products.
          </p>
        </div>

        <div className="line-dividing"></div>

        <div className="work-section-top">
          <h1 className="primary-heading" ref={grapesref} id="scrollgrapes">
            Extra Neutral Alcohol 99%
          </h1>
          <div className="alcohol-img">
            <img src={alcohol99} alt="alcohol" />
          </div>
          <p>
            It is a pure and concentrated ethanol solution with 99% alcohol by
            volume. It is used in the pharmaceutical, cosmetic, and chemical
            industries as a solvent and raw material. ENA 99% is not suitable
            for consumption and is usually denatured to prevent its use for
            human consumption.
          </p>
        </div>

        <div className="line-dividing"></div>

        <div className="work-section-top">
          <h1
            className="primary-heading"
            ref={pomegranateref}
            id="scrollpomegranate"
          >
            ISO PROPYL ALCOHOL
          </h1>
          <div className="alcohol-img">
            <img src={isopropyl} alt="alcohol" />
          </div>
          <p>
            It is a colorless and flammable chemical compound widely used as a
            disinfectant, cleaning agent, and solvent. It has a strong odor,
            evaporates quickly, and is used in various applications where rapid
            evaporation is needed. IPA is also a key raw material in the
            production of resins, coatings, and pharmaceuticals.
          </p>
        </div>

        <div className="line-dividing"></div>
      </div>
    </div>
  );
}

export default Alcohol;
