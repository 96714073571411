import React, { useEffect, useRef } from "react";

import { useLocation } from "react-router-dom";
import ethyl from "../Assets/ethyl.jpg";

function Spices_Product() {
  const redref = useRef(false);
  const turmericref = useRef(false);
  const gingerref = useRef(false);
  const cuminref = useRef(false);
  const seasameref = useRef(false);
  const cardamomref = useRef(false);
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
    if (location.hash === "#scrollred") {
      redref.current.scrollIntoView({ behaviour: "smooth" });
    }
    if (location.hash === "#scrollturmeric") {
      turmericref.current.scrollIntoView({ behaviour: "smooth" });
    }
    if (location.hash === "#scrollginger") {
      gingerref.current.scrollIntoView({ behaviour: "smooth" });
    }
    if (location.hash === "#scrollcumin") {
      cuminref.current.scrollIntoView({ behaviour: "smooth" });
    }
    if (location.hash === "#scrollseasame") {
      seasameref.current.scrollIntoView({ behaviour: "smooth" });
    }
    if (location.hash === "#scrollcardamom") {
      cardamomref.current.scrollIntoView({ behaviour: "smooth" });
    }
  }, [location]);
  return (
    <div>
      <div className="work-section-wrapper">
        <div className="work-section-top" id="scrollred" ref={redref}>
          <h1 className="primary-mainheading">Solvent</h1>
          <br></br>
          <h1 className="primary-heading">Ethyl Acetate</h1>
          <div className="alcohol-img">
            <img src={ethyl} alt="" />
          </div>
          <p className="primary-text">
            It is a colorless liquid with a sweet fruity smell. It is commonly
            used as a solvent in various industrial processes, including the
            production of paints, coatings, and adhesives. It is also used in
            the manufacture of artificial flavors and fragrances, as well as in
            the food and pharmaceutical industries. Ethyl acetate is flammable
            and should be handled with care.
          </p>
        </div>
        <hr className="line-dividing" />
        <div className="work-section-top">
          <p className="primary-subheading"></p>
          <h1 className="primary-heading">Mix Xylene</h1>

          <p className="primary-text">
            It is flammable liquid that consists of a mixture of three isomers
            of xylene: meta-xylene, ortho-xylene, and para-xylene. It is
            commonly used as a solvent in various industrial applications,
            including in the production of paints, coatings, and adhesives. It
            is also used as a cleaning agent in the printing industry, and as a
            fuel additive in gasoline. Mixed xylene can be harmful if inhaled or
            ingested, and it should be handled with care in a well-ventilated
            area.
          </p>
        </div>

        <div
          className="scroll"
          ref={turmericref}
          id="scrollturmeric"
          style={{ position: "absolute" }}
        ></div>
        <hr
          style={{
            borderBottom: "1px solid #000",
            width: "100%",
            marginBottom: "5%",
          }}
        ></hr>

        <div className="work-section-top">
          <p className="primary-subheading"></p>
          <h1 className="primary-heading">Toluene</h1>
          <div className="alcohol-img">
            <img
              src="https://i.ibb.co/nCc29Z8/Screenshot-2023-04-23-162657.png"
              alt="Screenshot-2023-04-23-162657"
              border="0"
            />
          </div>
          <p className="primary-text">
            Toluene Chemical offered by Kyros Immpex is manufactured utilizing
            high grade material that is obtained from trusted suppliers. These
            chemicals are made under the guidance of skilled workers. The
            company has segregated its infrastructure into several units and one
            of them is packaging unit wherein we meticulously pack our products
            for ensuring safe and secure deliveries.
            <br></br>
            Toluene is widely used across different industries like pharma,
            paint etc. We provide Toluene in liquid form with standard packaging
            of 180 Kgs drums. Molecular weight of Toluene is 92.14 Grams.
          </p>
          <b>Packaging</b>
          <p className="primary-text">
            It is Non-Hazardous Cargo. We provide 180 kgs packing in Drums with
            Shrink wrapped, Palatalized.
          </p>
        </div>

        <hr className="line-dividing"></hr>
        <div
          className="scroll"
          ref={gingerref}
          id="scrollginger"
          style={{ position: "absolute" }}
        ></div>
        <div className="work-section-top">
          <p className="primary-subheading"></p>
          <h1 className="primary-heading">Industrial Phenol</h1>
          <div className="alcohol-img">
            <img
              src="https://i.ibb.co/Xx1Wx4T/Screenshot-2023-04-24-003858.png"
              alt="Screenshot-2023-04-24-003858"
              border="0"
            />
            <p className="primary-text">
              The major uses of phenol, consuming two thirds of its production,
              involve its conversion to plastics or related materials.
              Condensation with acetone gives bisphenol-A, a key precursor to
              polycarbonates and epoxide resins. Condensation of phenol,
              alkylphenols, or diphenols with formaldehyde gives phenolic
              resins, a famous example of which is Bakelite.
            </p>
            <p className="primary-text">
              Phenol is also a versatile precursor to a large collection of
              drugs, most notably aspirin but also many herbicides and
              pharmaceuticals. Phenol is also used as an oral
              anaesthetic/analgesic in products such as Chloraseptic or other
              brand name and generic equivalents, commonly used to temporarily
              treat pharyngitis
            </p>
          </div>
        </div>
        <hr className="line-dividing"></hr>
        <div
          className="scroll"
          ref={seasameref}
          id="scrollseasame"
          style={{ position: "absolute" }}
        ></div>
        <div className="work-section-top">
          <p className="primary-subheading"></p>
          <h1 className="primary-heading">Methanol</h1>
          <div className="alcohol-img">
            <img
              src="https://i.ibb.co/ggpnDF9/Screenshot-2023-04-24-004343.png"
              alt="Screenshot-2023-04-24-004343"
              border="0"
            />
          </div>
          <p className="primary-text">
            <b>Features of Methanol:</b>
          </p>
          <div className="lists-primary-text">
            <ul>
              <li>
                Methanol is highly toxic liquid chemical widely used as an
                antifreeze, solvent, fuel and as a denaturant for ethanol.
              </li>
              <li>
                Methanol is also named as 2- Butoxyethanol, Butyl Glycol Ether,
                Ethylene Glycol Butyl Ether
              </li>
              <li>
                A low flash point solvent with a high evaporation rate, low
                viscosity.
              </li>
              <li>
                Absorbs moisture readily which assists in natural gas drying.
              </li>
              <li>
                Versatility – Methanol can be included in any formulation where
                speed of evaporation is required and it combines well with other
                organic compounds.
              </li>
              <li>
                Solvency – Methanol has high solvency of resins used in surface
                coatings or as a specialised fuel.
              </li>
            </ul>
          </div>
        </div>
        <div className="work-section-top">
          <p className="primary-text">
            <b>Uses of Methanol:</b>
          </p>
          <div className="lists-primary-text">
            <ul>
              <li>
                Methanol fuel is an alternative biofuel for internal combustion
                and other engines, either in combination with gasoline or
                independently. Methanol (CH3OH) is less expensive to produce
                sustainably than ethanol fuel, although it is generally more
                toxic and has lower energy density.
              </li>
              <li>
                For optimizing engine performance and fuel availability,
                however, a blend of ethanol, methanol and petroleum is likely to
                be preferable to using any of these alone.
              </li>
              <li>
                Methanol is a wood alcohol (CH3OH) that can be used as a
                renewable energy, marine and auto fuel, and as fuel cells.
              </li>
              <li>
                Methanol is a nondrinking type of alcohol (also known as wood
                alcohol and methyl alcohol) which is mostly used to create fuel,
                solvents and antifreeze.
              </li>
              <li>
                It is also used as a solvent in the manufacture of important
                pharmaceutical ingredients and products such as cholesterol,
                streptomycin, vitamins and hormones.
              </li>
            </ul>
          </div>
          <p className="primary-text">
            <b>Methanol Hazards:</b>
          </p>
          <div className="lists-primary-text">
            When handling methanol, including biofuels, it is best to avoid
            direct exposure as much as possible. As such, it is imperative that
            safety gear be worn, especially those that cover the face, eyes and
            skin. If working where methanol vapors are present, proper
            ventilation is imperative for safety.
          </div>
          <p className="primary-text">
            <b>Packaging</b>
          </p>
          <div className="lists-primary-text">
            In 200l HDPE Drums, IBC Tanks or as per client requirements.
          </div>
        </div>
      </div>
    </div>
  );
}

export default Spices_Product;
