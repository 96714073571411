import React, { useEffect, useRef, useState } from "react";
import "./Navbar.css";
import { Link } from "react-router-dom";
import Logo from "../Assets/Final-Logo.png";
import { HiOutlineBars3 } from "react-icons/hi2";
import { Icon } from "@iconify/react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import $ from "jquery";
//#8406D1
function Navbar() {
  const [maintoggle, setmainToggle] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [toggle2, setToggle2] = useState(false);
  const [toggle3, setToggle3] = useState(false);
  const [toggle4, setToggle4] = useState(false);
  const [toggle5, setToggle5] = useState(false);
  const [toggle6, setToggle6] = useState(false);
  const [toggle7, setToggle7] = useState(false);
  const [toggle8, setToggle8] = useState(false);
  const calltoggle = () => {
    setToggle(!toggle);
  };
  const calltoggle2 = () => {
    setToggle2(!toggle2);
  };
  const calltoggle3 = () => {
    setToggle3(!toggle3);
  };

  function animation() {
    var tabsNewAnim = $("#navbarSupportedContent");
    var activeItemNewAnim = tabsNewAnim.find(".active");
    var activeWidthNewAnimHeight = activeItemNewAnim.innerHeight();
    var activeWidthNewAnimWidth = activeItemNewAnim.innerWidth();
    var itemPosNewAnimTop = activeItemNewAnim.position();
    var itemPosNewAnimLeft = activeItemNewAnim.position();
    $(".hori-selector").css({
      top: itemPosNewAnimTop.top + "px",
      left: itemPosNewAnimLeft.left + "px",
      height: activeWidthNewAnimHeight + "px",
      width: activeWidthNewAnimWidth + "px",
    });
    $("#navbarSupportedContent").on("click", "li", function (e) {
      $("#navbarSupportedContent ul li").removeClass("active");
      $(this).addClass("active");
      var activeWidthNewAnimHeight = $(this).innerHeight();
      var activeWidthNewAnimWidth = $(this).innerWidth();
      var itemPosNewAnimTop = $(this).position();
      var itemPosNewAnimLeft = $(this).position();
      $(".hori-selector").css({
        top: itemPosNewAnimTop.top + "px",
        left: itemPosNewAnimLeft.left + "px",
        height: activeWidthNewAnimHeight + "px",
        width: activeWidthNewAnimWidth + "px",
      });
    });
  }

  const [isSticky, setIsSticky] = useState(false);
  const navbarRef = useRef(null);

  const handleScroll = () => {
    const navbar = navbarRef.current;
    const top = navbar.offsetTop;
    setIsSticky(window.scrollY >= top);
  };

  useEffect(() => {
    animation();
    $(window).on("resize", function () {
      setTimeout(function () {
        animation();
      }, 500);
    });
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    let handler = (e) => {
      if (!navbarRef.current.contains(e.target)) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", handler);
  }, []);
  const [hover, setHover] = useState(false);
  const toggleHover = () => {
    setHover(!hover);
  };
  const linkStyle = {
    color: hover ? "#ab3af2" : "#ab3af2",
    fontWeight: "600",
  };
  const [open, setOpen] = useState(false);
  return (
    <nav
      className={
        isSticky
          ? "sticky navbar navbar-expand-lg navbar-mainbg"
          : "sticky navbar navbar-expand-lg navbar-mainbg"
      }
      ref={navbarRef}
      style={{
        paddingRight: "5%",
      }}
    >
      <Link className="navbar-brand navbar-logo" to="/">
        <img src={Logo} alt="" />
      </Link>

      <div
        className="collapse navbar-collapse"
        id="navbarSupportedContent"
        style={{
          justifyContent: "end",
          width: "100%",
        }}
      >
        <ul className="navbar-nav ml-auto">
          <div className="hori-selector">
            <div className="left"></div>
            <div className="right"></div>
          </div>

          <li className="nav-item active">
            <Link className="nav-link" to="/">
              <i className="fas fa-tachometer-alt"></i>Home
            </Link>
          </li>

          <li className="nav-item">
            <Link className="nav-link" to="/about">
              <i className="far fa-address-book"></i>Who we are
            </Link>
          </li>

          <li className="nav-item">
            <div className="services">
              <Link className="nav-link" to="/products">
                <i className="far fa-clone"></i>
                <span
                  style={linkStyle}
                  onMouseEnter={toggleHover}
                  onMouseLeave={toggleHover}
                >
                  Products
                </span>
              </Link>
              <ul className="dropdown">
                <li>
                  <div className="fruits-hover">
                    <Link to="/productsList" className="remove_decoration">
                      Chemicals
                      <Icon
                        icon="material-symbols:keyboard-double-arrow-right-rounded"
                        className="right-arrow"
                        color="#ab3af2"
                      />
                    </Link>

                    <ul className="fruits-dropdown">
                      <li>
                        <Link to="/lubricants">Lubricants</Link>
                      </li>
                      <li>
                        <div className="alcohol-hover">
                          <Link to="/alcohol">
                            Alcohol
                            <Icon
                              icon="material-symbols:keyboard-double-arrow-right-rounded"
                              className="right-arrow-sub"
                              color="#ab3af2"
                            />
                          </Link>

                          <ul className="alcohol-dropdown">
                            <li>
                              <Link to="/alcohol#scrollmango">
                                Extra Neutral Alcohol 96%
                              </Link>
                            </li>
                            <li>
                              <Link to="/alcohol#scrollgrapes">
                                Extra Neutral Alcohol 99%
                              </Link>
                            </li>
                            <li>
                              <Link to="/alcohol#scrollpomegranate">
                                ISO PROPYL ALCOHOL (IPA)
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </li>
                      <li>
                        <div className="solvent-hover">
                          <Link to="/solvent">
                            Solvent
                            <Icon
                              icon="material-symbols:keyboard-double-arrow-right-rounded"
                              className="right-arrow-sub"
                              color="#ab3af2"
                            />
                          </Link>
                          <ul className="solvent-dropdown">
                            <li>
                              <Link to="/solvent#scrollred">ETHYL ACETATE</Link>
                            </li>
                            <li>
                              <Link to="/solvent#scrollturmeric">
                                MIX XYLENE
                              </Link>
                            </li>
                            <li>
                              <Link to="/solvent#scrollginger">TOLUENE</Link>
                            </li>
                            <li>
                              <Link to="/solvent#scrollseasame">
                                INDUSTRIAL PHENOL
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </li>
                      <li>
                        <div className="guar-hover">
                          <Link to="/guar-gum-powder">
                            Guar Gum Powder
                            <Icon
                              icon="material-symbols:keyboard-double-arrow-right-rounded"
                              className="right-arrow-sub"
                              color="#ab3af2"
                            />
                          </Link>
                          <ul className="guar-dropdown">
                            <li>
                              <Link to="/guar-gum-powder#scrollonion">
                                Guar gum powder
                              </Link>
                            </li>
                            <li>
                              <Link to="/guar-gum-powder#scrollgarlic">
                                Fast hydrating guar gum powder
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </li>
                      <li>
                        <Link to="/castor">Castor Oil</Link>
                      </li>
                    </ul>
                  </div>
                </li>
                <li>
                  <div className="spices-hover">
                    <Link to="/recycle" className="remove_decoration">
                      Recycling
                      <Icon
                        icon="material-symbols:keyboard-double-arrow-right-rounded"
                        className="right-arrow"
                        color="#ab3af2"
                      />
                    </Link>

                    <ul className="spices-dropdown">
                      <li>
                        <div className="metal-hover">
                          <Link to="/scrap#scrollmetal">
                            Metal Scrap
                            <Icon
                              icon="material-symbols:keyboard-double-arrow-right-rounded"
                              className="right-arrow-sub"
                              color="#ab3af2"
                            />
                          </Link>

                          <ul className="metal-dropdown">
                            <li>
                              <Link to="/scrap#scrollferrous">Ferrous</Link>
                            </li>
                            <li>
                              <Link to="/scrap#scrollnonferrous">
                                Non-ferrous
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </li>
                      <li>
                        <div className="paper-hover">
                          <Link to="/paper">
                            Paper Scrap
                            <Icon
                              icon="material-symbols:keyboard-double-arrow-right-rounded"
                              className="right-arrow-sub"
                              color="#ab3af2"
                            />
                          </Link>
                          <ul className="paper-dropdown">
                            <li>
                              <Link to="/paper#scrollocc">OCC</Link>
                            </li>
                            <li>
                              <Link to="/paper#scrolldsocc">DSOCC</Link>
                            </li>
                            <li>
                              <Link to="/paper#scrollonp">ONP/OINP</Link>
                            </li>
                            <li>
                              <Link to="/paper#scrolltissue">Tissue</Link>
                            </li>
                          </ul>
                        </div>
                      </li>
                      <li>
                        <Link to="/ewaste">E-waste</Link>
                      </li>
                    </ul>
                  </div>
                </li>

                <li>
                  <div className="dehydrated-hover">
                    <Link to="/api" className="remove_decoration">
                      APIs
                      <Icon
                        icon="material-symbols:keyboard-double-arrow-right-rounded"
                        className="right-arrow"
                        color="#ab3af2"
                      />
                    </Link>
                    <ul className="dehydrated-dropdown">
                      <li>
                        <Link to="/iodine">Iodine</Link>
                      </li>
                      <li>
                        <Link to="/piperazine">Piperazine</Link>
                      </li>
                      <li>
                        <Link to="/citrate">Citrate</Link>
                      </li>
                      <li>
                        <Link to="/other-api">Other APIs</Link>
                      </li>
                      <li>
                        <Link to="/spiceole">Spice Oleoresin</Link>
                      </li>
                    </ul>
                  </div>
                </li>
                {/* <li>
                  <div className="others-hover">
                    <Link to="/" className="remove_decoration">
                      Others
                      <Icon
                        icon="material-symbols:keyboard-double-arrow-right-rounded"
                        className="right-arrow"
                        color="#ab3af2"
                      />
                    </Link>
                    <ul className="others-dropdown">
                      <li>
                        <Link to="/">API</Link>
                      </li>
                      <li>
                        <Link to="/">Anti Cancer Drug</Link>
                      </li>
                      <li>
                        <Link to="/">Smart Earphones</Link>
                      </li>
                      <li>
                        <Link to="/">Smart Hearing Aid</Link>
                      </li>
                    </ul>
                  </div>
                </li> */}
              </ul>
            </div>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/partner">
              <i className="far fa-copy"></i>Partner Us
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/contact">
              <i className="far fa-copy"></i>Contact
            </Link>
          </li>
        </ul>
      </div>

      <div className="navbar-menu-container">
        <HiOutlineBars3
          onClick={() => {
            setOpen(!open);
          }}
          className="hamburger"
        />
      </div>
      {open && (
        <div className="main-drawer">
          <div
            className={`navbar-drawer ${open ? "active" : "inactive"}`}
            style={{ overflowY: "auto" }}
          >
            <ul>
              <div className="item">
                <li
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  <Link to="/">
                    <Icon
                      icon="material-symbols:home"
                      className="navbar-icon"
                    />
                    Home
                  </Link>
                </li>
              </div>
              <li
                onClick={() => {
                  setOpen(false);
                }}
              >
                <Link to="/about">
                  <Icon
                    icon="material-symbols:person"
                    className="navbar-icon"
                  />
                  Who we are
                </Link>
              </li>
              <li>
                <Link
                  to="/products"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  <Icon
                    icon="mdi:bag-personal-outline"
                    className="navbar-icon"
                  />
                  Products
                </Link>
                <div
                  onClick={() => {
                    setmainToggle(!maintoggle);
                  }}
                >
                  {maintoggle ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </div>
              </li>
              {maintoggle && (
                <ul>
                  <li>
                    <Link
                      to="/productsList"
                      onClick={() => {
                        setOpen(false);
                      }}
                    >
                      <Icon
                        icon="fluent-emoji-high-contrast:test-tube"
                        className="navbar-icon"
                      />
                      Chemicals
                    </Link>
                    <div onClick={calltoggle}>
                      {toggle ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </div>
                  </li>
                  {toggle && (
                    <ul>
                      <li>
                        <Link to="/lubricants">
                          <Icon
                            icon="material-symbols:water-bottle-rounded"
                            className="navbar-icon"
                          />
                          Lubricants
                        </Link>
                      </li>
                      <li>
                        <Link to="/castor">
                          <Icon icon="mdi:oil" className="navbar-icon" />
                          Castor Oil
                        </Link>
                      </li>
                      <li>
                        <Link to="/alcohol">
                          <Icon icon="ooui:lab-flask" className="navbar-icon" />
                          Alcohol
                        </Link>
                        <div onClick={calltoggle2}>
                          {toggle2 ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </div>
                      </li>
                      {toggle2 && (
                        <ul>
                          <li>
                            <Link to="/alcohol#scrollmango">
                              <Icon icon="mdi:flask" className="navbar-icon" />
                              Extra Neutral Alcohol 96%
                            </Link>
                          </li>
                          <li>
                            <Link to="/alcohol#scrollgrapes">
                              <Icon
                                icon="mdi:flask-empty"
                                className="navbar-icon"
                              />
                              Extra Neutral Alcohol 99%
                            </Link>
                          </li>
                          <li>
                            <Link to="/alcohol#scrollpomegranate">
                              <Icon
                                icon="material-symbols:lab-research-sharp"
                                className="navbar-icon"
                              />
                              Iso Propyl Alcohol
                            </Link>
                          </li>
                        </ul>
                      )}
                      <li>
                        <Link to="/solvent">
                          <Icon
                            icon="material-symbols:water"
                            className="navbar-icon"
                          />
                          Solvent
                        </Link>
                        <div onClick={calltoggle3}>
                          {toggle3 ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </div>
                      </li>
                      {toggle3 && (
                        <ul>
                          <li>
                            <Link to="/solvent#scrollred">
                              <Icon
                                icon="clarity:internet-of-things-line"
                                className="navbar-icon"
                              />
                              Ethyl Acetate
                            </Link>
                          </li>
                          <li>
                            <Link to="/solvent#scrollturmeric">
                              <Icon
                                icon="ic:baseline-propane-tank"
                                className="navbar-icon"
                              />
                              Mix Xyelene
                            </Link>
                          </li>
                          <li>
                            <Link to="/solvent#scrollginger">
                              <Icon
                                icon="simple-icons:smartthings"
                                className="navbar-icon"
                              />
                              Toluene
                            </Link>
                          </li>
                          <li>
                            <Link to="/solvent#scrollseasame">
                              <Icon
                                icon="carbon:carbon-for-ibm-product"
                                className="navbar-icon"
                              />
                              Industrial Phenol
                            </Link>
                          </li>
                        </ul>
                      )}

                      <li>
                        <Link to="/guar-gum-powder">
                          <Icon
                            icon="game-icons:powder"
                            className="navbar-icon"
                          />
                          Guar Gum Powder
                        </Link>
                        <div
                          onClick={() => {
                            setToggle5(!toggle5);
                          }}
                        >
                          {toggle5 ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </div>
                      </li>
                      {toggle5 && (
                        <ul>
                          <li>
                            <Link to="/guar-gum-powder#scrollonion">
                              <Icon
                                icon="arcticons:powdertoy"
                                className="navbar-icon"
                              />
                              Guargum Powder
                            </Link>
                          </li>
                          <li>
                            <Link to="/guar-gum-powder#scrollgarlic">
                              <Icon
                                icon="game-icons:powder-bag"
                                className="navbar-icon"
                              />
                              Fast Hydrating Guargum Powder
                            </Link>
                          </li>
                        </ul>
                      )}
                    </ul>
                  )}
                  <li>
                    <Link
                      to="/recycle"
                      onClick={() => {
                        setOpen(false);
                      }}
                    >
                      <Icon
                        icon="fluent-mdl2:empty-recycle-bin"
                        className="navbar-icon"
                      />
                      Recycling
                    </Link>
                    <div
                      onClick={() => {
                        setToggle4(!toggle4);
                      }}
                    >
                      {toggle4 ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </div>
                  </li>
                  {toggle4 && (
                    <ul>
                      <li>
                        <Link to="/scrap">
                          <Icon
                            icon="game-icons:metal-bar"
                            className="navbar-icon"
                          />
                          Metal Scrap
                        </Link>
                        <div
                          onClick={() => {
                            setToggle6(!toggle6);
                          }}
                        >
                          {toggle6 ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </div>
                      </li>
                      {toggle6 && (
                        <ul>
                          <li>
                            <Link to="/scrap#scrollferrous">
                              <Icon
                                icon="icon-park-outline:heavy-metal"
                                className="navbar-icon"
                              />
                              Ferrous
                            </Link>
                          </li>
                          <li>
                            <Link to="/scrap#scrollnonferrous">
                              <Icon
                                icon="simple-icons:equinixmetal"
                                className="navbar-icon"
                              />
                              Non-ferrous
                            </Link>
                          </li>
                        </ul>
                      )}

                      <li>
                        <Link to="/paper">
                          <Icon icon="mdi:paper" className="navbar-icon" />
                          Paper Scrap
                        </Link>
                        <div
                          onClick={() => {
                            setToggle7(!toggle7);
                          }}
                        >
                          {toggle7 ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </div>
                      </li>
                      {toggle7 && (
                        <ul>
                          <li>
                            <Link to="/paper#scrollocc">
                              <Icon
                                icon="mdi:file-document-outline"
                                className="navbar-icon"
                              />
                              OCC
                            </Link>
                          </li>
                          <li>
                            <Link to="/paper#scrolldsocc">
                              <Icon
                                icon="mdi:paper-text"
                                className="navbar-icon"
                              />
                              DSOCC
                            </Link>
                          </li>
                          <li>
                            <Link to="/paper#scrollonp">
                              <Icon
                                icon="ic:outline-menu-book"
                                className="navbar-icon"
                              />
                              ONP/OINP
                            </Link>
                          </li>
                          <li>
                            <Link to="/paper#scrolltissue">
                              <Icon
                                icon="mdi:paper-roll-outline"
                                className="navbar-icon"
                              />
                              Tissue
                            </Link>
                          </li>
                        </ul>
                      )}
                      <li>
                        <Link to="/ewaste">
                          <Icon icon="mdi:recycle" className="navbar-icon" />
                          E-waste
                        </Link>
                      </li>
                    </ul>
                  )}
                  <li>
                    <Link
                      to="/api"
                      onClick={() => {
                        setOpen(false);
                      }}
                    >
                      <Icon
                        icon="game-icons:medicine-pills"
                        className="navbar-icon"
                      />
                      API
                    </Link>
                    <div
                      onClick={() => {
                        setToggle8(!toggle8);
                      }}
                    >
                      {toggle8 ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </div>
                  </li>
                  {toggle8 && (
                    <ul>
                      <li>
                        <Link to="/iodine">
                          <Icon
                            icon="solar:document-medicine-bold"
                            className="navbar-icon"
                          />
                          Iodine
                        </Link>
                      </li>
                      <li>
                        <Link to="/piperazine">
                          {" "}
                          <Icon
                            icon="icon-park-solid:medicine-bottle-one"
                            className="navbar-icon"
                          />
                          Piperazine
                        </Link>
                      </li>
                      <li>
                        <Link to="/citrate">
                          <Icon
                            icon="ri:medicine-bottle-fill"
                            className="navbar-icon"
                          />
                          Citrate
                        </Link>
                      </li>
                      <li>
                        <Link to="/other-api">
                          <Icon
                            icon="icon-park-outline:other"
                            className="navbar-icon"
                          />
                          Other APIs
                        </Link>
                      </li>
                      <li>
                        <Link to="/spiceole">
                          {" "}
                          <Icon
                            icon="icon-park-outline:medicine-bottle"
                            className="navbar-icon"
                          />
                          Spice Oleoresin
                        </Link>
                      </li>
                    </ul>
                  )}
                </ul>
              )}

              <li
                onClick={() => {
                  setOpen(false);
                }}
              >
                <Link to="/partner">
                  <Icon
                    icon="pepicons-print:handshake"
                    className="navbar-icon"
                  />
                  Partner Us
                </Link>
              </li>
              <li
                onClick={() => {
                  setOpen(false);
                }}
              >
                <Link to="/contact">
                  <Icon
                    icon="material-symbols:phone-enabled-sharp"
                    hFlip={true}
                    className="navbar-icon"
                  />
                  Contact
                </Link>
              </li>
            </ul>
          </div>
        </div>
      )}
    </nav>
  );
}
export default Navbar;
