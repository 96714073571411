import React from "react";
import teamPic from "../Assets/about.svg";
import Fade from "react-reveal/Fade";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from "react-router-dom";
const About = () => {
  return (
    <section className="about overflow-hidden py-5">
      <div className="w-100">
        <div className="row col-md-11 mx-auto ">
          <div className="col-md-6 img">
            <Fade duration={2000} left>
              <img src={`${teamPic}`} alt="" className="img-fluid" />
            </Fade>
          </div>
          <div className="col-md-6 ps-2">
            <Fade duration={2000} right>
              <h1 className="miniTitle">About Us</h1>
              <h2 className="headerTitle">
                Integrity Excellence Success,<br></br>
                <span className="headerHighlight">
                  We are your trusted
                </span>{" "}
                International Trade Partner
              </h2>
              <p className="headerContent">
                Kyros Immpex Private Limited is a global supplier of high-end
                and diverse range of products specialising in organic &
                inorganic chemicals, specialty chemicals and imports of ferrous
                and non ferrous metal scrap and paper scrap for recycling
                industries.
              </p>
              <Link
                to="/about"
                className="secondary-button"
                style={{ width: "12rem", display: "flex", margin: "auto" }}
              >
                Learn More
              </Link>
            </Fade>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
