import React, { useEffect, useState } from "react";

function LubCement() {
  const [activeIndex, setActiveIndex] = useState(null);
  const [subActiveIndex, setSubActiveIndex] = useState(null);

  const handleAccordionClick = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
    setSubActiveIndex(null);
  };

  const handleSubAccordionClick = (index) => {
    setSubActiveIndex(subActiveIndex === index ? null : index);
  };
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div>
      <div className="lubricant">
        <h2 className="primary-subheading">Cement</h2>
        <p className="primary-information">
          Lubrication in the cement industry offers some unique challenges.
          Cement plants and their equipments operate in severe conditions. Heavy
          loads, shock loads, extreme temperature, exposure to the elements and
          the constant presence of contaminants such as cement dust are some of
          the major challenges a machine faces in a cement plant.
        </p>
        <p className="primary-information">
          A wide range of equipment, including ball mills, kilns, crushers,
          vertical mills, bucket elevators, conveyor systems are used in a
          typical cement plant. Kyros Lubricants understands the importance of
          lubricants in the cement industry. We have a wide range of lubricating
          oil and high-performance greases that will protect your equipment and
          help improving your plant equipment efficiency.
        </p>
      </div>
      <div className="auto-lubricant">
        <div className="auto-lubricant-text">
          <h2>Compressor</h2>
          <img
            src="https://www.bharatpetroleum.in/images/COMPRESSOR%20OILS.jpg"
            alt=""
          />
        </div>
        <div className="accordion">
          <div className="accordion-item">
            <div
              className="accordion-header"
              onClick={() => handleAccordionClick(0)}
            >
              <h3>Compressor Oil</h3>
              <span>{activeIndex === 0 ? "-" : "+"}</span>
            </div>
            {activeIndex === 0 && (
              <div className="accordion-body">
                Superior quality reciprocating air compressor oil
                <br></br>
                Superior quality reciprocating air compressor oil Kyros Compressor
                Oils are high quality, high performance mineral oils developed
                for reciprocating air compressors. They are formulated from
                highly refined, high viscosity index base stocks and high
                performance additives with ashless chemistry. Reciprocating
                compressors operate at high temperatures and pressures. The
                lubricant creates a strong film in order to avoid surface wear.
                These oils have exceptional resistance to oxidation and thermal
                degradation. The thermal stability and oxidation resistance of
                these fluids can help maintain cleaner compressors, thereby
                enabling longer running periods between scheduled maintenance
                and oil changes.
                <div className="sub-accordion">
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(0)}
                    >
                      <h4>Specifications</h4>
                      <span>{subActiveIndex === 0 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 0 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>DIN 51 506 - VDL</li>
                          <li>ISO / DP 6521</li>
                          <li>
                            IS 13256:1992 DAA & DAB type [Reaffirmed 1998]
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(1)}
                    >
                      <h4>Applications</h4>
                      <span>{subActiveIndex === 1 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 1 && (
                      <div className="sub-accordion-body">
                        For high performance reciprocating & rotary air
                        compressors where air discharge temperatures may go up
                        to 220°C.
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(2)}
                    >
                      <h4>Benefits</h4>
                      <span>{subActiveIndex === 2 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 2 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>
                            Longer Oil Life- This oil has strong oxidation
                            resistance and hence is less prone to degradation
                          </li>
                          <li>
                            Lower Maintenance costs: oil has lesser tendency to
                            form sludge & varnish and hence it provides trouble
                            free operation and lower maintenance.
                          </li>
                          <li>
                            Extended maintenance intervals: due to reduced
                            deposit formation on valves, the maintenance
                            intervals for valves and other components increase.
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="accordion-item">
            <div
              className="accordion-header"
              onClick={() => handleAccordionClick(2)}
            >
              <h3>Kyros Compressor Oil C</h3>
              <span>{activeIndex === 2 ? "-" : "+"}</span>
            </div>
            {activeIndex === 2 && (
              <div className="accordion-body">
                Superior quality reciprocating air compressor oil for moist air
                <br></br>
                Kyros Compressor Oil C is a group of high quality, high
                performance oils developed for reciprocating air compressors
                handling moist working media. They are formulated from highly
                refined, high viscosity index base stocks and high performance
                additives. Reciprocating compressors sometimes operate with
                highly moist working medium like moist air or moist natural gas.
                The moisture is trapped in order to reduce the risk of corrosion
                of the compressor components. These oils are compounded to form
                stable emulsion with the moisture. The moisture is trapped and
                remains isolated from the metal surfaces. Reciprocating
                compressors operate at high temperatures and pressures.
                <div className="sub-accordion">
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(0)}
                    >
                      <h4>Specifications</h4>
                      <span>{subActiveIndex === 0 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 0 && (
                      <div className="sub-accordion-body">
                        Proprietary grades
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(1)}
                    >
                      <h4>Applications</h4>
                      <span>{subActiveIndex === 1 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 1 && (
                      <div className="sub-accordion-body">
                        These oils are recommended for cylinder lubrication in
                        reciprocating single and multi-stage air compressor
                        handling moist air and moist natural gas
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(2)}
                    >
                      <h4>Benefits</h4>
                      <span>{subActiveIndex === 2 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 2 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>
                            Excellent anti-wear & higher load carrying capacity.
                          </li>
                          <li>Outstanding oxidation and thermal stability</li>
                          <li>Excellent water separation characteristics</li>
                          <li>
                            Prevents rust and corrosion of internal compressor
                            components
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="accordion-item">
            <div
              className="accordion-header"
              onClick={() => handleAccordionClick(1)}
            >
              <h3>Screwcom</h3>
              <span>{activeIndex === 1 ? "-" : "+"}</span>
            </div>
            {activeIndex === 1 && (
              <div className="accordion-body">
                Premium quality oils for rotary screw air compressors
                <br></br>
                Kyros Screwcom Oils are high quality, high performance mineral
                oils developed for rotary vane and screw air compressors. They
                are formulated from highly refined, high viscosity index base
                stocks and high performance ashless additives. These oils have
                exceptional resistance to oxidation and thermal degradation. The
                thermal stability and oxidation resistance of these fluids can
                help maintain cleaner compressors, thereby enabling longer
                running periods between scheduled maintenance and oil changes.
                <div className="sub-accordion">
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(0)}
                    >
                      <h4>Specifications</h4>
                      <span>{subActiveIndex === 0 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 0 && (
                      <div className="sub-accordion-body">
                        Proprietary grades
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(1)}
                    >
                      <h4>Applications</h4>
                      <span>{subActiveIndex === 1 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 1 && (
                      <div className="sub-accordion-body">
                        Recommended for oil flooded or oil injected, single or
                        two-stage screw compressors powered by Electricity or
                        Diesel
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(2)}
                    >
                      <h4>Benefits</h4>
                      <span>{subActiveIndex === 2 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 2 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>
                            Excellent anti-wear & higher load carrying capacity.
                          </li>
                          <li>Outstanding oxidation and thermal stability</li>
                          <li>Excellent water separation characteristics</li>
                          <li>
                            Prevents rust and corrosion of internal compressor
                            components
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="auto-lubricant">
        <div className="auto-lubricant-text">
          <h2>Pnumatic Tool Oil</h2>
          <img
            src="https://www.bharatpetroleum.in/images/Pneumatic-Tool-Oil.jpg"
            alt=""
          />
        </div>
        <div className="accordion">
          <div className="accordion-item">
            <div
              className="accordion-header"
              onClick={() => handleAccordionClick(5)}
            >
              <h3>About</h3>
              <span>{activeIndex === 5 ? "-" : "+"}</span>
            </div>
            {activeIndex === 5 && (
              <div className="accordion-body">
                Pneumatic tool oil, also known as air tool oil, is a specialized
                lubricant designed for use in pneumatic tools. Pneumatic tools
                use compressed air to power their motors, and the oil helps to
                lubricate the internal components of the tool to prevent wear
                and tear and keep the tool running smoothly.
              </div>
            )}
          </div>
          <div className="accordion-item">
            <div
              className="accordion-header"
              onClick={() => handleAccordionClick(3)}
            >
              <h3>Kyros Metamol R 100</h3>
              <span>{activeIndex === 3 ? "-" : "+"}</span>
            </div>
            {activeIndex === 3 && (
              <div className="accordion-body">
                Premium quality lubricating oils for pneumatic tools
                <br></br>
                Kyros Metamol R grades are high quality lubricating oils developed
                from highly refined high viscosity index paraffinic base stock
                and carefully selected additive package. The high quality base
                stock combined with the additive package imparts outstanding
                ability to maintain oil film and effectively lubricate the
                demanding requirements of pneumatic mechanisms. Kyros Metamol R
                has been exclusively developed to meet the special lubrication
                requirements of all percussion type pneumatic tools
                <div className="sub-accordion">
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(0)}
                    >
                      <h4>Specification</h4>
                      <span>{subActiveIndex === 0 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 0 && (
                      <div className="sub-accordion-body">
                        Proprietary grade
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(1)}
                    >
                      <h4>Applications</h4>
                      <span>{subActiveIndex === 1 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 1 && (
                      <div className="sub-accordion-body">
                        This oil is specially designed to meet the lubrication
                        requirements of percussion type pneumatic tools in
                        general and rock drills in particular, where lubrication
                        requirements are very critical.
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(2)}
                    >
                      <h4>Benefits</h4>
                      <span>{subActiveIndex === 2 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 2 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>Exhibits extreme pressure properties</li>
                          <li>Possesses rust preventive properties</li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="accordion-item">
            <div
              className="accordion-header"
              onClick={() => handleAccordionClick(4)}
            >
              <h3>Kyros Metamol 220</h3>
              <span>{activeIndex === 4 ? "-" : "+"}</span>
            </div>
            {activeIndex === 4 && (
              <div className="accordion-body">
                Premium quality lubricating oils for pneumatic tools
                <br></br>
                Kyros Metamol R grades are high quality lubricating oils developed
                from highly refined high viscosity index paraffinic base stock
                and carefully selected additive package. The high quality base
                stock combined with the additive package imparts outstanding
                ability to maintain oil film and effectively lubricate the
                demanding requirements of pneumatic mechanisms. Kyros Metamol R
                has been exclusively developed to meet the special lubrication
                requirements of all percussion type pneumatic tools including
                those subjected to the most arduous conditions.
                <div className="sub-accordion">
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(0)}
                    >
                      <h4>Specifications</h4>
                      <span>{subActiveIndex === 0 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 0 && (
                      <div className="sub-accordion-body">
                        Proprietary grades
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(1)}
                    >
                      <h4>Applications</h4>
                      <span>{subActiveIndex === 1 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 1 && (
                      <div className="sub-accordion-body">
                        Lubrication of slides & tables of machine tools for both
                        horizontal and vertical guide ways.
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(2)}
                    >
                      <h4>Benefits</h4>
                      <span>{subActiveIndex === 2 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 2 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>Exhibits very good oxidation stability</li>
                          <li>
                            Contains Anti-wear, non-drip and oiliness additive.
                          </li>
                        </ul>
                        Major Approvals:<br></br>
                        Kyros Metamol 32, 68 & 220 - RDSO for pneumatic tools.
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* Third */}
      <div className="auto-lubricant">
        <div className="auto-lubricant-text">
          <h2>Gears</h2>
          <img
            src="https://www.bharatpetroleum.in/images/220x220-Gear_1.jpg"
            alt=""
          />
        </div>
        <div className="accordion">
          <div className="accordion-item">
            <div
              className="accordion-header"
              onClick={() => handleAccordionClick(7)}
            >
              <h3>Kyros Amocam</h3>
              <span>{activeIndex === 7 ? "-" : "+"}</span>
            </div>
            {activeIndex === 7 && (
              <div className="accordion-body">
                Premium quality high viscosity index, extreme pressure oils for
                industrial gears
                <br></br>
                Kyros Amocam is a range of premium quality, extreme pressure
                industrial gear lubricants blended from high viscosity index
                solvent refined high quality base oils. The superior quality
                sulphur-phosphorus additive chemistry imparts high level of
                antiwear and extreme pressure property. Their high load carrying
                capacity and anti-friction property offer excellent performance
                in industrial gears and other industrial applications.
                <div className="sub-accordion">
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(0)}
                    >
                      <h4>Specification</h4>
                      <span>{subActiveIndex === 0 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 0 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>IS 8406: 1993 (EP Type) [Reaffirmed 2001]</li>
                          <li>IPSS: 1-09-003 • US Steel 224</li>
                          <li>David Brown S.1.53.101</li>
                          <li>DIN 51 517 (Part 3)</li>
                          <li>AGMA 250.04 5EP & AGMA 251.02 5EP</li>
                        </ul>
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(1)}
                    >
                      <h4>Applications</h4>
                      <span>{subActiveIndex === 1 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 1 && (
                      <div className="sub-accordion-body">
                        Recommended for the lubrication of all types of
                        industrial enclosed gear drives operating at high load &
                        speed conditions. Also recommended for the lubrication
                        of worm gear units. Bearing Lubrication where EP type
                        Oil is recommended.
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(2)}
                    >
                      <h4>Benefits</h4>
                      <span>{subActiveIndex === 2 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 2 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>
                            Selected additive chemistry offers extended die /
                            tool life and minimizes down time
                          </li>

                          <li>
                            Provides good surface finish and proper sheared
                            straight edges in fine blanked components.
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="accordion-item">
            <div
              className="accordion-header"
              onClick={() => handleAccordionClick(8)}
            >
              <h3>Kyros Amocam Plus</h3>
              <span>{activeIndex === 8 ? "-" : "+"}</span>
            </div>
            {activeIndex === 8 && (
              <div className="accordion-body">
                Advanced extreme pressure, anti-micropitting oils for heavy duty
                industrial gears
                <br></br>
                Kyros Amocam Plus is a range of advanced, heavy duty, extreme
                pressure industrial gear lubricants blended from high viscosity
                index and high quality base stocks. These oils have been
                developed to deliver optimum value through enhanced wear
                protection, long oil life and high system efficiency. The
                superior quality additive chemistry imparts high level of
                antiwear and extreme pressure property. Their high load carrying
                capacity and anti-friction property offer excellent performance
                in industrial gears and other industrial applications.
                <div className="sub-accordion">
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(0)}
                    >
                      <h4>Specifications</h4>
                      <span>{subActiveIndex === 0 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 0 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>IS 8406: 1993 (EP Type) [Reaffirmed, 2001]</li>
                          <li>US Steel 224 • IPSS:1-09-003</li>
                          <li>DIN 51 517 (Part 3)</li>
                          <li>DAVID BROWN S. 1. 53. 101</li>
                          <li>AGMA 250.04 5EP & AGMA 251.02 5EP</li>
                          <li>Micro pitting performance - Flender AG</li>
                        </ul>
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(1)}
                    >
                      <h4>Applications</h4>
                      <span>{subActiveIndex === 1 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 1 && (
                      <div className="sub-accordion-body">
                        Used for the lubrication of all types of industrial
                        enclosed gear drives operating at high load & speed
                        conditions. The need of such gear oil increases wherever
                        there is possibility of shock loads as in case of
                        enclosed spur, helical, herring bone, bevel and
                        planetary gearboxes.
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(2)}
                    >
                      <h4>Benefits</h4>
                      <span>{subActiveIndex === 2 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 2 && (
                      <div className="sub-accordion-body">
                        Premium quality EP type industrial gear oil, which meets
                        Flender’s requirement for anti-micro pitting gear oil to
                        take care of shock loads.
                        <br></br>
                        Major Approvals:
                        <br></br>
                        <ul>
                          <li>
                            Kyros Amocam Plus 320 - Bhushan Steel for Gear box
                            lubrication in the gear box of their uncoiler and
                            Walchandnagar Inds for their Cement Division.
                          </li>
                          <li>
                            Kyros Amocam Plus 460 - Walchandnagar Inds, Cement
                            Division.
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="accordion-item">
            <div
              className="accordion-header"
              onClick={() => handleAccordionClick(26)}
            >
              <h3>Kyros Egnol</h3>
              <span>{activeIndex === 26 ? "-" : "+"}</span>
            </div>
            {activeIndex === 26 && (
              <div className="accordion-body">
                High viscosity mineral oils for industrial gears
                <br></br>
                Kyros Engol is a range of premium quality industrial gear
                lubricants blended from high viscosity index solvent refined
                high quality base oils. The high load carrying capacity and
                anti-friction property of these oils offer excellent performance
                in industrial gears and other industrial applications. They
                offer extra protection to the gear teeth, bearings and seals in
                order to handle severe stresses that occur in industrial gears.
                <div className="sub-accordion">
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(0)}
                    >
                      <h4>Specifications</h4>
                      <span>{subActiveIndex === 0 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 0 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>IS 1589 : 1994 (Type 1) [Reaffirmed 2001]</li>
                        </ul>
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(1)}
                    >
                      <h4>Applications</h4>
                      <span>{subActiveIndex === 1 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 1 && (
                      <div className="sub-accordion-body">
                        These are recommended for bath lubricated gears, which
                        operate at low speeds and under normal loads for which
                        oils of high viscosity are required.
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(2)}
                    >
                      <h4>Benefits</h4>
                      <span>{subActiveIndex === 2 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 2 && (
                      <div className="sub-accordion-body">
                        Straight mineral oils having enhanced lubricating
                        properties and thermal stability.
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="accordion-item">
            <div
              className="accordion-header"
              onClick={() => handleAccordionClick(9)}
            >
              <h3>Kyros Syngear</h3>
              <span>{activeIndex === 9 ? "-" : "+"}</span>
            </div>
            {activeIndex === 9 && (
              <div className="accordion-body">
                Advanced synthetic oils for heavy duty industrial gears
                <br></br>
                Kyros Syngear is a range of fully synthetic gear oils with
                outstanding lubrication performance. They are formulated from
                special quality wax-free polyalphaolefin (PAO) synthetic base
                stocks that have exceptional oxidation resistance and thermal
                properties, high viscosity index and excellent low temperature
                fluidity. Their high load carrying capacity and anti-friction
                property offer excellent performance in industrial gears and
                other industrial applications. The high viscosity index and low
                traction coefficient of these oils combine to provide a
                potential reduction in power consumption in many gear systems.
                <div className="sub-accordion">
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(0)}
                    >
                      <h4>Specifications</h4>
                      <span>{subActiveIndex === 0 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 0 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>U.S. Steel 224</li>
                          <li>AGMA 9005-E-02</li>
                          <li>DIN 51517 Part 3</li>
                          <li>Micro-pitting performance - Flender AG</li>
                          <li>David Brown S1.53.101 Type E</li>
                          <li>FAG FE8 test</li>
                        </ul>
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(1)}
                    >
                      <h4>Applications</h4>
                      <span>{subActiveIndex === 1 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 1 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>
                            {" "}
                            Heavily loaded enclosed spur, helical, herringbone,
                            bevel and planetary gearboxes.
                          </li>
                          <li>
                            Application where the OEMs recommends synthetic
                            extreme pressure gear oil.
                          </li>
                          <li>
                            Industrial gear drives where full extreme pressure
                            and micro-pitting performance due to heavy / shock
                            loading is needed.
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(2)}
                    >
                      <h4>Benefits</h4>
                      <span>{subActiveIndex === 2 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 2 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li> High level anti-wear performance</li>
                          <li>Extreme pressure property</li>
                          <li>Micro-pitting performance</li>
                          <li>Excellent oxidation & thermal stability</li>
                          <li>Good low temperature properties</li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="accordion-item">
            <div
              className="accordion-header"
              onClick={() => handleAccordionClick(10)}
            >
              <h3>Kyros Syngear PG</h3>
              <span>{activeIndex === 10 ? "-" : "+"}</span>
            </div>
            {activeIndex === 10 && (
              <div className="accordion-body">
                Kyros SYNGEAR PG 320 is a synthetic gear lubricant based on the
                Polyalkylene Glycol base fluid and selected additives.
                <br></br>
                Polyalkylene Glycols (PAGs) are homopolymers of ethylene oxide
                or propylene oxide, or copolymers of ethylene oxide or propylene
                oxide. Because of the unique structure, PAG’s possess a low
                friction coefficient. Polyglycolbased gear oils offer
                significant advantages over mineral oils when a lot of sliding
                friction occurs in gearboxes and lowers oil temperatures that in
                turn extend oil change intervals. PAG based gear oils are used
                whenever mineral gear oils have reached their performance limit
                and can no longer meet the application requirements
                <div className="sub-accordion">
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(1)}
                    >
                      <h4>Applications</h4>
                      <span>{subActiveIndex === 1 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 1 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>
                            PAG gear oils are recommended for highly stressed
                            crown, bevel, planetary and worm drive gears
                          </li>
                          <li>
                            Poly glycol based gear oils offer significant
                            advantages over mineral oils when a lot of sliding
                            friction occurs in gearboxes and lowers oil
                            temperatures that in turn extend oil change
                            intervals.
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(2)}
                    >
                      <h4>Benefits</h4>
                      <span>{subActiveIndex === 2 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 2 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>High level anti-wear performance</li>
                          <li>Extreme pressure property</li>
                          <li>Micro-pitting performance</li>
                          <li>Excellent oxidation & thermal stability</li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* {Fourth} */}
      <div className="auto-lubricant">
        <div className="auto-lubricant-text">
          <h2>Grease</h2>
          <img
            src="https://www.bharatpetroleum.in/images/220x220-GREASE.jpg"
            alt=""
          />
        </div>
        <div className="accordion">
          <div className="accordion-item">
            <div
              className="accordion-header"
              onClick={() => handleAccordionClick(11)}
            >
              <h3>Compressor Oil 150</h3>
              <span>{activeIndex === 11 ? "-" : "+"}</span>
            </div>
            {activeIndex === 11 && (
              <div className="accordion-body">
                Superior quality reciprocating air compressor oil
                <br></br>
                Kyros Compressor Oils are high quality, high performance mineral
                oils developed for reciprocating air compressors. They are
                formulated from highly refined, high viscosity index base stocks
                and high performance additives with ashless chemistry.
                Reciprocating compressors operate at high temperatures and
                pressures. The lubricant creates a strong film in order to avoid
                surface wear. These oils have exceptional resistance to
                oxidation and thermal degradation.
                <div className="sub-accordion">
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(0)}
                    >
                      <h4>Specification</h4>
                      <span>{subActiveIndex === 0 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 0 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>DIN 51 506 - VDL</li>
                          <li>ISO / DP 6521</li>
                          <li>
                            IS 13256:1992 DAA & DAB type [Reaffirmed 1998]
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(1)}
                    >
                      <h4>Applications</h4>
                      <span>{subActiveIndex === 1 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 1 && (
                      <div className="sub-accordion-body">
                        For high performance reciprocating & rotary air
                        compressors where air discharge temperatures may go up
                        to 220°C.
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(2)}
                    >
                      <h4>Benefits</h4>
                      <span>{subActiveIndex === 2 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 2 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>
                            Longer Oil Life- This oil has strong oxidation
                            resistance and hence is less prone to degradation
                          </li>

                          <li>
                            Lower Maintenance costs: oil has lesser tendency to
                            form sludge & varnish and hence it provides trouble
                            free operation and lower maintenance costs Improved
                            Compressor performance: Due to low carbon forming
                            tendency, deposit forming tendency of oil is
                            reduced. This provides improved valve operation and
                            reduced deposits in lines.
                          </li>
                          <li>
                            Extended maintenance intervals: due to reduced
                            deposit formation on valves, the maintenance
                            intervals for valves and other components increase.
                          </li>
                        </ul>
                        Major Approvals:
                        <br></br>
                        <ul>
                          <li>ELGI Equipment Ltd.</li>
                          <li>
                            RDSO for use in Expressers / Compressors on diesel
                            and electric locomotives of Indian Railways.
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="accordion-item">
            <div
              className="accordion-header"
              onClick={() => handleAccordionClick(12)}
            >
              <h3>Kyros Egnol J 680</h3>
              <span>{activeIndex === 12 ? "-" : "+"}</span>
            </div>
            {activeIndex === 12 && (
              <div className="accordion-body">
                High viscosity compounded oils for industrial gears
                <br></br>
                Kyros Engol J is a range of premium quality industrial gear
                lubricants blended from high viscosity index solvent refined
                high quality base oils and fatty oils. The fatty oil content
                allows for excellent wettability and the ability to work in high
                moisture conditions. The high load carrying capacity and
                anti-friction property of these oils offer excellent performance
                in industrial gears and other industrial applications.
                <div className="sub-accordion">
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(0)}
                    >
                      <h4>Specification</h4>
                      <span>{subActiveIndex === 0 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 0 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>IS 1589:1994 (Type 2) [Reaffirmed 2001]</li>
                          <li>IPSS:1-09-010</li>
                          Our grades meet above specification for the respective
                          Viscosity Grades.
                        </ul>
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(1)}
                    >
                      <h4>Applications</h4>
                      <span>{subActiveIndex === 1 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 1 && (
                      <div className="sub-accordion-body">
                        Lubrication in gear boxes, calendar bearings and roller
                        bearings in Sugar Mills; where high viscosity and
                        compounded type of oils are required. These oils are
                        also suitable for lubrication of worm gears where
                        Sulphur/Phosphorus type EP additives are not recommended
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(2)}
                    >
                      <h4>Benefits</h4>
                      <span>{subActiveIndex === 2 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 2 && (
                      <div className="sub-accordion-body">
                        These oils are compounded with fatty oils to provide
                        good wet ability having excellent oiliness and film
                        strength properties.
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="accordion-item">
            <div
              className="accordion-header"
              onClick={() => handleAccordionClick(13)}
            >
              <h3>Kyros Hitemp Xtra Grease</h3>
              <span>{activeIndex === 13 ? "-" : "+"}</span>
            </div>
            {activeIndex === 13 && (
              <div className="accordion-body">
                Premium non-soap based grease for high temperature application
                <br></br>
                Kyros Hitemp Xtra Grease is a premium quality clay thickened
                non-soap base smooth structured grease formulated using superior
                quality mineral base oil and high performance additives. Kyros
                Hitemp Xtra Grease is characterised by a very high dropping
                point, outstanding oxidation stability, and excellent water
                resistance property. It also offers good protection against rust
                and corrosion. The grease is fortified with 3% molybdenum
                disulphide (MoS2) as solid lubricant for protection of moving
                parts under severe shock load.
                <div className="sub-accordion">
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(0)}
                    >
                      <h4>Specifications</h4>
                      <span>{subActiveIndex === 0 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 0 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>IS 12790 : 1999 (Grade 2)</li>
                        </ul>
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(1)}
                    >
                      <h4>Applications</h4>
                      <span>{subActiveIndex === 1 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 1 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>
                            This is used in the lubrication of machine elements,
                            plain bearing and anti-friction bearings operating
                            at high temperatures.
                          </li>
                          <li>
                            Recommended in lubrication in various steel plant
                            applications where the components have to work under
                            high temperatures
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(2)}
                    >
                      <h4>Benefits</h4>
                      <span>{subActiveIndex === 2 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 2 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>
                            High Drop point-With a drop point of greater than
                            250 °C, it is well suited to lubricate components
                            efficiently under high temperature conditions also
                          </li>
                          <li>
                            Good Oxidation Stability- Gives enhanced life to the
                            grease lubricant and minimum changing intervals
                          </li>
                          <li>
                            Excellent Shock Load Carrying abilities- Due to
                            presence of MoS2 in the formulation, the grease has
                            good shock loading carrying ability, making it
                            suitable to protect and lubricate the machine
                            components even under intermittent loads
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="accordion-item">
            <div
              className="accordion-header"
              onClick={() => handleAccordionClick(27)}
            >
              <h3>Kyros Lanthax EP Grease 2</h3>
              <span>{activeIndex === 27 ? "-" : "+"}</span>
            </div>
            {activeIndex === 27 && (
              <div className="accordion-body">
                Premium quality extreme pressure grease for automotive and
                industrial application
                <br></br>
                Kyros Lanthax EP Grease is a premium quality lithium based grease
                containing extreme pressure additives. It is formulated with
                high quality mineral base oils and lithium soap thickener. This
                grease is suitable for situations warranting the use of extreme
                pressure grease. Kyros Lanthax EP Grease is designed to handle the
                most severe loads in a wide range of automotive and industrial
                applications. The grease has a recommended operating temperature
                range from -20O C to 130O C. Kyros Lanthax EP Grease has high
                resistance to mechanical breakdown, excellent oxidation
                resistance, good rust protection, and excellent water washout
                resistance.
                <div className="sub-accordion">
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(0)}
                    >
                      <h4>Specifications</h4>
                      <span>{subActiveIndex === 0 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 0 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li> IS 7623 : 1993 EP Type [Reaffirmed 2001]</li>
                          <li>IPSS : 1-09-005-99</li>
                        </ul>
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(1)}
                    >
                      <h4>Applications</h4>
                      <span>{subActiveIndex === 1 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 1 && (
                      <div className="sub-accordion-body">
                        This grease is recommended for all plain & roller
                        bearings in equipment operating under severe shock
                        loading conditions and temperatures up to about 130°C
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(2)}
                    >
                      <h4>Benefits</h4>
                      <span>{subActiveIndex === 2 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 2 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>
                            Good Oxidation Stability- Gives enhanced life to the
                            grease lubricant and minimum changing intervals
                          </li>
                          <li>
                            Excellent Load Carrying abilities- this grease has
                            good extreme pressure properties, making it suitable
                            to protect and lubricate the machine components even
                            under high loads
                          </li>
                          <li>
                            Good Shear Stability- Provides grease with extra
                            ability to resist early shear and work longer during
                            harsh working conditions
                          </li>
                          <li>
                            Heat and water resistant-The grease has good water
                            resistant properties and recommended to use upto
                            +130°C
                          </li>
                          <li>
                            Excellent corrosion resistant - Enhances the service
                            life of equipment even under harsh conditions.
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* Fifth */}
      <div className="auto-lubricant">
        <div className="auto-lubricant-text">
          <h2>Turbine</h2>
          <img
            src="https://www.bharatpetroleum.in/images/220x220-TURBINE-OILS.jpg"
            alt=""
          />
        </div>
        <div className="accordion">
          <div className="accordion-item">
            <div
              className="accordion-header"
              onClick={() => handleAccordionClick(14)}
            >
              <h3>About</h3>
              <span>{activeIndex === 14 ? "-" : "+"}</span>
            </div>
            {activeIndex === 14 && (
              <div className="accordion-body">
                A turbine is a type of rotary machine that converts the energy
                of a fluid (such as water, steam, or gas) into mechanical
                energy. The fluid is directed through a series of blades mounted
                on a rotor, which rotates as the fluid flows over the blades.
                The rotating motion of the rotor is then used to power a
                generator or other mechanical device.
                <br></br>
                Turbines are widely used in power generation, including
                hydroelectric, thermal, and gas turbine power plants.
                Hydroelectric turbines are typically used in dams and other
                waterways to convert the energy of falling water into
                electricity.
              </div>
            )}
          </div>
          <div className="accordion-item">
            <div
              className="accordion-header"
              onClick={() => handleAccordionClick(15)}
            >
              <h3>Kyros Turbol</h3>
              <span>{activeIndex === 15 ? "-" : "+"}</span>
            </div>
            {activeIndex === 15 && (
              <div className="accordion-body">
                Superior quality industrial steam, gas and hydraulic turbine
                oils
                <br></br>
                Kyros Turbol oils are high performance oils blended from highly
                refined, high viscosity index Group II plus base stocks and
                carefully selected additive. These oils are developed to meet
                the demands of the modern steam, gas and hydraulic turbines. The
                additive system imparts extremely high resistance to the
                chemical and thermal degradation of the oil.
                <div className="sub-accordion">
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(0)}
                    >
                      <h4>Specifications</h4>
                      <span>{subActiveIndex === 0 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 0 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>IS 1012 : 2002</li>
                          <li>DIN 51 515 Part 1</li>
                          <li>BS 489 : 1983</li>
                          <li>GEK 32568A</li>
                        </ul>
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(1)}
                    >
                      <h4>Applications</h4>
                      <span>{subActiveIndex === 1 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 1 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>Good oxidation & thermal stability</li>
                          <li>Provide excellent protection against rusting</li>
                          <li>Ability to release entrained air rapidly</li>
                          <li>
                            Excellent demulsibility and resistance to foaming
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(2)}
                    >
                      <h4>Benefits</h4>
                      <span>{subActiveIndex === 2 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 2 && (
                      <div className="sub-accordion-body">
                        <br></br>
                        <ul>
                          <li>Excellent Thermal stability</li>
                          <li>Non corrosive & non toxic , anti dust</li>
                          <li>Controlled hardness</li>
                          <li>Low vapour pressure hence resists cracking</li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="accordion-item">
            <div
              className="accordion-header"
              onClick={() => handleAccordionClick(16)}
            >
              <h3>Kyros Turbol EP</h3>
              <span>{activeIndex === 16 ? "-" : "+"}</span>
            </div>
            {activeIndex === 16 && (
              <div className="accordion-body">
                High quality heavy duty turbine oils for geared turbines
                <br></br>
                Kyros Turbol EP oils are high performance oils blended from highly
                refined, high viscosity index Group II plus base stocks and
                carefully selected additive. These oils are developed to meet
                the demands of the high-output modern geared turbines. The
                additive system imparts extremely high extreme pressure
                properties, resistance to the chemical and thermal degradation
                of the oil.
                <div className="sub-accordion">
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(0)}
                    >
                      <h4>Specifications</h4>
                      <span>{subActiveIndex === 0 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 0 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>FZG NIEMANN TEST 10th FLS</li>
                          <li>IS 1012 : 2002</li>
                          <li>DIN 51 515 Part 1</li>
                          <li>BS 489 : 1983</li>
                          <li>GEK 32568A</li>
                          <li>SIEMENS 0 – 1130-0468-01</li>
                        </ul>
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(1)}
                    >
                      <h4>Applications</h4>
                      <span>{subActiveIndex === 1 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 1 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>
                            These oils are recommended for Geared Turbine which
                            requires extreme pressure properties.
                          </li>
                          <li>
                            This grade can also be supplied in Super Clean
                            (Typically NAS 7 at plant level) HDPE Barrels
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(2)}
                    >
                      <h4>Benefits</h4>
                      <span>{subActiveIndex === 2 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 2 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>
                            Exceptionally good oxidation & chemical stability
                          </li>
                          <li>Excellent Thermal stability</li>
                          <li>Non corrosive & non toxic</li>
                          <li>Controlled hardness</li>
                          <li>Low vapour pressure hence resists cracking</li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* Sixth */}
      <div className="auto-lubricant">
        <div className="auto-lubricant-text">
          <h2>Hydraulics</h2>
          <img
            src="https://www.bharatpetroleum.in/images/220x220-HYDRAULIC-OIL.jpg"
            alt=""
          />
        </div>
        <div className="accordion">
          <div className="accordion-item">
            <div
              className="accordion-header"
              onClick={() => handleAccordionClick(18)}
            >
              <h3>Kyros Frhf DU</h3>
              <span>{activeIndex === 18 ? "-" : "+"}</span>
            </div>
            {activeIndex === 18 && (
              <div className="accordion-body">
                Advanced high performance fire resistant hydraulic fluids
                <br></br>
                Kyros FRHF DU fluids are advanced, fully synthetic less flammable
                hydraulic fluids. They are blended from polyol esters and high
                performance additives. These fluids are developed to provide
                good performance in conventional hydraulic systems and exhibit
                better fire resistance than mineral oils. They are biodegradable
                with low ecotoxicity. Excellent viscosity control enable them to
                operate over a wide range of working temperatures.
                <div className="sub-accordion">
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(0)}
                    >
                      <h4>Specification</h4>
                      <span>{subActiveIndex === 0 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 0 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>ISO 12922 : 2012 Category HFDU</li>
                        </ul>
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(1)}
                    >
                      <h4>Applications</h4>
                      <span>{subActiveIndex === 1 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 1 && (
                      <div className="sub-accordion-body">
                        Suitable for use in Mining, Steel, Hot Rolling, Glass,
                        Tunnelling, Offshore Drilling, Food Processing
                        industries etc. This grade is also suitable for use in
                        hydraulic systems.
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(2)}
                    >
                      <h4>Benefits</h4>
                      <span>{subActiveIndex === 2 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 2 && (
                      <div className="sub-accordion-body">
                        Polyol ester type fire resistant hydraulic fluid. This
                        is biodegradable in nature and is fortified with
                        anti-wear, anti-corrosion additives etc.
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="accordion-item">
            <div
              className="accordion-header"
              onClick={() => handleAccordionClick(19)}
            >
              <h3>Kyros Hydrol CE</h3>
              <span>{activeIndex === 19 ? "-" : "+"}</span>
            </div>
            {activeIndex === 19 && (
              <div className="accordion-body">
                Industrial antiwear hydraulic fluid for superior performance
                <br></br>
                Kyros Hydrol AW range is a group of premium quality, transparent
                and antiwear hydraulic oils. They are blended from
                hydro-processed, high viscosity index base oils with carefully
                selected antiwear and other additives. These oils are designed
                to operate over a wide range of working conditions including low
                load and severe high load conditions. High rate of water
                separation, exceptional hydrolytic stability, anti-foam and
                cleanliness allow efficient operation of the system. These oils
                ensures high level of antiwear property and film strength.
                <div className="sub-accordion">
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(0)}
                    >
                      <h4>Specifications</h4>
                      <span>{subActiveIndex === 0 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 0 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>IS 3098:1983 [Reaffirmed 1998]</li>
                          <li>IS 10522:1983 [Reaffirmed 1998]</li>
                          <li>IPSS : 1-09-022</li>
                          <li>Vickers V-104C Vane pump test</li>
                          <li>DIN 51 524 Part 1 HL Type</li>
                        </ul>
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(1)}
                    >
                      <h4>Applications</h4>
                      <span>{subActiveIndex === 1 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 1 && (
                      <div className="sub-accordion-body">
                        These oils are recommended for general hydraulic
                        equipment in control and power transmission systems and
                        for circulating equipment.
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(2)}
                    >
                      <h4>Benefits</h4>
                      <span>{subActiveIndex === 2 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 2 && (
                      <div className="sub-accordion-body">
                        <br></br>
                        <ul>
                          <li>
                            Fortified with very carefully selected anti-wear
                            additives
                          </li>
                          <li>
                            They also show exceptional hydrolytic stability and
                            demulsibility and resist any deterioration due to
                            water contamination.
                          </li>
                          <li>
                            These oils are compatible with seals normally used
                            in hydraulic systems.
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="accordion-item">
            <div
              className="accordion-header"
              onClick={() => handleAccordionClick(20)}
            >
              <h3>Kyros Hydrol </h3>
              <span>{activeIndex === 20 ? "-" : "+"}</span>
            </div>
            {activeIndex === 20 && (
              <div className="accordion-body">
                Premium ashless hydraulic fluid for long life and improved
                efficiency
                <br></br>
                Kyros Hydrol CE oils are high performance premium quality
                hydraulic oils blended from highly refined, high viscosity index
                Group II plus base stocks with carefully selected ashless
                (Zinc-free) antiwear additive. These oils are designed to
                operate over a wide range of working conditions including wide
                load fluctuation. They possess high FZG rating and provide
                outstanding protection and performance. Outstanding oxidation
                and thermal stability offers long oil life, a high degree of
                protection and minimised deposit formation.
                <div className="sub-accordion">
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(0)}
                    >
                      <h4>Specifications</h4>
                      <span>{subActiveIndex === 0 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 0 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>FZG Niemann EP 11th FLS</li>
                          <li>IS 11656 : 1986 [Reaffirmed 2002]</li>
                          <li>DIN 51524 Part 2 HLP type</li>
                          <li>Denison HF-0 & HF-2</li>
                          <li>Vickers 1-286-S US</li>
                          <li>Steel 127</li>
                          <li>Cincinnati Milacron P-70</li>
                        </ul>
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(1)}
                    >
                      <h4>Applications</h4>
                      <span>{subActiveIndex === 1 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 1 && (
                      <div className="sub-accordion-body">
                        Zinc free ash less type oil suitable for high pressure
                        hydraulic systems, pumps, industrial hydraulic
                        machineries operating at high speeds, loads and
                        temperatures.
                        <br></br>
                        Sophisticated high performance electro-hydraulic or
                        numerically controlled systems found in concrete mixture
                        units used in construction applications. Also suitable
                        for the lubrication of screw and vane type rotary
                        compressors.
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(2)}
                    >
                      <h4>Benefits</h4>
                      <span>{subActiveIndex === 2 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 2 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>
                            Excellent EP properties which leads to better
                            response to high load conditions
                          </li>
                          <li>
                            Extra heavy duty anti-wear oils with outstanding
                            thermal stability
                          </li>
                          <li>
                            Possess excellent compatibility with seals normally
                            used in hydraulic system.
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="accordion-item">
            <div
              className="accordion-header"
              onClick={() => handleAccordionClick(21)}
            >
              <h3>Hydrol HLP</h3>
              <span>{activeIndex === 21 ? "-" : "+"}</span>
            </div>
            {activeIndex === 21 && (
              <div className="accordion-body">
                Industrial extra heavy duty hydraulic fluid for long life,
                excellent performance and protection
                <br></br>
                Kyros Hydrol HLP oils are high performance premium quality
                hydraulic oils. They are blended from highly refined, high
                viscosity index Group II plus base oils with carefully selected
                balanced antiwear additive. These oils are designed to operate
                over a wide range of working conditions including low load and
                severe high load conditions. They provide outstanding protection
                and performance in manufacturing and other operations.
                <div className="sub-accordion">
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(0)}
                    >
                      <h4>Specifications</h4>
                      <span>{subActiveIndex === 0 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 0 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>11th FLS of FZG – Niemann EP test</li>
                          <li>IS 11656: 1986 [Reaffirmed March, 2002]</li>
                          <li>DIN 51 524 Part II HL P Type</li>
                          <li>Denison HF-0 & HF-2</li>
                          <li>Eaton Vickers I-286-S & M-2950-S</li>
                          <li>Eaton Vickers 35VQ25 Pump Test</li>
                          <li>US Steel 127</li>
                          <li>Cincinnati Milacron P-70</li>
                        </ul>
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(1)}
                    >
                      <h4>Applications</h4>
                      <span>{subActiveIndex === 1 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 1 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>
                            Recommended for hydraulic systems and a wide variety
                            of circulation systems of industrial and automotive
                            equipment.
                          </li>
                          <li>
                            Also used in General manufacturing, Metal and Power
                            equipment
                          </li>
                          <li>
                            Suitable for the lubrication of screw and vane type
                            rotary compressors recommended for mining and
                            construction equipment
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(2)}
                    >
                      <h4>Benefits</h4>
                      <span>{subActiveIndex === 2 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 2 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>
                            Outstanding Oxidation Stability: Reduces
                            consumption, filter blocking and valve sticking.
                          </li>
                          <li>
                            Good water and foam resistant properties: Separates
                            out water very fast in case of water ingression
                            releases entrapped air immediately to avoid foaming.
                          </li>
                          <li>
                            Excellent Hydrolytic Stability: This avoids
                            absorption of water in oil and thus enhances fluid
                            life.
                          </li>
                          <li>
                            Excellent Seal Compatibility: Prolongs seal life and
                            reduces maintenance.
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="accordion-item">
            <div
              className="accordion-header"
              onClick={() => handleAccordionClick(22)}
            >
              <h3>Hydrol HVLP</h3>
              <span>{activeIndex === 22 ? "-" : "+"}</span>
            </div>
            {activeIndex === 22 && (
              <div className="accordion-body">
                Industrial high viscosity index extra heavy duty hydraulic fluid
                for excellent performance over wide temperature range
                <br></br>
                Kyros Hydrol HVLP oils are high performance premium quality
                hydraulic oils. They are blended from hydroprocessed, high
                viscosity index Group II plus base oils with proven zinc based
                antiwear additive. These oils are designed with excellent
                viscosity control to operate over a wide range of working
                conditions including severe mechanical stress and wide
                temperature range. They provide outstanding protection and
                performance in manufacturing and other operations subjected to
                wide ranges of ambient and operating temperatures.
                <div className="sub-accordion">
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(0)}
                    >
                      <h4>Specifications</h4>
                      <span>{subActiveIndex === 0 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 0 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>IS 11656 : 1986 [Reaffirmed 2002]</li>
                          <li>
                            DIN 51 524 Part 3 – HVLP Type Denison HF-0 & HF-2
                          </li>
                          <li>Vickers 1-286-S</li>
                          <li>US Steel 127</li>
                          <li>Cincinnati Milacron P-70</li>
                        </ul>
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(1)}
                    >
                      <h4>Applications</h4>
                      <span>{subActiveIndex === 1 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 1 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>
                            Recommended for high pressure systems and power
                            transmission systems requiring very high viscosity
                            index oil, operating under high speeds, load and
                            temperatures.
                          </li>

                          <li>
                            Also recommended for hydraulic applications with
                            sophisticated high performance electro-hydraulic or
                            numerically controlled systems.
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(2)}
                    >
                      <h4>Benefits</h4>
                      <span>{subActiveIndex === 2 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 2 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>
                            Outstanding Anti-wear performance: Ensures minimum
                            wear throughout the range of operations.
                          </li>
                          <li>
                            Outstanding Oxidation Stability: Reduces
                            consumption, filter blocking and valve sticking.
                          </li>
                          <li>
                            Good water and foam resistant properties: Separates
                            out water very fast in case of water ingression
                          </li>
                          <li>Excellent Hydrolytic Stability</li>
                          <li>Excellent Seal Compatibility</li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* Seventh */}
      <div className="auto-lubricant">
        <div className="auto-lubricant-text">
          <h2>Transformers</h2>
          <img
            src="https://www.bharatpetroleum.in/images/220x220-Transformer-oils.jpg"
            alt=""
          />
        </div>
        <div className="accordion">
          <div className="accordion-item">
            <div
              className="accordion-header"
              onClick={() => handleAccordionClick(23)}
            >
              <h3>About</h3>
              <span>{activeIndex === 23 ? "-" : "+"}</span>
            </div>
            {activeIndex === 23 && (
              <div className="accordion-body">
                It consists of two coils of wire, known as the primary and
                secondary windings, which are wound around a common magnetic
                core. When an alternating current (AC) is passed through the
                primary winding, it creates a magnetic field that induces a
                voltage in the secondary winding. The ratio of the number of
                turns in the primary winding to the number of turns in the
                secondary winding determines the amount of voltage change that
                occurs.
              </div>
            )}
          </div>
          <div className="accordion-item">
            <div
              className="accordion-header"
              onClick={() => handleAccordionClick(24)}
            >
              <h3>Hivoltol</h3>
              <span>{activeIndex === 24 ? "-" : "+"}</span>
            </div>
            {activeIndex === 24 && (
              <div className="accordion-body">
                High performance uninhibited electrical insulating oil
                <br></br>
                Kyros Hivoltol is a high quality mineral uninhibited electrical
                insulating oil. It is manufactured from severely hydrotreated
                low aromatic oil and does not have polar compounds. It has very
                good dielectric property, high resistance to oxidation and
                aging. It has excellent low temperature property, low viscosity
                and provides efficient heat transfer. Kyros Hivoltol has a high
                resistance to thermal and chemical degradation and offers very
                high breakdown voltage. Special care is taken to achieve very
                low water content in the oil. It is noncorrosive towards copper
                and does not require passivation.
                <div className="sub-accordion">
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(0)}
                    >
                      <h4>Specification</h4>
                      <span>{subActiveIndex === 0 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 0 && (
                      <div className="sub-accordion-body">
                        IS 335:2018 (Type II) – Uninhibited
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(1)}
                    >
                      <h4>Applications</h4>
                      <span>{subActiveIndex === 1 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 1 && (
                      <div className="sub-accordion-body">
                        It is used as a quenching media for power and
                        distribution electrical apparatus such as transformers,
                        switch gears, circuit breakers, capacitors and other
                        allied equipment.
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(2)}
                    >
                      <h4>Benefits</h4>
                      <span>{subActiveIndex === 2 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 2 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>
                            Outstanding Oxidation Stability :No sludge
                            formation, Improves life of oil & solid insulation
                          </li>
                          <li>
                            High volume Resistivity : Gives high insulation
                            resistance between transformer windings
                          </li>
                          <li>
                            Very low dielectric dissipation factor: Improves
                            efficiency of transformer
                          </li>
                          <li>
                            Highly refined Group II plus oil : low aromatic and
                            sulphur content and have high initial interfacial
                            tension
                          </li>
                          <li>
                            Non corrosive and low acidity formation : Enhance
                            life of oil and transformer
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="accordion-item">
            <div
              className="accordion-header"
              onClick={() => handleAccordionClick(25)}
            >
              <h3>Benefits</h3>
              <span>{activeIndex === 25 ? "-" : "+"}</span>
            </div>
            {activeIndex === 25 && (
              <div className="accordion-body">
                Voltage regulation: Transformers can regulate voltage levels in
                electrical circuits. , Electrical isolation:which helps prevent
                electrical noise and interference from disrupting sensitive
                electronic devices. , Efficiency: transferring electrical power
                from one circuit to another, with minimal power loss during the
                process. , Safety: Transformers help improve electrical safety
                by isolating the input and output circuits.
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default LubCement;
