import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import guar from "../Assets/guarh.png";
function Guar_Products() {
  const location = useLocation();
  const onionref = useRef();
  const garlicref = useRef();
  const spicesref = useRef();
  const driedref = useRef();
  useEffect(() => {
    window.scrollTo(0, 0);
    if (location.hash === "#scrollonion") {
      onionref.current.scrollIntoView({ behaviour: "smooth" });
    }
    if (location.hash === "#scrollgarlic") {
      garlicref.current.scrollIntoView({ behaviour: "smooth" });
    }
    if (location.hash === "#scrollspices") {
      spicesref.current.scrollIntoView({ behaviour: "smooth" });
    }
    if (location.hash === "#scrolldried") {
      driedref.current.scrollIntoView({ behaviour: "smooth" });
    }
  }, [location]);

  return (
    <div>
      <p className="primary-subheading"></p>
      <h1 className="primary-mainheading">Guar Gum Powder</h1>
      <div className="work-section-top" id="scrollonion" ref={onionref}>
        <h1 className="primary-heading">Guargum</h1>
        <div className="fruit-images">
          <div className="fruit1">
            <div className="one">
              <img
                src="https://m.media-amazon.com/images/I/41+WH4rshjL.jpg"
                alt="is-guar-gum-1544222258"
                border="0"
              />
            </div>
            <div className="one">
              <img src={guar} alt="guarh" border="0" />
            </div>
          </div>
        </div>
        <p>
          Gum is derived from guar seeds or cyamopsis tetragonoloba termed as
          Guar Gum. Guar Gum can also be termed as guaran. These seeds have high
          low-shear viscosity as evaluated with other hydrocolloids like (locust
          bean gum). Guar Gums are effective thickeners and stabilizers. Guar
          Gum is relatively cost effective as compared to other thickeners and
          stabilizers along with it being an effective binder, plasticizer and
          emulsifier.
        </p>
        <p>
          One of the important properties of guar gum, a polysaccharide, is that
          it is high on galactose and mannose. Guar gum is also known as
          guarkernmehl, guaran, goma guar, gomme guar and galactomannan.
        </p>
      </div>
      <div className="guar-gum">
        <table>
          <tbody>
            <tr>
              <th>S.No</th>
              <th>Guar Gum Powder</th>
              <th>Code</th>
            </tr>
            <tr>
              <td>1</td>
              <td>HS-Code of Guar Gum Powder</td>
              <td>130.232.30</td>
            </tr>
            <tr>
              <td>2</td>
              <td>CAS No. of Guar Gum Powder</td>
              <td>9000-30-0</td>
            </tr>
            <tr>
              <td>3</td>
              <td>EEC No. of Guar Gum Powder</td>
              <td>9000-30-0</td>
            </tr>
            <tr>
              <td>4</td>
              <td>BT No. of Guar Gum Powder</td>
              <td>1302.3290</td>
            </tr>
            <tr>
              <td>5</td>
              <td>EINECS No. of Guar Gum Powder</td>
              <td>232.536.8</td>
            </tr>

            <tr>
              <td>6</td>
              <td>Imco-Code</td>
              <td>Harmless</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="work-section-top">
        <p className="primary-text">
          <b>Properties of Guar Gum:</b>
        </p>
        <div className="lists-primary-text">
          <ul>
            <li>
              Guar gum has reasonably more thickening property as compared to
              corn starch.
            </li>
            <li>Holds back the growth of ice crystal</li>
            <li>Guar is draught resistant plant</li>
            <li>Guar gum forms gel in water</li>
          </ul>
          <br></br>
          Endosperm of guar seeds are used in many sectors of industries like
          mining, petroleum, drilling and textile., food products,
          pharmaceuticals, cosmetics, water treatment, mining, drilling,
          explosives, confectioneries and many more. Since a long time Guar Gum
          can be also named as a hydrocolloid, is treated as the key product for
          humans and animals as it has a very high nourishing property.
          <br></br>
          Applications: Food, Bakery, PetFood, Mining, Nutraceuticals, Tobacco
          Industry, Oil Drilling
        </div>
      </div>

      <hr className="line-dividing" />

      <div className="work-section-top">
        <h1 className="primary-heading">Guargum powder (Industrial Grade):</h1>
        <div className="alcohol-img">
          <img
            src="https://i.ibb.co/GTrT2gP/Screenshot-2023-04-25-001224.png"
            alt="Screenshot-2023-04-25-001224"
            border="0"
          />
        </div>
      </div>

      <hr className="line-dividing" />
      <div className="work-section-top">
        <h1 className="primary-heading">Guargum powder (Food Grade):</h1>
        <div className="alcohol-img">
          <img
            src="https://i.ibb.co/M7xL3s5/Screenshot-2023-04-25-002142.png"
            alt="Screenshot-2023-04-25-002142"
            border="0"
          />
        </div>
      </div>
      <hr className="line-dividing" />
      <div className="work-section-top" ref={garlicref} id="scrollgarlic">
        <h1 className="primary-heading">Fast Hydration Guar Gum Powder</h1>
        <div className="alcohol-img">
          <img
            src="https://i.ibb.co/M7xL3s5/Screenshot-2023-04-25-002142.png"
            alt="Screenshot-2023-04-25-002142"
            border="0"
          />
        </div>
        <p>
          The natural fast hydrating and slurriable guar is a non ionic guar,
          polymeric viscosifier that is slurrible up to 50% of solids in systems
          such as diesel fuel. In aqueous systems, its main use is in the
          stimulation fluid.This yields excellent pump ability, good hydartion,
          good thermal stability and excellent shell life.It can be typically
          corss linked eith borates and once corss linked with exhibits
          excellent carrying capacity to suppprt sand.
        </p>
        <div className="lists-primary-text">
          <p>
            <b>Applications</b>
          </p>
          <ul>
            <li>Mid Drilling</li>
            <li>Oil & Gas Drilling</li>
            <li>Water Drilling</li>
            <li>Geological Drilling</li>
          </ul>
        </div>
      </div>

      <div className="work-section-top">
        <div className="lists-primary-text">
          <b>Technical Specifications</b>
          <ul>
            <li>Moisture: 5% Max</li>
            <li>Ash: 1% Max</li>
            <li>R.I.A: 5% Max</li>
            <li>Galactomannan: 80% Min</li>
            <li>pH: 6.5 - 7.5</li>
            <li>Granulometry</li>
            <li>Through 200 mesh: 97% Min</li>
          </ul>
          <b>Fann Viscosity</b>
          <p>
            2 minutes stirring at 2000rpm ,10gm KCL & 2.4 gm Guar gum powder in
            500ml water.using Fann 35 Viscometer at 300 RPM.
          </p>
        </div>
      </div>
      <div className="guar-gum">
        <table>
          <tbody>
            <tr>
              <th>After 3 Min.</th>
              <th>After 1 Hour.</th>
            </tr>
            <tr>
              <td>30 cps</td>
              <td>35 cps</td>
            </tr>
            <tr>
              <td>32 cps</td>
              <td>37 cps</td>
            </tr>
            <tr>
              <td>35 cps</td>
              <td>40 cps</td>
            </tr>
            <tr>
              <td>37 cps</td>
              <td>42 cps</td>
            </tr>
            <tr>
              <td>40cps</td>
              <td>45 cps</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="work-section-top">
        <b>Packing available</b>
        <p>
          25 Kgs HDPE Paper Laminated Bags. (800 Bags x 25 Kgs = 20,000 Kgs/FCL)
          <br></br>
          50 Kgs HDPE Paper Laminated Bags. (400 Bags x 50 Kgs = 20,000 Kgs/FCL)
          <br></br>
          Bulk Bags with Bottom Drop Up to 1 MT/Bag. (1000 Kgs x 20 bags = 20
          MT/FCL)
        </p>
      </div>
    </div>
  );
}

export default Guar_Products;
