import React, { useEffect } from "react";

function LubricantAutoMobile() {
  useEffect(() => {
    window.scroll(0, 0);
  });
  return (
    <div className="lubricant-container">
      <div className="lubricant">
        <h2 className="primary-subheading">Energising Growth</h2>
        <p className="primary-information">
          Kyros Lubricants are available at about 50000+ retailers through the
          dedicated efforts of our PLDs. In order to leverage rural potential
          Kyros rolled out Rural Lubes Distributors (RLDs) at taluka level.
          These distributors compliment the efforts of PLDs in deeper
          penetration of Kyros range. Similarly to cater to small and MSMEs
          which are mushrooming in India, Kyros has commissioned more than 150+
          Industrial Lubes distributors (ILD) majorly catering to Industries and
          Institutional Sales.
        </p>
        <h2 className="primary-subheading">Bazaar Market</h2>
        <img
          src="https://www.bharatpetroleum.in/images/MAK-connect-3-(1)(1)(1).png"
          alt=""
        />
        <p className="primary-information">
          The hi-street market in the lubricants sector is known as Bazaar
          Market.
          <br></br>
          Kyros Lubricants - a late entrant in this category – has grown
          exponentially and is now considered a brand to reckon.
        </p>
      </div>
    </div>
  );
}

export default LubricantAutoMobile;
