import { Stack } from "@mui/material";
import Button from "@mui/material/Button";
import React, { useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";

function Products() {
  const recycleref = useRef(null);
  const location = useLocation();
  useEffect(() => {
    if (location.hash === "#scrollrecycle") {
      recycleref.current.scrollIntoView({ behaviour: "smooth" });
    }
  }, [location]);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  const productsList = [
    {
      key: 1,
      name: "Lubricants",
      url: "/lubricants",
      text: "A substance that helps to reduce friction between surfaces in mutual contact, which ultimately reduces the heat generated when the surfaces move. It may also have the function of transmitting forces, transporting foreign particles",
    },
    {
      key: 2,
      name: "Alcohol",
      url: "/alcohol",
      text: "It is a colorless and flammable liquid that is commonly used as a recreational drug and as a solvent in various industries. It is produced through the fermentation of sugars and can be found in various alcoholic beverages",
    },
    {
      key: 3,
      name: "Solvent",
      url: "/solvent",
      text: "A solvent is a substance that has the ability to dissolve other substances (solutes) to form a homogeneous solution. Mostly used in various industries such as chemical manufacturing, pharmaceuticals, and cleaning products.",
    },
    {
      key: 4,
      name: "Guar Gum Powder",
      url: "/guar-gum-powder",
      text: " It is a natural thickener and stabilizer derived from the seeds of the guar plant. It is commonly used in the food industry to improve texture, viscosity, and shelf life of various products such as ice cream, sauces, and baked goods.",
    },
    {
      key: 5,
      name: "Castor Oil",
      url: "/castor",
      text: "Non-volatile fatty oil obtained from the seeds of the castor bean, Ricinus communis, of the spurge family (Euphorbiaceae). It is used in the production of synthetic resins, plastics, fibres, paints, varnishes",
    },
  ];
  return (
    <div>
      <div className="work-section-wrapper">
        {productsList.map((item) => (
          <div>
            <div className="work-section-top">
              <p className="primary-mainheading">{item.name}</p>

              <p className="work-spices-text">{item.text}</p>
            </div>
            <div className="spices-button">
              <Stack spacing={2} direction="row">
                <Button variant="contained">
                  <Link to={item.url} className="link-products">
                    Explore More
                  </Link>
                </Button>
              </Stack>
            </div>
            <br></br>
            <hr className="line-dividing"></hr>
            <br></br>
            <br></br>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Products;
