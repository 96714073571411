import React, { useEffect } from "react";
import recycle from "../Assets/recycle1.jpg";
function Ewaste() {
  useEffect(() => {
    window.scroll(0, 0);
  });
  return (
    <div>
      <div className="work-section-wrapper">
        <div className="work-section-top">
          <h1 className="primary-mainheading">E- Waste Recycling</h1>
        </div>
        <div className="metal-image">
          <img
            src={recycle}
            alt="Recycling-and-Zero-Waste-Concept-Illustration-Instagram-posts-1"
            border="0"
          />
        </div>

        <div className="work-section-top">
          <p>
            All that exists is opportunity and potential in spinning around the
            unwanted electronics of today to be a sustainable resource for
            tomorrow.
          </p>
          <p>
            Electronic waste, or e-waste, is a growing problem worldwide. As
            technology advances, consumers are increasingly replacing their
            electronic devices, which has led to a surge in the amount of
            e-waste generated each year. E-waste contains a variety of hazardous
            materials, including heavy metals and toxic chemicals, which can
            harm human health and the environment if not properly disposed of.
          </p>
          <p>
            E-waste recycling is a sustainable solution that can help reduce the
            environmental impact of electronic devices while conserving natural
            resources. Recycling e-waste involves the recovery of valuable
            materials such as gold, silver, copper, and plastics, which can be
            reused in the manufacturing of new products. By recycling e-waste,
            we can reduce the amount of waste sent to landfills and minimize the
            need for mining and refining virgin materials
          </p>
          <p>
            E-waste recycling in India has gained momentum in recent years, with
            several organizations offering e-waste collection and recycling
            services. These organizations ensure that e-waste is safely
            collected, transported, and processed, adhering to strict
            environmental and health regulations. Many of these organizations
            also offer e-waste management and disposal services to businesses,
            helping them comply with e-waste regulations
          </p>
          <p>
            If you are looking to dispose of your electronic devices in an
            environmentally friendly way, it is important to choose a reputable
            e-waste recycling service provider. Make sure that the organization
            you choose is certified and follows proper safety and environmental
            regulations. By recycling your e-waste, you can contribute to a more
            sustainable future for generations to come.
          </p>
          <br></br>
        </div>
      </div>
    </div>
  );
}

export default Ewaste;
