import React, { useEffect } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import linkedin from "../Assets/174857.png";
import Sid from "../Assets/SiddharthP.jpg";
import Sam from "../Assets/SameerN.jpg";
import Footer from "./Footer";
function More() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <br></br>
      <div className="heading-recycling">
        <h1 style={{ fontSize: "2.5rem" }}>Our Leadership</h1>
      </div>
      <div className="carousel-director">
        <img src={Sid} alt="IMG-0859" border="0" className="director-images" />
        <div className="myCarousel">
          <h3>Mr. Siddharth Pawar</h3>
          <h4>Director</h4>

          <div className="carousel-contact">
            <a href="https://www.linkedin.com/in/ssiddharthpawar5/">
              <img src={linkedin} alt=""></img>
            </a>
          </div>
          <p>
            Leading tech-preneur having core expertise in waste management,
            sustainability and circular economy. His aim and objective of
            delivering the best of all, he has established strong bonds with our
            suppliers and strategic partners / customers based on trust, good
            business ethics which makes him a reliable player in the global
            market.
          </p>
        </div>
      </div>

      <div className="carousel-director">
        <img src={Sam} alt="" className="director-images" />
        <div className="myCarousel">
          <h3>Mr. Sameer Nikam</h3>
          <h4>Director</h4>

          <div className="carousel-contact">
            <a href="https://www.linkedin.com/in/sameer-nikam-46681b224/">
              <img src={linkedin} alt=""></img>
            </a>
          </div>
          <p>
            Well renowned waste management specialist having more than 33+ years
            of practical business experience right from the root levels in the
            recycling industry. With future interests of up-cycling and value
            addition to the scarce primary industrial raw materials, he believes
            to implement the 3R rule to Reduce, Reuse, Recycle!
          </p>
        </div>
      </div>
      <div className="about-recycling">
        {/* <div className="heading-recycling">
          <h2>Recycling</h2>
        </div>
        <div className="subheading-recycling">
          <h4>| WHERE ECONOMIC AND ENVIRONMENTAL INTERESTS MEET |</h4>
        </div> */}

        <div
          className="primary-text"
          style={{
            margin: "auto",

            maxWidth: "1000px",
            padding: "2% 4%",
            fontSize: "47px",
            fontFamily: "Sail",
          }}
        >
          Our Story
        </div>
        <div
          className="recycling-text"
          style={{
            paddingTop: "8%",
            margin: "auto",
            textAlign: "left",
            maxWidth: "1000px",
            padding: "0 5%",
          }}
        >
          <p>
            Welcome to Kyros Immpex Private Limited, an import and export firm
            situated in Pune, India's Twin City! We have been a significant
            exporter of organic and inorganic chemicals, APIs, castor oil,
            essential oils, and other products to the industry since 1972. Our
            high-quality products and prompt delivery have established us as a
            trustworthy partner for organizations all around the world.
          </p>

          <p>
            Because of our expertise in the chemical business, we can provide a
            wide range of products to a variety of industries, including
            medicines, cosmetics, food, and agriculture. We make certain that
            our items satisfy the highest quality standards and are acquired
            from reputable suppliers.
          </p>

          <p>
            We are proud of our commitment to sustainability and environmental
            responsibility. As a result, we specialise in shipping organic
            chemicals that are devoid of dangerous toxins and additives. Our
            castor oil is also supplied sustainably and is suitable for cosmetic
            and industrial applications.
          </p>

          <p>
            In addition to our export services, we also import metal scrap and
            paper scrap for recycling industries in India. By providing these
            materials, we contribute to the circular economy and help reduce
            waste.
          </p>
          <p>
            Kyros believes in developing long-term relationships with our
            clients. Our skilled team is committed to providing great customer
            service, ensuring that our clients' needs are addressed at every
            step of the journey. We have the resources and knowledge to meet the
            needs of any size company, whether it is a little business or a huge
            corporation.
          </p>
          <p>
            Get in touch with us to learn more about our products and services.
            We look forward to working with you!
          </p>
          <div
            style={{
              fontFamily: "Great Vibes",
              margin: "auto",
              fontSize: "2rem",
              textAlign: "center",
            }}
          >
            "Building long-term relationships through exceptional trade
            services!"
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default More;
