import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

function Spiceole() {
  const occref = useRef(null);
  const dsoccref = useRef(null);
  const onpref = useRef(null);
  const tissueref = useRef(null);
  const location = useLocation();
  useEffect(() => {
    window.scroll(0, 0);
    if (location.hash === "#scrollocc") {
      occref.current.scrollIntoView({ behaviour: "smooth" });
    }
    if (location.hash === "#scrolldsocc") {
      dsoccref.current.scrollIntoView({ behaviour: "smooth" });
    }
    if (location.hash === "#scrollonp") {
      onpref.current.scrollIntoView({ behaviour: "smooth" });
    }
    if (location.hash === "#scrolltissue") {
      tissueref.current.scrollIntoView({ behaviour: "smooth" });
    }
  }, [location]);
  return (
    <div>
      <div className="work-section-wrapper">
        <div className="work-section-top">
          <h1 className="primary-mainheading">Spice Oleoresin & Herbals</h1>
        </div>
        <br></br>
        <br></br>
      </div>

      <div className="work-section-top" ref={occref} id="scrollocc">
        <h2 className="primary-heading">Capsicum Oleoresin 2.5% to 15%</h2>
        <div className="fruit-images">
          <div className="fruit1" style={{ display: "flex" }}>
            <div className="one">
              <img
                src="http://www.adanipharma.com/images/product/spice%20oleoresin/1.jpg"
                alt="is-corn-grain-1544222258"
                border="0"
              />
              <p>Molecular Structure</p>
            </div>
            <div className="one">
              <img
                src="http://www.adanipharma.com/images/product/spice%20oleoresin/01.jpg"
                alt="is-corn-grain-1544222258"
                border="0"
              />
              <p>Product Image</p>
            </div>
          </div>
        </div>
        <p>
          Uses of Product<br></br>
          It is powerful irritant and carminative used as counter irritant in
          lumbago and neuralgia. It can also be used to treat stomach ache that
          involves poorly functioning stomach muscles and as an antibacterial
          agents.
        </p>
      </div>
      <div className="line-dividing"></div>

      <div className="work-section-top" id="scrolldsocc" ref={dsoccref}>
        <h2 className="primary-heading">Oleoresin Paprika Exporters India</h2>
        <div className="fruit-images">
          <div className="fruit1">
            <div className="one">
              <img
                src="http://www.adanipharma.com/images/product/spice%20oleoresin/03.jpg"
                alt="is-corn-grain-1544222258"
                border="0"
              />
              <p>Product Image</p>
            </div>
          </div>
        </div>
        <p>
          Uses of Oleoresin Paprika<br></br>
          Oleoresin Paprika is available in the prominent range in our product
          catalog which is good for the national, domestic and international
          market. From the past decade, this is proved as the important range of
          herbs and we are proud to announce you that we, Kyros Pharmaceutical
          industry provide you this well recognized natural flavor herb which is
          efficient to color the reagents for regular usage of the domestic and
          commercial markets.
          <br></br>
          It is good and safe to use and that premium range will not toxidise
          your food items. This is good as natural and also a manufactured food
          product which is sold with lots of profit. As per the view of
          Oleoresin exporters in India, you can add this color in many popular
          food items as sweets, spices, mixture of food items, emulsified
          processed meats, cheese, juice of many fruits like orange juice and
          many other items which are processed with this technology.
        </p>
      </div>
      <div className="line-dividing"></div>

      <div className="work-section-top" id="scrollonp" ref={onpref}>
        <h2 className="primary-heading">Capsaicin Powder 95%</h2>
        <div className="fruit-images">
          <div className="fruit1" style={{ display: "flex" }}>
            <div className="one">
              <img
                src="http://www.adanipharma.com/images/product/spice%20oleoresin/2.jpg"
                alt="is-corn-grain-1544222258"
                border="0"
              />
              <p>Molecular Structure</p>
            </div>
            <div className="one">
              <img
                src="http://www.adanipharma.com/images/product/spice%20oleoresin/02.jpg"
                alt="is-corn-grain-1544222258"
                border="0"
              />
              <p>Product Image</p>
            </div>
          </div>
        </div>
        <p>
          Product Usage<br></br>
          It is used in medicated creams and lotions to relieve muscle or joint
          paincaused by strains, sprains, arthritis, bruising, or
          backaches.Capsaicin topical is also used to treat nerve pain
          (neuralgia) in people who have had herpes zoster, or shingles.
        </p>
      </div>
      <div className="line-dividing"></div>

      <div className="work-section-top">
        <h2 className="primary-heading" id="scrolltissue" ref={tissueref}>
          Curcumin Powder 95%
        </h2>
        <div className="fruit-images">
          <div className="fruit1" style={{ display: "flex" }}>
            <div className="one">
              <img
                src="http://www.adanipharma.com/images/product/spice%20oleoresin/4.jpg"
                alt="is-corn-grain-1544222258"
                border="0"
              />
              <p>Molecular Structure</p>
            </div>
            <div className="one">
              <img
                src="http://www.adanipharma.com/images/product/spice%20oleoresin/04.jpg"
                alt="is-corn-grain-1544222258"
                border="0"
              />
              <p>Product Image</p>
            </div>
          </div>
        </div>
        <p>
          Uses of Product<br></br>
          It is natural occurring colouring & flavouring agent. Because of its
          anti-inflammatory properties, it is used to treat some inflammatory
          conditions. Curcumin supplements are popular for their potential
          antioxidant benefits. It is often touted as an anti-cancer agent.
        </p>
      </div>
      <div className="line-dividing"></div>

      <div className="work-section-top">
        <h2 className="primary-heading">Bulk Drugs Exporters India</h2>
        <div className="fruit-images">
          <div className="fruit1">
            <div className="one">
              <img
                src="http://www.adanipharma.com/images/product/piperazine/05.jpg"
                alt="is-corn-grain-1544222258"
                border="0"
              />
              <p>Product Image</p>
            </div>
          </div>
        </div>
        <p>
          We are from Kyros pharmaceutical industry which is popular for its
          well-known and reputed manufacturers, bulk drug exporters and
          developed drugs in all over the world. We offer a great range of high
          quality drugs and import and exports several drugs for the welfare
          programs in all around on the global level.
          <br></br>
          Our industry is well known for the production of bulk amount of drugs
          so that users do not have to suffer a lot due to the poor quality or
          lack of medicines. We serve our clients with the regarding qualities
          in our services with particular nature and our sales department help
          the users to buy them.
        </p>
      </div>
      <div className="line-dividing"></div>

      <div className="work-section-top">
        <h2 className="primary-heading">Fine Chemicals Exporters</h2>

        <p>
          We evolved as prominent fine chemical exporters in pharmaceuticals
          industry selling premium range of veterinary drugs to our clients
          worldwide.
          <br></br>
          We had quality team to check our products at different parameters
          before it is released into market. We always assure flawless
          production for our clients and following international quality
          standards and norms.
          <br></br>
          Our Quality Control Laboratory consists of 7 rooms as Hot Lab.,
          Physico-Chemical Lab., Instrumental Lab., Stability Lab., Control
          Sample Room, Document Room and Q. C. Office.
        </p>
      </div>
    </div>
  );
}

export default Spiceole;
