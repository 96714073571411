import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

function Citrate() {
  const occref = useRef(null);
  const dsoccref = useRef(null);
  const onpref = useRef(null);
  const tissueref = useRef(null);
  const location = useLocation();
  useEffect(() => {
    window.scroll(0, 0);
    if (location.hash === "#scrollocc") {
      occref.current.scrollIntoView({ behaviour: "smooth" });
    }
    if (location.hash === "#scrolldsocc") {
      dsoccref.current.scrollIntoView({ behaviour: "smooth" });
    }
    if (location.hash === "#scrollonp") {
      onpref.current.scrollIntoView({ behaviour: "smooth" });
    }
    if (location.hash === "#scrolltissue") {
      tissueref.current.scrollIntoView({ behaviour: "smooth" });
    }
  }, [location]);
  return (
    <div>
      <div className="work-section-wrapper">
        <div className="work-section-top">
          <h1 className="primary-mainheading">Citrate Derivatives</h1>
        </div>
        <br></br>
        <br></br>
      </div>

      <div className="work-section-top" ref={occref} id="scrollocc">
        <h2 className="primary-heading">
          Sodium Citrate Dihydrate / Anhydrous
        </h2>
        <div className="fruit-images">
          <div className="fruit1" style={{ display: "flex" }}>
            <div className="one">
              <img
                src="http://www.adanipharma.com/images/product/citrate%20derivatives/2.jpg"
                alt="is-corn-grain-1544222258"
                border="0"
              />
              <p>Molecular Structure</p>
            </div>
            <div className="one">
              <img
                src="http://www.adanipharma.com/images/product/citrate%20derivatives/02.jpg"
                alt="is-corn-grain-1544222258"
                border="0"
              />
              <p>Product Image</p>
            </div>
          </div>
        </div>
        <p>
          Product Usage
          <ul>
            <li>Mainly used as food additive and preservative</li>
            <li>The anticoagulant in blood transfusions</li>
            <li>Used to relieve discomfort in urinary tract infections</li>
            <li>
              It also works as buffering agent in food and acidity regulator as
              antacid
            </li>
            <li>
              As a sequestrant – to improve the quality and stability of the
              food products
            </li>
            <li>As a emulsifier - to stabilize processed foods like cheese</li>
          </ul>
        </p>
      </div>
      <div className="line-dividing"></div>

      <div className="work-section-top" id="scrolldsocc" ref={dsoccref}>
        <h2 className="primary-heading">Di Sodium Citrate</h2>
        <div className="fruit-images">
          <div className="fruit1" style={{ display: "flex" }}>
            <div className="one">
              <img
                src="http://www.adanipharma.com/images/product/citrate%20derivatives/6.jpg"
                alt="is-corn-grain-1544222258"
                border="0"
              />
              <p>Molecular Structure</p>
            </div>
            <div className="one">
              <img
                src="http://www.adanipharma.com/images/product/citrate%20derivatives/06.jpg"
                alt="is-corn-grain-1544222258"
                border="0"
              />
              <p>Product Image</p>
            </div>
          </div>
        </div>
        <p>
          Kyros Pharma provides the best quality Disodium citrate to its global
          clients. It is a white powdered drug soluble in water used as an
          antioxidant in food and an acidity regulator.
          <br></br>
          We have a standard packing of 25 Kg in the Polywooven craft bag with
          dual inner polyethylene bag. You can avail our other range of products
          such as calcium citrate, sodium citrate, magnesium citrate, etc. at
          best prices.
          <br></br>
          Share your requirements with Kyros Pharma experts and get a response
          from the team.
        </p>
      </div>
      <div className="line-dividing"></div>

      <div className="work-section-top" id="scrollonp" ref={onpref}>
        <h2 className="primary-heading">Mono Sodium Citrate</h2>
        <div className="fruit-images">
          <div className="fruit1" style={{ display: "flex" }}>
            <div className="one">
              <img
                src="http://www.adanipharma.com/images/product/citrate%20derivatives/5.jpg"
                alt="is-corn-grain-1544222258"
                border="0"
              />
              <p>Molecular Structure</p>
            </div>
            <div className="one">
              <img
                src="http://www.adanipharma.com/images/product/citrate%20derivatives/05.jpg"
                alt="is-corn-grain-1544222258"
                border="0"
              />
              <p>Product Image</p>
            </div>
          </div>
        </div>
        <p>
          Product Usage<br></br>
          It is used as a relatively strong sequestrant. It is used to prevent
          platelet clumping in blood samples, thus used as an anticoagulant in
          donated blood.
        </p>
      </div>
      <div className="line-dividing"></div>

      <div className="work-section-top">
        <h2 className="primary-heading" id="scrolltissue" ref={tissueref}>
          Potassium Citrate
        </h2>
        <div className="fruit-images">
          <div className="fruit1" style={{ display: "flex" }}>
            <div className="one">
              <img
                src="http://www.adanipharma.com/images/product/citrate%20derivatives/1.jpg"
                alt="is-corn-grain-1544222258"
                border="0"
              />
              <p>Molecular Structure</p>
            </div>
            <div className="one">
              <img
                src="http://www.adanipharma.com/images/product/citrate%20derivatives/01.jpg"
                alt="is-corn-grain-1544222258"
                border="0"
              />
              <p>Product Image</p>
            </div>
          </div>
        </div>
        <p>
          Kyros Pharma is a premium manufacturers and supplier of potassium
          citrate or tripotassium citrate. It is a hygroscopic crystalline
          powder that contains 38.3% potassium. It is a white colored powder
          that is used for reducing pain and frequency in urination. It is also
          used as veterinary drugs for dogs and cats.
          <br></br>
          Among other potassium citrate manufacturers, Kyros Pharma ranks at the
          top of the list. We provide wide range of products to pharmaceutical
          sector, food industry, soap making facilities, etc. You can easily get
          potassium citrate in a 25 kg packet at Kyros Pharma.
          <br></br>
          Our expert team working in the company also deals on bulk and
          wholesale orders. You can share your requirements with the experts and
          get a quote.
        </p>
      </div>
      <div className="line-dividing"></div>

      <div className="work-section-top">
        <h2 className="primary-heading">Calcium Citrate India</h2>
        <div className="fruit-images">
          <div className="fruit1" style={{ display: "flex" }}>
            <div className="one">
              <img
                src="http://www.adanipharma.com/images/product/citrate%20derivatives/3.jpg"
                alt="is-corn-grain-1544222258"
                border="0"
              />
              <p>Molecular Structure</p>
            </div>
            <div className="one">
              <img
                src="http://www.adanipharma.com/images/product/citrate%20derivatives/03.jpg"
                alt="is-corn-grain-1544222258"
                border="0"
              />
              <p>Product Image</p>
            </div>
          </div>
        </div>
        <p>
          Kyros PharmaChem is engaged in manufacturing of calcium citrate that
          customers can avail in various packaging sizes at competitive prices.
          Calcium citrate is the calcium salt extracted from citric acid. It is
          a common salt used in food industry as a food additive for
          preservation purposes.
          <br></br>
          All our ranges of products are widely accepted by various industries
          for their precise composition, longer shelf life and accurate pH
          value. Kyros PharmaChem professionals formulate their products using
          fine quality raw materials and ingredients that are sourced from the
          trusted vendors.
          <br></br>
          Kyros PharmaChem is among pioneer calcium citrate manufacturers that
          pack all products with high-grade packaging material for safety
          assurance. For queries and orders, you can write to Kyros Pharma
          support desk anytime from anywhere. we are supplying calcium citrate
          and other products throughout the world.
        </p>
      </div>
      <div className="line-dividing"></div>

      <div className="work-section-top">
        <h2 className="primary-heading">Calcium Citrate Maleate</h2>
        <div className="fruit-images">
          <div className="fruit1" style={{ display: "flex" }}>
            <div className="one">
              <img
                src="http://www.adanipharma.com/images/product/citrate%20derivatives/7.jpg"
                alt="is-corn-grain-1544222258"
                border="0"
              />
              <p>Molecular Structure</p>
            </div>
            <div className="one">
              <img
                src="http://www.adanipharma.com/images/product/citrate%20derivatives/07.jpg"
                alt="is-corn-grain-1544222258"
                border="0"
              />
              <p>Product Image</p>
            </div>
          </div>
        </div>
        <p>
          Uses of Product<br></br> In combination with vitamin D3, it is used to
          prevent or treat low blood calcium levels in people who do not get
          enough calcium from their diets. It is used to treat conditions caused
          by low calcium levels such as bone loss (osteoporosis), weak bones
          (osteomalacia/rickets), decreased activity of the parathyroid gland
          (hypoparathyroidism), and a certain muscle disease (latent tetany).
        </p>
      </div>
    </div>
  );
}

export default Citrate;
