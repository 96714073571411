import React, { useEffect, useState } from "react";

function LunInfra() {
  const [activeIndex, setActiveIndex] = useState(null);
  const [subActiveIndex, setSubActiveIndex] = useState(null);

  const handleAccordionClick = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
    setSubActiveIndex(null);
  };

  const handleSubAccordionClick = (index) => {
    setSubActiveIndex(subActiveIndex === index ? null : index);
  };
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div>
      <div className="lubricant">
        <h2 className="primary-subheading">Infrastructure</h2>
        <p className="primary-information">
          From excavators, dump trucks, tippers and all other construction
          equipments are subject to harsh conditions. Exposure to extreme cold
          or heat, moisture, dust and dirt can hurt lubricant performance and
          lead to premature equipment failure. Kyros Lubricants comprehensive
          range of products give superior lubrication protection and
          performance, which will help eliminate your downtime and will help
          reducing your operating costs.
        </p>
      </div>
      <div className="auto-lubricant">
        <div className="auto-lubricant-text">
          <h2>Compressor</h2>
          <img
            src="https://www.bharatpetroleum.in/images/COMPRESSOR%20OILS.jpg"
            alt=""
          />
        </div>
        <div className="accordion">
          <div className="accordion-item">
            <div
              className="accordion-header"
              onClick={() => handleAccordionClick(0)}
            >
              <h3>Compressor Oil</h3>
              <span>{activeIndex === 0 ? "-" : "+"}</span>
            </div>
            {activeIndex === 0 && (
              <div className="accordion-body">
                Superior quality reciprocating air compressor oil
                <br></br>
                Superior quality reciprocating air compressor oil Kyros
                Compressor Oils are high quality, high performance mineral oils
                developed for reciprocating air compressors. They are formulated
                from highly refined, high viscosity index base stocks and high
                performance additives with ashless chemistry. Reciprocating
                compressors operate at high temperatures and pressures. The
                lubricant creates a strong film in order to avoid surface wear.
                These oils have exceptional resistance to oxidation and thermal
                degradation. The thermal stability and oxidation resistance of
                these fluids can help maintain cleaner compressors, thereby
                enabling longer running periods between scheduled maintenance
                and oil changes.
                <div className="sub-accordion">
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(0)}
                    >
                      <h4>Specifications</h4>
                      <span>{subActiveIndex === 0 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 0 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>DIN 51 506 - VDL</li>
                          <li>ISO / DP 6521</li>
                          <li>
                            IS 13256:1992 DAA & DAB type [Reaffirmed 1998]
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(1)}
                    >
                      <h4>Applications</h4>
                      <span>{subActiveIndex === 1 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 1 && (
                      <div className="sub-accordion-body">
                        For high performance reciprocating & rotary air
                        compressors where air discharge temperatures may go up
                        to 220°C.
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(2)}
                    >
                      <h4>Benefits</h4>
                      <span>{subActiveIndex === 2 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 2 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>
                            Longer Oil Life- This oil has strong oxidation
                            resistance and hence is less prone to degradation
                          </li>
                          <li>
                            Lower Maintenance costs: oil has lesser tendency to
                            form sludge & varnish and hence it provides trouble
                            free operation and lower maintenance.
                          </li>
                          <li>
                            Extended maintenance intervals: due to reduced
                            deposit formation on valves, the maintenance
                            intervals for valves and other components increase.
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="accordion-item">
            <div
              className="accordion-header"
              onClick={() => handleAccordionClick(2)}
            >
              <h3>Kyros Compressor Oil C</h3>
              <span>{activeIndex === 2 ? "-" : "+"}</span>
            </div>
            {activeIndex === 2 && (
              <div className="accordion-body">
                Superior quality reciprocating air compressor oil for moist air
                <br></br>
                Kyros Compressor Oil C is a group of high quality, high
                performance oils developed for reciprocating air compressors
                handling moist working media. They are formulated from highly
                refined, high viscosity index base stocks and high performance
                additives. Reciprocating compressors sometimes operate with
                highly moist working medium like moist air or moist natural gas.
                The moisture is trapped in order to reduce the risk of corrosion
                of the compressor components. These oils are compounded to form
                stable emulsion with the moisture. The moisture is trapped and
                remains isolated from the metal surfaces. Reciprocating
                compressors operate at high temperatures and pressures.
                <div className="sub-accordion">
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(0)}
                    >
                      <h4>Specifications</h4>
                      <span>{subActiveIndex === 0 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 0 && (
                      <div className="sub-accordion-body">
                        Proprietary grades
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(1)}
                    >
                      <h4>Applications</h4>
                      <span>{subActiveIndex === 1 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 1 && (
                      <div className="sub-accordion-body">
                        These oils are recommended for cylinder lubrication in
                        reciprocating single and multi-stage air compressor
                        handling moist air and moist natural gas
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(2)}
                    >
                      <h4>Benefits</h4>
                      <span>{subActiveIndex === 2 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 2 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>
                            Excellent anti-wear & higher load carrying capacity.
                          </li>
                          <li>Outstanding oxidation and thermal stability</li>
                          <li>Excellent water separation characteristics</li>
                          <li>
                            Prevents rust and corrosion of internal compressor
                            components
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="accordion-item">
            <div
              className="accordion-header"
              onClick={() => handleAccordionClick(1)}
            >
              <h3>Screwcom</h3>
              <span>{activeIndex === 1 ? "-" : "+"}</span>
            </div>
            {activeIndex === 1 && (
              <div className="accordion-body">
                Premium quality oils for rotary screw air compressors
                <br></br>
                Kyros Screwcom Oils are high quality, high performance mineral
                oils developed for rotary vane and screw air compressors. They
                are formulated from highly refined, high viscosity index base
                stocks and high performance ashless additives. These oils have
                exceptional resistance to oxidation and thermal degradation. The
                thermal stability and oxidation resistance of these fluids can
                help maintain cleaner compressors, thereby enabling longer
                running periods between scheduled maintenance and oil changes.
                <div className="sub-accordion">
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(0)}
                    >
                      <h4>Specifications</h4>
                      <span>{subActiveIndex === 0 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 0 && (
                      <div className="sub-accordion-body">
                        Proprietary grades
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(1)}
                    >
                      <h4>Applications</h4>
                      <span>{subActiveIndex === 1 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 1 && (
                      <div className="sub-accordion-body">
                        Recommended for oil flooded or oil injected, single or
                        two-stage screw compressors powered by Electricity or
                        Diesel
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(2)}
                    >
                      <h4>Benefits</h4>
                      <span>{subActiveIndex === 2 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 2 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>
                            Excellent anti-wear & higher load carrying capacity.
                          </li>
                          <li>Outstanding oxidation and thermal stability</li>
                          <li>Excellent water separation characteristics</li>
                          <li>
                            Prevents rust and corrosion of internal compressor
                            components
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="auto-lubricant">
        <div className="auto-lubricant-text">
          <h2>DG Sets</h2>
          <img
            src="https://www.bharatpetroleum.in/images/DG-Sets-Engine-Oils.jpg"
            alt=""
          />
        </div>
        <div className="accordion">
          <div className="accordion-item">
            <div
              className="accordion-header"
              onClick={() => handleAccordionClick(5)}
            >
              <h3>Kyros CD</h3>
              <span>{activeIndex === 5 ? "-" : "+"}</span>
            </div>
            {activeIndex === 5 && (
              <div className="accordion-body">
                Kyros CD 40 is a high performance oil formulated with very high
                quality base oils and advanced additive technology. It is
                uniquely designed to optimise the performance of engine,
                transmission, hydraulic systems and drive trains. The oil offers
                enhanced protection to hydraulic system components even in high
                pressure systems.
                <div className="sub-accordion">
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(0)}
                    >
                      <h4>Specification</h4>
                      <span>{subActiveIndex === 0 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 0 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>API CD/SC</li>
                          <li>IS 13656:2002 Type EDL2 & EPL1</li>
                          <li>UK DEF 2101D</li>
                        </ul>
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(1)}
                    >
                      <h4>Applications</h4>
                      <span>{subActiveIndex === 1 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 1 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>Off-highway</li>
                          <li>Construction equipment</li>
                          <li>Earth Moving equipment</li>
                        </ul>
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(2)}
                    >
                      <h4>Benefits</h4>
                      <span>{subActiveIndex === 2 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 2 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>Good oxidation and thermal stability</li>
                          <li>
                            The multi grade version is suitable for all seasons
                            and helps in reducing oil consumption.
                          </li>
                          <li>Minimizes engine deposits.</li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="accordion-item">
            <div
              className="accordion-header"
              onClick={() => handleAccordionClick(3)}
            >
              <h3>Kyros D GEN</h3>
              <span>{activeIndex === 3 ? "-" : "+"}</span>
            </div>
            {activeIndex === 3 && (
              <div className="accordion-body">
                High quality engine oil for turbocharged DG sets
                <br></br>
                Kyros D-GEN 15W-40 is an exclusively formulated multigrade
                diesel engine oil for modern, high powered turbocharged DG sets
                that power mobile towers. It is manufactured using superior
                quality base oils and advanced additive chemistry. This oil is
                specially formulated to provide excellent protection to DG set
                engines operating under severe conditions.
                <div className="sub-accordion">
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(0)}
                    >
                      <h4>Specification</h4>
                      <span>{subActiveIndex === 0 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 0 && (
                      <div className="sub-accordion-body">
                        Proprietary grade
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(1)}
                    >
                      <h4>Applications</h4>
                      <span>{subActiveIndex === 1 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 1 && (
                      <div className="sub-accordion-body">
                        Recommended for use in all models of naturally aspirated
                        and turbocharged DG Sets.
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(2)}
                    >
                      <h4>Benefits</h4>
                      <span>{subActiveIndex === 2 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 2 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>
                            Enhanced thermo-oxidative stability ensures reduced
                            engine deposits thereby keeping the engine clean
                          </li>
                          <li>Longer oil and engine life</li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="accordion-item">
            <div
              className="accordion-header"
              onClick={() => handleAccordionClick(4)}
            >
              <h3>Kyros Metamol 220</h3>
              <span>{activeIndex === 4 ? "-" : "+"}</span>
            </div>
            {activeIndex === 4 && (
              <div className="accordion-body">
                High performance monograde heavy duty diesel engine oil
                <br></br> Kyros T 30 is a high performance monograde diesel
                engine oil blended from superior quality base oils and well
                balanced group of additives to provide high detergency,
                dispersancy and anti-oxidation characteristics.
                <div className="sub-accordion">
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(0)}
                    >
                      <h4>Specifications</h4>
                      <span>{subActiveIndex === 0 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 0 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>API CF / CD</li>
                          <li>Mack T-7 / T-8A Test</li>
                          <li>IS 13656 : 2002 Type EDL3</li>
                        </ul>
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(1)}
                    >
                      <h4>Applications</h4>
                      <span>{subActiveIndex === 1 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 1 && (
                      <div className="sub-accordion-body">
                        Recommended for all types of naturally aspirated, super
                        charged & turbo charged diesel engines used in
                        stationary DG sets and off-highway / earthmoving
                        equipment.
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(2)}
                    >
                      <h4>Benefits</h4>
                      <span>{subActiveIndex === 2 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 2 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>Exhibits very good oxidation stability</li>
                          <li>Good Anti-wear</li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* Third */}
      <div className="auto-lubricant">
        <div className="auto-lubricant-text">
          <h2>Gears</h2>
          <img
            src="https://www.bharatpetroleum.in/images/220x220-GEARS.jpg"
            alt=""
          />
        </div>
        <div className="accordion">
          <div className="accordion-item">
            <div
              className="accordion-header"
              onClick={() => handleAccordionClick(7)}
            >
              <h3>Kyros Amocam</h3>
              <span>{activeIndex === 7 ? "-" : "+"}</span>
            </div>
            {activeIndex === 7 && (
              <div className="accordion-body">
                Kyros D-GEN 15W-40 is an exclusively formulated multigrade
                diesel engine oil for modern, high powered turbocharged DG sets
                that power mobile towers. It is manufactured using superior
                quality base oils and advanced additive chemistry. This oil is
                specially formulated to provide excellent protection to DG set
                engines operating under severe conditions.
                <div className="sub-accordion">
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(0)}
                    >
                      <h4>Specification</h4>
                      <span>{subActiveIndex === 0 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 0 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>IS 8406: 1993 (EP Type) [Reaffirmed 2001]</li>
                          <li>IPSS: 1-09-003 • US Steel 224</li>
                          <li>David Brown S.1.53.101</li>
                          <li>DIN 51 517 (Part 3)</li>
                          <li>AGMA 250.04 5EP & AGMA 251.02 5EP</li>
                        </ul>
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(1)}
                    >
                      <h4>Applications</h4>
                      <span>{subActiveIndex === 1 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 1 && (
                      <div className="sub-accordion-body">
                        Recommended for the lubrication of all types of
                        industrial enclosed gear drives operating at high load &
                        speed conditions. Also recommended for the lubrication
                        of worm gear units. Bearing Lubrication where EP type
                        Oil is recommended.
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(2)}
                    >
                      <h4>Benefits</h4>
                      <span>{subActiveIndex === 2 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 2 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>
                            Selected additive chemistry offers extended die /
                            tool life and minimizes down time
                          </li>

                          <li>
                            Provides good surface finish and proper sheared
                            straight edges in fine blanked components.
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="accordion-item">
            <div
              className="accordion-header"
              onClick={() => handleAccordionClick(8)}
            >
              <h3>Kyros Amocam Plus</h3>
              <span>{activeIndex === 8 ? "-" : "+"}</span>
            </div>
            {activeIndex === 8 && (
              <div className="accordion-body">
                Advanced extreme pressure, anti-micropitting oils for heavy duty
                industrial gears
                <br></br>
                Kyros Amocam Plus is a range of advanced, heavy duty, extreme
                pressure industrial gear lubricants blended from high viscosity
                index and high quality base stocks. These oils have been
                developed to deliver optimum value through enhanced wear
                protection, long oil life and high system efficiency. The
                superior quality additive chemistry imparts high level of
                antiwear and extreme pressure property. Their high load carrying
                capacity and anti-friction property offer excellent performance
                in industrial gears and other industrial applications.
                <div className="sub-accordion">
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(0)}
                    >
                      <h4>Specifications</h4>
                      <span>{subActiveIndex === 0 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 0 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>IS 8406: 1993 (EP Type) [Reaffirmed, 2001]</li>
                          <li>US Steel 224 • IPSS:1-09-003</li>
                          <li>DIN 51 517 (Part 3)</li>
                          <li>DAVID BROWN S. 1. 53. 101</li>
                          <li>AGMA 250.04 5EP & AGMA 251.02 5EP</li>
                          <li>Micro pitting performance - Flender AG</li>
                        </ul>
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(1)}
                    >
                      <h4>Applications</h4>
                      <span>{subActiveIndex === 1 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 1 && (
                      <div className="sub-accordion-body">
                        Used for the lubrication of all types of industrial
                        enclosed gear drives operating at high load & speed
                        conditions. The need of such gear oil increases wherever
                        there is possibility of shock loads as in case of
                        enclosed spur, helical, herring bone, bevel and
                        planetary gearboxes.
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(2)}
                    >
                      <h4>Benefits</h4>
                      <span>{subActiveIndex === 2 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 2 && (
                      <div className="sub-accordion-body">
                        Premium quality EP type industrial gear oil, which meets
                        Flender’s requirement for anti-micro pitting gear oil to
                        take care of shock loads.
                        <br></br>
                        Major Approvals:
                        <br></br>
                        <ul>
                          <li>
                            Kyros Amocam Plus 320 - Bhushan Steel for Gear box
                            lubrication in the gear box of their uncoiler and
                            Walchandnagar Inds for their Cement Division.
                          </li>
                          <li>
                            Kyros Amocam Plus 460 - Walchandnagar Inds, Cement
                            Division.
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="accordion-item">
            <div
              className="accordion-header"
              onClick={() => handleAccordionClick(26)}
            >
              <h3>Kyros Egnol</h3>
              <span>{activeIndex === 26 ? "-" : "+"}</span>
            </div>
            {activeIndex === 26 && (
              <div className="accordion-body">
                High viscosity mineral oils for industrial gears
                <br></br>
                Kyros Engol is a range of premium quality industrial gear
                lubricants blended from high viscosity index solvent refined
                high quality base oils. The high load carrying capacity and
                anti-friction property of these oils offer excellent performance
                in industrial gears and other industrial applications. They
                offer extra protection to the gear teeth, bearings and seals in
                order to handle severe stresses that occur in industrial gears.
                <div className="sub-accordion">
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(0)}
                    >
                      <h4>Specifications</h4>
                      <span>{subActiveIndex === 0 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 0 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>IS 1589 : 1994 (Type 1) [Reaffirmed 2001]</li>
                        </ul>
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(1)}
                    >
                      <h4>Applications</h4>
                      <span>{subActiveIndex === 1 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 1 && (
                      <div className="sub-accordion-body">
                        These are recommended for bath lubricated gears, which
                        operate at low speeds and under normal loads for which
                        oils of high viscosity are required.
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(2)}
                    >
                      <h4>Benefits</h4>
                      <span>{subActiveIndex === 2 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 2 && (
                      <div className="sub-accordion-body">
                        Straight mineral oils having enhanced lubricating
                        properties and thermal stability.
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="accordion-item">
            <div
              className="accordion-header"
              onClick={() => handleAccordionClick(9)}
            >
              <h3>Kyros Syngear</h3>
              <span>{activeIndex === 9 ? "-" : "+"}</span>
            </div>
            {activeIndex === 9 && (
              <div className="accordion-body">
                Advanced synthetic oils for heavy duty industrial gears
                <br></br>
                Kyros Syngear is a range of fully synthetic gear oils with
                outstanding lubrication performance. They are formulated from
                special quality wax-free polyalphaolefin (PAO) synthetic base
                stocks that have exceptional oxidation resistance and thermal
                properties, high viscosity index and excellent low temperature
                fluidity. Their high load carrying capacity and anti-friction
                property offer excellent performance in industrial gears and
                other industrial applications. The high viscosity index and low
                traction coefficient of these oils combine to provide a
                potential reduction in power consumption in many gear systems.
                <div className="sub-accordion">
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(0)}
                    >
                      <h4>Specifications</h4>
                      <span>{subActiveIndex === 0 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 0 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>U.S. Steel 224</li>
                          <li>AGMA 9005-E-02</li>
                          <li>DIN 51517 Part 3</li>
                          <li>Micro-pitting performance - Flender AG</li>
                          <li>David Brown S1.53.101 Type E</li>
                          <li>FAG FE8 test</li>
                        </ul>
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(1)}
                    >
                      <h4>Applications</h4>
                      <span>{subActiveIndex === 1 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 1 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>
                            {" "}
                            Heavily loaded enclosed spur, helical, herringbone,
                            bevel and planetary gearboxes.
                          </li>
                          <li>
                            Application where the OEMs recommends synthetic
                            extreme pressure gear oil.
                          </li>
                          <li>
                            Industrial gear drives where full extreme pressure
                            and micro-pitting performance due to heavy / shock
                            loading is needed.
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(2)}
                    >
                      <h4>Benefits</h4>
                      <span>{subActiveIndex === 2 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 2 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li> High level anti-wear performance</li>
                          <li>Extreme pressure property</li>
                          <li>Micro-pitting performance</li>
                          <li>Excellent oxidation & thermal stability</li>
                          <li>Good low temperature properties</li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="accordion-item">
            <div
              className="accordion-header"
              onClick={() => handleAccordionClick(10)}
            >
              <h3>Kyros Syngear PG</h3>
              <span>{activeIndex === 10 ? "-" : "+"}</span>
            </div>
            {activeIndex === 10 && (
              <div className="accordion-body">
                Kyros SYNGEAR PG 320 is a synthetic gear lubricant based on the
                Polyalkylene Glycol base fluid and selected additives.
                <br></br>
                Polyalkylene Glycols (PAGs) are homopolymers of ethylene oxide
                or propylene oxide, or copolymers of ethylene oxide or propylene
                oxide. Because of the unique structure, PAG’s possess a low
                friction coefficient. Polyglycolbased gear oils offer
                significant advantages over mineral oils when a lot of sliding
                friction occurs in gearboxes and lowers oil temperatures that in
                turn extend oil change intervals. PAG based gear oils are used
                whenever mineral gear oils have reached their performance limit
                and can no longer meet the application requirements
                <div className="sub-accordion">
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(1)}
                    >
                      <h4>Applications</h4>
                      <span>{subActiveIndex === 1 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 1 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>
                            PAG gear oils are recommended for highly stressed
                            crown, bevel, planetary and worm drive gears
                          </li>
                          <li>
                            Poly glycol based gear oils offer significant
                            advantages over mineral oils when a lot of sliding
                            friction occurs in gearboxes and lowers oil
                            temperatures that in turn extend oil change
                            intervals.
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(2)}
                    >
                      <h4>Benefits</h4>
                      <span>{subActiveIndex === 2 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 2 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>High level anti-wear performance</li>
                          <li>Extreme pressure property</li>
                          <li>Micro-pitting performance</li>
                          <li>Excellent oxidation & thermal stability</li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* {Fourth} */}
      <div className="auto-lubricant">
        <div className="auto-lubricant-text">
          <h2>Grease</h2>
          <img
            src="https://www.bharatpetroleum.in/images/220x220-GREASE.jpg"
            alt=""
          />
        </div>
        <div className="accordion">
          <div className="accordion-item">
            <div
              className="accordion-header"
              onClick={() => handleAccordionClick(11)}
            >
              <h3>Compressor Oil 150</h3>
              <span>{activeIndex === 11 ? "-" : "+"}</span>
            </div>
            {activeIndex === 11 && (
              <div className="accordion-body">
                Superior quality reciprocating air compressor oil
                <br></br>
                Kyros Compressor Oils are high quality, high performance mineral
                oils developed for reciprocating air compressors. They are
                formulated from highly refined, high viscosity index base stocks
                and high performance additives with ashless chemistry.
                Reciprocating compressors operate at high temperatures and
                pressures. The lubricant creates a strong film in order to avoid
                surface wear. These oils have exceptional resistance to
                oxidation and thermal degradation.
                <div className="sub-accordion">
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(0)}
                    >
                      <h4>Specification</h4>
                      <span>{subActiveIndex === 0 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 0 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>DIN 51 506 - VDL</li>
                          <li>ISO / DP 6521</li>
                          <li>
                            IS 13256:1992 DAA & DAB type [Reaffirmed 1998]
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(1)}
                    >
                      <h4>Applications</h4>
                      <span>{subActiveIndex === 1 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 1 && (
                      <div className="sub-accordion-body">
                        For high performance reciprocating & rotary air
                        compressors where air discharge temperatures may go up
                        to 220°C.
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(2)}
                    >
                      <h4>Benefits</h4>
                      <span>{subActiveIndex === 2 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 2 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>
                            Longer Oil Life- This oil has strong oxidation
                            resistance and hence is less prone to degradation
                          </li>

                          <li>
                            Lower Maintenance costs: oil has lesser tendency to
                            form sludge & varnish and hence it provides trouble
                            free operation and lower maintenance costs Improved
                            Compressor performance: Due to low carbon forming
                            tendency, deposit forming tendency of oil is
                            reduced. This provides improved valve operation and
                            reduced deposits in lines.
                          </li>
                          <li>
                            Extended maintenance intervals: due to reduced
                            deposit formation on valves, the maintenance
                            intervals for valves and other components increase.
                          </li>
                        </ul>
                        Major Approvals:
                        <br></br>
                        <ul>
                          <li>ELGI Equipment Ltd.</li>
                          <li>
                            RDSO for use in Expressers / Compressors on diesel
                            and electric locomotives of Indian Railways.
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="accordion-item">
            <div
              className="accordion-header"
              onClick={() => handleAccordionClick(12)}
            >
              <h3>Kyros Egnol J 680</h3>
              <span>{activeIndex === 12 ? "-" : "+"}</span>
            </div>
            {activeIndex === 12 && (
              <div className="accordion-body">
                High viscosity compounded oils for industrial gears
                <br></br>
                Kyros Engol J is a range of premium quality industrial gear
                lubricants blended from high viscosity index solvent refined
                high quality base oils and fatty oils. The fatty oil content
                allows for excellent wettability and the ability to work in high
                moisture conditions. The high load carrying capacity and
                anti-friction property of these oils offer excellent performance
                in industrial gears and other industrial applications.
                <div className="sub-accordion">
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(0)}
                    >
                      <h4>Specification</h4>
                      <span>{subActiveIndex === 0 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 0 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>IS 1589:1994 (Type 2) [Reaffirmed 2001]</li>
                          <li>IPSS:1-09-010</li>
                          Our grades meet above specification for the respective
                          Viscosity Grades.
                        </ul>
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(1)}
                    >
                      <h4>Applications</h4>
                      <span>{subActiveIndex === 1 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 1 && (
                      <div className="sub-accordion-body">
                        Lubrication in gear boxes, calendar bearings and roller
                        bearings in Sugar Mills; where high viscosity and
                        compounded type of oils are required. These oils are
                        also suitable for lubrication of worm gears where
                        Sulphur/Phosphorus type EP additives are not recommended
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(2)}
                    >
                      <h4>Benefits</h4>
                      <span>{subActiveIndex === 2 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 2 && (
                      <div className="sub-accordion-body">
                        These oils are compounded with fatty oils to provide
                        good wet ability having excellent oiliness and film
                        strength properties.
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="accordion-item">
            <div
              className="accordion-header"
              onClick={() => handleAccordionClick(13)}
            >
              <h3>Kyros Hitemp Xtra Grease</h3>
              <span>{activeIndex === 13 ? "-" : "+"}</span>
            </div>
            {activeIndex === 13 && (
              <div className="accordion-body">
                Premium non-soap based grease for high temperature application
                <br></br>
                Kyros Hitemp Xtra Grease is a premium quality clay thickened
                non-soap base smooth structured grease formulated using superior
                quality mineral base oil and high performance additives. Kyros
                Hitemp Xtra Grease is characterised by a very high dropping
                point, outstanding oxidation stability, and excellent water
                resistance property. It also offers good protection against rust
                and corrosion. The grease is fortified with 3% molybdenum
                disulphide (MoS2) as solid lubricant for protection of moving
                parts under severe shock load.
                <div className="sub-accordion">
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(0)}
                    >
                      <h4>Specifications</h4>
                      <span>{subActiveIndex === 0 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 0 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>IS 12790 : 1999 (Grade 2)</li>
                        </ul>
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(1)}
                    >
                      <h4>Applications</h4>
                      <span>{subActiveIndex === 1 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 1 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>
                            This is used in the lubrication of machine elements,
                            plain bearing and anti-friction bearings operating
                            at high temperatures.
                          </li>
                          <li>
                            Recommended in lubrication in various steel plant
                            applications where the components have to work under
                            high temperatures
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(2)}
                    >
                      <h4>Benefits</h4>
                      <span>{subActiveIndex === 2 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 2 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>
                            High Drop point-With a drop point of greater than
                            250 °C, it is well suited to lubricate components
                            efficiently under high temperature conditions also
                          </li>
                          <li>
                            Good Oxidation Stability- Gives enhanced life to the
                            grease lubricant and minimum changing intervals
                          </li>
                          <li>
                            Excellent Shock Load Carrying abilities- Due to
                            presence of MoS2 in the formulation, the grease has
                            good shock loading carrying ability, making it
                            suitable to protect and lubricate the machine
                            components even under intermittent loads
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="accordion-item">
            <div
              className="accordion-header"
              onClick={() => handleAccordionClick(27)}
            >
              <h3>Kyros Lanthax EP Grease 2</h3>
              <span>{activeIndex === 27 ? "-" : "+"}</span>
            </div>
            {activeIndex === 27 && (
              <div className="accordion-body">
                Premium quality extreme pressure grease for automotive and
                industrial application
                <br></br>
                Kyros Lanthax EP Grease is a premium quality lithium based
                grease containing extreme pressure additives. It is formulated
                with high quality mineral base oils and lithium soap thickener.
                This grease is suitable for situations warranting the use of
                extreme pressure grease. Kyros Lanthax EP Grease is designed to
                handle the most severe loads in a wide range of automotive and
                industrial applications. The grease has a recommended operating
                temperature range from -20O C to 130O C. Kyros Lanthax EP Grease
                has high resistance to mechanical breakdown, excellent oxidation
                resistance, good rust protection, and excellent water washout
                resistance.
                <div className="sub-accordion">
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(0)}
                    >
                      <h4>Specifications</h4>
                      <span>{subActiveIndex === 0 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 0 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li> IS 7623 : 1993 EP Type [Reaffirmed 2001]</li>
                          <li>IPSS : 1-09-005-99</li>
                        </ul>
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(1)}
                    >
                      <h4>Applications</h4>
                      <span>{subActiveIndex === 1 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 1 && (
                      <div className="sub-accordion-body">
                        This grease is recommended for all plain & roller
                        bearings in equipment operating under severe shock
                        loading conditions and temperatures up to about 130°C
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(2)}
                    >
                      <h4>Benefits</h4>
                      <span>{subActiveIndex === 2 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 2 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>
                            Good Oxidation Stability- Gives enhanced life to the
                            grease lubricant and minimum changing intervals
                          </li>
                          <li>
                            Excellent Load Carrying abilities- this grease has
                            good extreme pressure properties, making it suitable
                            to protect and lubricate the machine components even
                            under high loads
                          </li>
                          <li>
                            Good Shear Stability- Provides grease with extra
                            ability to resist early shear and work longer during
                            harsh working conditions
                          </li>
                          <li>
                            Heat and water resistant-The grease has good water
                            resistant properties and recommended to use upto
                            +130°C
                          </li>
                          <li>
                            Excellent corrosion resistant - Enhances the service
                            life of equipment even under harsh conditions.
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* Fifth */}
      <div className="auto-lubricant">
        <div className="auto-lubricant-text">
          <h2>Mobile Engine</h2>
          <img
            src="https://www.bharatpetroleum.in/images/220x220-AUTOMOTIVE-ENGINE-OIL.jpg"
            alt=""
          />
        </div>
        <div className="accordion">
          <div className="accordion-item">
            <div
              className="accordion-header"
              onClick={() => handleAccordionClick(14)}
            >
              <h3>Kyros CD</h3>
              <span>{activeIndex === 14 ? "-" : "+"}</span>
            </div>
            {activeIndex === 14 && (
              <div className="accordion-body">
                High performance oil for engine, transmission, hydraulic systems
                and final drives
                <br></br>
                Kyros CD 40 is a high performance oil formulated with very high
                quality base oils and advanced additive technology. It is
                uniquely designed to optimise the performance of engine,
                transmission, hydraulic systems and drive trains. The oil offers
                enhanced protection to hydraulic system components even in high
                pressure systems.
                <div className="sub-accordion">
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(0)}
                    >
                      <h4>Specifications</h4>
                      <span>{subActiveIndex === 0 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 0 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>API CD/SC</li>
                          <li>IS 13656:2002 Type EDL2 & EPL1</li>
                          <li>UK DEF 2101D</li>
                        </ul>
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(1)}
                    >
                      <h4>Applications</h4>
                      <span>{subActiveIndex === 1 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 1 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>Onhighway</li>
                          <li>Construction</li>
                          <li>Earth Moving equipment</li>
                        </ul>
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(2)}
                    >
                      <h4>Benefits</h4>
                      <span>{subActiveIndex === 2 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 2 && (
                      <div className="sub-accordion-body">
                        <br></br>
                        <ul>
                          <li>Excellent Thermal stability</li>
                          <li>Good oxidation and thermal stability</li>
                          <li>
                            The multi grade version is suitable for all seasons
                            and helps in reducing oil consumption.
                          </li>
                          <li>Minimizes engine deposits.</li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="accordion-item">
            <div
              className="accordion-header"
              onClick={() => handleAccordionClick(15)}
            >
              <h3>Kyros Diamond</h3>
              <span>{activeIndex === 15 ? "-" : "+"}</span>
            </div>
            {activeIndex === 15 && (
              <div className="accordion-body">
                Multigrade diesel engine oil with API CF-4 performance
                <br></br>
                Kyros Diamond 15W-40 is a heavy duty diesel engine oil blended
                from highly refined high viscosity index Group II plus base
                stocks. It is formulated to perform under the most stringent
                operating condition for heavy duty diesel engines. It offers
                outstanding protection against oil degradation, oil thickening
                and corrosion.
                <div className="sub-accordion">
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(0)}
                    >
                      <h4>Specifications</h4>
                      <span>{subActiveIndex === 0 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 0 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li> API CF-4</li>
                          <li>MB 228.1</li>
                        </ul>
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(1)}
                    >
                      <h4>Applications</h4>
                      <span>{subActiveIndex === 1 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 1 && (
                      <div className="sub-accordion-body">
                        Recommended for all type of diesel passenger cars,
                        commercial vehicles and earth moving machinery. It is
                        specially recommended for use in vehicles meeting BS I &
                        II norms
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(2)}
                    >
                      <h4>Benefits</h4>
                      <span>{subActiveIndex === 2 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 2 && (
                      <div className="sub-accordion-body">
                        <br></br>
                        <ul>
                          <li>
                            Excellent protection against wear and tear of
                            critical engine parts even under severe operating
                            conditions reduce maintenance costs
                          </li>
                          <li>
                            Enhanced oxidation stability reduces engine deposit
                            and keeps engine parts clean
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="accordion-item">
            <div
              className="accordion-header"
              onClick={() => handleAccordionClick(16)}
            >
              <h3>Kyros Diamond</h3>
              <span>{activeIndex === 16 ? "-" : "+"}</span>
            </div>
            {activeIndex === 16 && (
              <div className="accordion-body">
                High performance heavy duty diesel engine oil
                <br></br>
                Kyros Diamond Plus 15W-40 is a superior quality heavy duty
                diesel engine oil. It is developed to meet the lubrication
                requirements of turbocharged and naturally aspirated heavy duty
                diesel engines operating under challenging conditions. This oil
                is formulated with high quality severely hydro processed Group
                II plus base oils and highly effective performance additives
                <div className="sub-accordion">
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(0)}
                    >
                      <h4>Specifications</h4>
                      <span>{subActiveIndex === 0 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 0 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>API CF-4</li>
                          <li>MB 228.3</li>
                        </ul>
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(1)}
                    >
                      <h4>Applications</h4>
                      <span>{subActiveIndex === 1 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 1 && (
                      <div className="sub-accordion-body">
                        Recommended for use in BS I, BS II, & BS III heavy duty
                        trucks, buses, LCVs of TATA Motors, ASHOK LEYLAND,
                        MAHINDRA, VOLVO, SWARAJ MAZDA, EICHER etc
                      </div>
                    )}
                  </div>
                  <div className="sub-accordion-item">
                    <div
                      className="sub-accordion-header"
                      onClick={() => handleSubAccordionClick(2)}
                    >
                      <h4>Benefits</h4>
                      <span>{subActiveIndex === 2 ? "-" : "+"}</span>
                    </div>
                    {subActiveIndex === 2 && (
                      <div className="sub-accordion-body">
                        <ul>
                          <li>
                            Excellent lubrication makes the engine run smoother.
                          </li>
                          <li>
                            Reduces soot related oil thickening and engine wear,
                            even under heavy loading conditions and ensures
                            reduced maintenance and operating costs.
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default LunInfra;
