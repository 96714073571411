import React, { useEffect } from "react";

function LubCenter() {
  useEffect(() => {
    window.scroll(0, 0);
  });
  return (
    <div className="lubricant-container">
      <div className="lubricant">
        <h2 className="primary-subheading">Kyros QOC Centre</h2>
        <p className="primary-subheading">Installing Trust in cunsumers</p>
        <img
          src="https://www.bharatpetroleum.in/images/900x420-SS-1013.png"
          alt=""
        />
        <p className="primary-information">
          Kryos Quik Oil Change centers, facilitates oil change right in the
          presence of customer. Such unique initiatives, where product is
          drained and replaced in presence of the customer, instill trust in the
          services rendered. Regular under-bonnet services are provided at our
          retail outlets, assisting customers to maintain optimum coolant and
          oil levels, through regular checks and top-ups.
        </p>
      </div>
    </div>
  );
}

export default LubCenter;
