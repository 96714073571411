import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import ferrous from "../Assets/ferrous.jpg";
import nonferrous from "../Assets/non-ferrous.jpg";

function Scrap() {
  const ferrousref = useRef(null);
  const nonferrousref = useRef(null);
  const location = useLocation();
  const hms = [
    {
      name: "HMS",
      img: "https://i.ibb.co/9pgLR4C/123494332-414731399547790-8286357342794672625-n.jpg",
    },

    {
      name: "HMS",
      img: "https://jansengroup.com/wp-content/uploads/2018/05/3B-kort-hoop-700x523.jpg",
    },
    // {
    //   name: "HMS",
    //   img: "https://jansengroup.com/wp-content/uploads/2018/05/HMS-12-700x467.jpg",
    // },
  ];
  const lms = [
    {
      name: "LMS",
      img: "https://3.imimg.com/data3/FV/UY/MY-2071160/light-melting-scrap-lms-500x500.jpg",
    },
    {
      name: "LMS",
      img: "https://cosmosgreenfze.com//wp-content/uploads/2017/09/LMS-HMS-Bundles.jpg",
    },
    // {
    //   name: "Light Metal Scrap",
    //   img: "https://5.imimg.com/data5/HV/KY/GR/SELLER-13172587/lms-and-hms-loose-iron-scrap--1000x1000.jpg",
    // },
  ];
  const cast = [
    {
      name: "Cast Iron Scrap",
      img: "https://5.imimg.com/data5/GLADMIN/Default/2022/6/VR/SO/CK/92368/cast-iron-scraps-500x500.jpg",
    },
    {
      name: "Cast Iron Scrap",
      img: "https://5.imimg.com/data5/BC/VX/MY-12203386/non-ferrous-cast-iron-scrap-500x500.jpg",
    },
    // {
    //   name: "Cast Iron Scrap",
    //   img: "https://static.connect2india.com/c2icd/product_resources/images/scrap-cast-iron.jpg",
    // },
  ];
  const alumiwire = [
    {
      name: "Aluminium Wire",
      img: "https://i.ibb.co/pwByk6C/120289215-611703219523202-5001033847973029835-n.jpg",
    },
    {
      name: "Aluminium Wire",
      img: "https://img1.exportersindia.com/product_images/bc-full/2022/1/9747151/aluminum-wire-scrap-1641501967-6147009.jpeg",
    },
    // {
    //   name: "ALuminium Wire",
    //   img: "https://www.lansgrupo.com/wp-content/uploads/2018/07/Grupo-Lan-aluminum-wire-scrap-008.jpg",
    // },
    // {
    //   name: "ALuminium Wire Scrap",
    //   img: "https://www.lansgrupo.com/wp-content/uploads/2018/07/Grupo-Lan-aluminum-wire-scrap-008.jpg",
    // },
  ];
  const alumisheet = [
    {
      name: "Aluminum Sheet",
      img: "https://5.imimg.com/data5/VH/NQ/MY-22778557/aluminium-sheet-scrap-500x500.png",
    },
    {
      name: "Aluminum Sheet",
      img: "https://5.imimg.com/data5/SELLER/Default/2021/12/CV/TM/QM/143262976/aluminium-patra-500x500.jpg",
    },
    // {
    //   name: "Aluminum Sheet",
    //   img: "https://5.imimg.com/data5/SELLER/Default/2022/4/HO/ZW/QB/112774696/aluminum-sheet-scrap-500x500.jpeg",
    // },
  ];
  const alumiextru = [
    {
      name: "Aluminium Extrusion",
      img: "https://i.ibb.co/ysXHNgL/96674492-3294743193921138-764962914336234696-n.jpg",
    },
    {
      name: "Aluminium Extrusion",
      img: "https://i.ibb.co/qgZsrsN/36085507-243799239757647-7013933382530760704-n.jpg",
    },
    // {
    //   name: "Aluminium Extrusion",
    //   img: "https://paschagmbh.com/wp-content/uploads/2020/01/aluminum-extrusion-6063-scrap.jpg",
    // },
  ];
  const alumiubc = [
    {
      name: "Aluminium UBC",
      img: "https://5.imimg.com/data5/GLADMIN/Default/2022/7/YK/OL/JU/92368/aluminum-ubc-scrap-500x500.png",
    },
    {
      name: "Aluminium UBC",
      img: "https://sc04.alicdn.com/kf/U87bacfed92654234b7a86f97b1e3c582B.jpg",
    },
  ];
  const alumibor = [
    {
      name: "Aluminium Turning",
      img: "https://4.imimg.com/data4/XD/MG/ANDROID-3895979/product-500x500.jpeg",
    },
    {
      name: "Aluminium Turning",
      img: "https://5.imimg.com/data5/SELLER/Default/2020/12/FK/CW/YX/12920881/aluminum-turnings-scrap-500x500.jpg",
    },
  ];
  useEffect(() => {
    if (location.hash === "#scrollferrous") {
      ferrousref.current.scrollIntoView({ behaviour: "smooth" });
    }
    if (location.hash === "#scrollnonferrous") {
      nonferrousref.current.scrollIntoView({ behaviour: "smooth" });
    }
  }, [location]);
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <div>
      <div className="work-section-wrapper">
        <div className="work-section-top">
          <h1 className="primary-mainheading">Metal Scrap</h1>
          <br></br>
          <h1 className="primary-heading" ref={ferrousref} id="scrollferrous">
            Ferrous Scrap
          </h1>
        </div>
        <div className="metal-image">
          <img src={ferrous} alt="Metal Scrap" border="0" />
        </div>

        <div className="work-section-top">
          <h2 className="primary-heading">HMS (Heavy Melting Scrap)</h2>
        </div>
        <div className="fruit-container">
          <div className="intro-text">
            <div className="fruit-section-bottom">
              {hms.map((item) => (
                <div
                  className="fruit-section-info"
                  // key={item.name}
                  style={{ backgroundImage: `url(${item.img})` }}
                >
                  <h1>{item.name}</h1>
                  <p>{item.text}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="work-section-top">
          <p>
            HMS includes ferrous scrap, such as plate and structural steel,
            machinery scrap, and miscellaneous industrial scrap. the
            specifications for hms typically include a maximum size limit and a
            minimum weight requirement.
          </p>

          <p>
            Recycling hms scrap helps reduce the environmental impact of
            industrial activities while conserving natural resources.
          </p>

          <div className="metal-content-bottom">
            <b>MOQ: </b>5 x 40ft FCL
            <br></br>
            <b>Loading per 40ft HC FCL: </b>
            25-28MT
          </div>
        </div>
        <div className="line-dividing-metal" />

        <div className="work-section-top">
          <h2 className="primary-heading">LMS (Light Melting Scrap)</h2>
        </div>
        <div className="fruit-container">
          <div className="intro-text">
            <div className="fruit-section-bottom">
              {lms.map((item) => (
                <div
                  className="fruit-section-info"
                  // key={item.name}
                  style={{ backgroundImage: `url(${item.img})` }}
                >
                  <h1>{item.name}</h1>
                  <p>{item.text}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="work-section-top">
          <p>
            LMS includes lighter ferrous scrap such as sheet metal, roofing
            iron, and other thin-gauge material. lms typically has a lower
            minimum weight requirement than hms.
          </p>

          <p>
            Lms scrap is a valuable source of raw material for the manufacturing
            of new steel products."one liner for lms: "lms scrap is a valuable
            source of raw material for the manufacturing of new steel products.
          </p>

          <div className="metal-content-bottom">
            <b> MOQ: </b>5 x 40ft FCL
            <br></br>
            <b>Loading per 40ft HC FCL: </b>
            25-28MT
          </div>
        </div>
        <div className="line-dividing-metal" />

        <div className="work-section-top">
          <h2 className="primary-heading">Cast Iron Scrap</h2>
        </div>
        <div className="fruit-container">
          <div className="intro-text">
            <div className="fruit-section-bottom">
              {cast.map((item) => (
                <div
                  className="fruit-section-info"
                  // key={item.name}
                  style={{ backgroundImage: `url(${item.img})` }}
                >
                  <h1>{item.name}</h1>
                  <p>{item.text}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="work-section-top">
          <p>
            This type of scrap is derived from cast iron products such as pipes,
            valves, and machinery parts. cast iron scrap is typically classified
            according to its grade, which is determined by its carbon content,
            silicon content, and other factors.
            <br></br>
            Types of cast iron scrap: The main types of cast iron scrap include
            gray iron, ductile iron, and malleable iron. each type has different
            properties and is used for different applications.
          </p>

          <p>
            Cast iron scrap is a key component of the circular economy, as it
            can be recycled into new products again and again."
          </p>

          <div className="metal-content-bottom">
            <b> MOQ: </b>5 x 40ft FCL
            <br></br>
            <b>Loading per 40ft HC FCL: </b>
            25-28MT
          </div>
        </div>
      </div>
      <div className="line-dividing-metal" />

      <div className="work-section-wrapper">
        <div className="work-section-top">
          <h1
            className="primary-heading"
            ref={nonferrousref}
            id="scrollnonferrous"
          >
            Non-Ferrous Scrap
          </h1>
        </div>
        <div className="metal-image">
          <img
            src={nonferrous}
            alt="Glassmorphism-Zero-Waste-Webinar-Instagram-Post-1"
            border="0"
          />
        </div>

        <div className="work-section-top">
          <h2 className="primary-heading">Aluminum Wire Scrap / TALON Scrap</h2>
        </div>
        <div className="fruit-container">
          <div className="intro-text">
            <div className="fruit-section-bottom">
              {alumiwire.map((item) => (
                <div
                  className="fruit-section-info"
                  // key={item.name}
                  style={{ backgroundImage: `url(${item.img})` }}
                >
                  <h1>{item.name}</h1>
                  <p>{item.text}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="work-section-top">
          <p>
            One of the most valuable and highly paid grades of Aluminum Scrap is
            Aluminum Wire Scrap. Aluminum Wire Scrap is identified by its shiny
            and silvery appearance. Moreover, Aluminum Wire Scrap is bare, clean
            and unalloyed, containing 99% Aluminum content, and falls under the
            ISRI Code "TALON" and "TANN" category.
          </p>

          <div className="metal-content-bottom">
            <b> MOQ: </b>2 x 40ft FCL
            <br></br>
            <b>Loading per 40ft HC FCL: </b>
            25-28MT
          </div>
        </div>
        <div className="line-dividing-metal" />

        <div className="work-section-top">
          <h2 className="primary-heading">Aluminum Extrusion Scrap</h2>
        </div>
        <div className="fruit-container">
          <div className="intro-text">
            <div className="fruit-section-bottom">
              {alumiextru.map((item) => (
                <div
                  className="fruit-section-info"
                  // key={item.name}
                  style={{ backgroundImage: `url(${item.img})` }}
                >
                  <h1>{item.name}</h1>
                  <p>{item.text}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="work-section-top">
          <p>
            The second most valuable and commonly found grade of Aluminum Scrap
            is Aluminum Extrusion Scrap. Aluminum Extrusion Scrap is clean and
            unalloyed, containing 97% to 99% Aluminum content and available in
            the form of Aluminum 6063 Extrusion Scrap (ISRI Code "TATA"),
            Painted Aluminum Extrusion Scrap (ISRI Code "TOTO") and Aluminum
            6061/7075 Extrusion Scrap (ISRI Code "TUTU").
            <br></br>
            <br></br>
            <p className="sub-sub-primary-heading">
              1) Aluminum 6063 Extrusion Scrap (ISRI Code "TATA") Aluminum
              Extrusion Scrap - Grades of Aluminum Scrap
            </p>
            Aluminum 6063 Extrusion Scrap is the most valuable type of Aluminum
            Extrusion Scrap. Suppose if you are renovating an old house or
            office. In that case, you can collect Aluminum 6063 Extrusion Scrap
            from door frames, pipe/tube fittings, window profile frames/window
            screens, wall panels, roofs, sign frames and other architectural
            appliances. Aluminum Extrusion Scrap can also be sourced building
            and demolition sites.
          </p>

          <div className="metal-content-bottom">
            <b> MOQ: </b>2 x 40ft FCL
            <br></br>
            <b>Loading per 40ft HC FCL: </b>
            25-28MT
          </div>

          <br></br>
          <br></br>
          <p className="sub-sub-primary-heading">
            2) Aluminum 6061/7075 Extrusion Scrap (ISRI Code "TUTU")
          </p>

          <p>
            Aluminum 6061/7075 Extrusion Scrap is the second most valuable grade
            of Aluminum Extrusion Scrap. It can be scrapped from the chassis of
            bicycles, airplanes, automobiles, boats, trucks, railway and subway
            cars, vehicle panels and other automobile parts.
            <br></br>
            Painted Aluminum Extrusion Scrap - Grades of Aluminum Scrap
            <br></br>
            If you encounter painted Aluminum Extrusion Scrap while scrapping
            Aluminum extrusion, keep it separate because it falls under the
            category of ISRI Code "TOTO". Painted Aluminum Extrusion Scrap is
            less expensive than the other two types of Aluminum Extrusion Scrap
            since it requires more refinement during processing.
          </p>

          <div className="metal-content-bottom">
            <b> MOQ: </b>2 x 40ft FCL
            <br></br>
            <b>Loading per 40ft HC FCL: </b>
            25-28MT
          </div>
        </div>
        <div className="line-dividing-metal" />

        <div className="work-section-top">
          <h2 className="primary-heading">
            Aluminum Sheet Scrap / TAINT/TABOR scrap:
          </h2>
        </div>
        <div className="fruit-container">
          <div className="intro-text">
            <div className="fruit-section-bottom">
              {alumisheet.map((item) => (
                <div
                  className="fruit-section-info"
                  // key={item.name}
                  style={{ backgroundImage: `url(${item.img})` }}
                >
                  <h1>{item.name}</h1>
                  <p>{item.text}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="work-section-top">
          <p>
            Aluminum Sheet Scrap is the third well-paid grade of Aluminum Scrap
            and is composed of unalloyed thin gauged Aluminum Scraps such as
            sheet Aluminum, gutters, wall roofing/panels, and downspouts.
            According to ISRI Scrap Specifications Circular 2020, Aluminum Sheet
            Scrap is categorized under the ISRI Code "TAINT/TABOR." If you are
            remodeling an old house or office, you can scrap Aluminum Sheets
            from the roofs and gutters. Also, you can collect Aluminum Sheet
            Scrap from construction and demolition sites.
            <br></br>
            What to do with painted Aluminum Sheets Scrap? Aluminum Sheets Scrap
            painted one or two sides is classified as ISRI code "TALE." Because
            of the amount of tar and paint on it, Aluminum Sheets Scrap is less
            expensive and is considered the second grade of Aluminum Sheet
            Scrap. It takes a long time to process and requires additional
            refining as more impurities are attached.
          </p>

          <div className="metal-content-bottom">
            <b> MOQ: </b>2 x 40ft FCL
            <br></br>
            <b>Loading per 40ft HC FCL: </b>
            25-28MT
          </div>
        </div>
        <div className="line-dividing-metal" />

        <div className="work-section-top">
          <h2 className="primary-heading">Aluminum UBC Scrap / TALC scrap</h2>
        </div>
        <div className="fruit-container">
          <div className="intro-text">
            <div className="fruit-section-bottom">
              {alumiubc.map((item) => (
                <div
                  className="fruit-section-info"
                  // key={item.name}
                  style={{ backgroundImage: `url(${item.img})` }}
                >
                  <h1>{item.name}</h1>
                  <p>{item.text}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="work-section-top">
          <p>
            One of the most commonly found and highly paid grades of Aluminum
            Scrap is Aluminum UBC Scrap. According to ISRI Scrap Specifications
            Circular 2020, Aluminum UBC Scrap is categorized under the “TALC.”
            <br></br>
            Soda cans, beverage cans, beer cans, soft drink cans, coca-cola
            cans, tuna cans, sardine cans, and other beverage cans are the most
            well-known sources of collecting Aluminum Beverage Cans Scrap and
            are wrapped of Aluminum. We probably buy at least one of these soft
            drinks daily. So instead of throwing away empty Aluminum cans, we
            can turn them into cash by collecting large quantities and selling
            them in scrap yards.
          </p>

          <div className="metal-content-bottom">
            <b> MOQ: </b>2 x 40ft FCL
            <br></br>
            <b>Loading per 40ft HC FCL: </b>
            25-28MT
          </div>
        </div>
        <div className="line-dividing-metal" />

        <div className="work-section-top">
          <h2 className="primary-heading">Aluminum Turnings and Borings</h2>
        </div>
        <div className="fruit-container">
          <div className="intro-text">
            <div className="fruit-section-bottom">
              {alumibor.map((item) => (
                <div
                  className="fruit-section-info"
                  // key={item.name}
                  style={{ backgroundImage: `url(${item.img})` }}
                >
                  <h1>{item.name}</h1>
                  <p>{item.text}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="work-section-top">
          <p>
            Aluminum Turnings and Borings are produced when processing unalloyed
            Aluminum Scrap with minimum Aluminum content of 97% to 99%.
            <br></br>
            Sources of Aluminum Turnings and Borings:
            <br></br>
            Aluminum Turnings and Borings can be collected from an Aluminum
            manufacturing/recycling facility. Aluminum Turnings/borings are
            generated while chopping or shredding any grade of Aluminum Scrap.
            These Aluminum Turnings and Borings have a nominal 96 to 99 percent
            Aluminum content and are classified as "TEENS" by the ISRI.
            Furthermore, the Aluminum Borings and Turnings must be free of
            contaminants.
            <br></br>
            Suppose the Aluminum Turnings are unclean and have certain metal
            impurities. In that case, you can sell them separately because they
            are less expensive and fall into the second grade of Aluminum
            Turnings as classified by ISRI Code "TELIC."
          </p>

          <div className="metal-content-bottom">
            <b> MOQ: </b>2 x 40ft FCL
            <br></br>
            <b>Loading per 40ft HC FCL: </b>
            25-28MT
          </div>
        </div>
        <br></br>
        <br></br>
      </div>
    </div>
  );
}

export default Scrap;
