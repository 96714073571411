import { Button, Stack } from "@mui/material";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";

function Recycle() {
  useEffect(() => {
    window.scroll(0, 0);
  });
  return (
    <div>
      <div className="work-section-wrapper">
        <div className="work-section-top">
          <p className="primary-mainheading">Metal Scrap</p>

          <p className="work-spices-text">
            A global leader in metal import and export, connecting industries
            worldwide. We source high-quality metals and deliver tailored
            solutions for diverse applications. Trust, reliability, and
            excellence are at the core of our operations.
          </p>
        </div>
        <div className="spices-button">
          <Stack spacing={2} direction="row">
            <Button variant="contained">
              <Link to="/scrap" className="link-products">
                Explore More
              </Link>
            </Button>
          </Stack>
        </div>
        <br></br>
        <hr className="line-dividing"></hr>
        <br></br>
        <br></br>
        <div className="work-section-top">
          <p className="primary-mainheading">Paper Scrap</p>

          <p className="work-spices-text">
            This company is a trusted global leader in the paper products. They
            prioritize sustainability, offering a wide range of responsibly
            sourced options. With their expertise and efficient logistics, they
            provide exceptional service and timely deliveries to businesses
            worldwide.
          </p>
        </div>
        <div className="spices-button">
          <Stack spacing={2} direction="row">
            <Button variant="contained">
              <Link to="/paper" className="link-products">
                Explore More
              </Link>
            </Button>
          </Stack>
        </div>
        <br></br>
        <hr className="line-dividing"></hr>
        <br></br>
        <br></br>
        <div className="work-section-top">
          <p className="primary-mainheading">E-Waste</p>

          <p className="work-spices-text">
            Our company specializes in recycling e-waste, diverting electronic
            products from landfills and promoting a circular economy. We employ
            responsible recycling practices to recover valuable materials and
            mitigate environmental impact.
          </p>
        </div>
        <div className="spices-button">
          <Stack spacing={2} direction="row">
            <Button variant="contained">
              <Link to="/ewaste" className="link-products">
                Explore More
              </Link>
            </Button>
          </Stack>
        </div>
      </div>
    </div>
  );
}

export default Recycle;
