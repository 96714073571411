/* eslint-disable import/no-anonymous-default-export */
import First from "../Assets/banner0c.jpg";
import Second from "../Assets/banner1c.jpg";
import Third from "../Assets/banner2c.jpg";
import Fourth from "../Assets/banner3c.jpg";
import Fifth from "../Assets/banner4c.jpg";
import Sixth from "../Assets/banner5c.jpg";
import Seventh from "../Assets/banner--1.jpg";
//import Eight from "../Assets/banners.png";
export default [
  {
    urls: First,
    text: "Innovative solutions for all your chemical and scrap needs.",
  },
  {
    urls: Second,
    text: "Your trusted source for quality chemicals and reliable scrap management.",
  },
  {
    urls: Third,
    text: "Transforming scrap into valuable resources for a sustainable future.",
  },
  {
    urls: Fourth,
    text: "Responsible recycling and processing for a better world.",
  },
  {
    urls: Fifth,
    text: "Partnering with you for a cleaner and greener tomorrow.",
  },
  {
    urls: Sixth,
    text: "Creating value from waste - it's what we do best.",
  },
  {
    urls: Seventh,
  },
  // {
  //   urls: Eight,
  // },
];
