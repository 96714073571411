import React, { useEffect } from "react";

function LubricantAutoMobile() {
  useEffect(() => {
    window.scroll(0, 0);
  });
  return (
    <div className="lubricant-container">
      <div className="lubricant">
        <br />
        <h2 className="primary-subheading">
          Revving Up Performance Using Advanced Chemistry
        </h2>
        <p className="primary-information">
          The journey of Kyros Lubricants is a voyage of vibrancy – in
          cutting-edge research and product development to fill every niche of
          the automotive segment. Striving to serve the evolving needs of this
          dynamic segment, we are always in pursuit of the next milestone –
          reaching a new level of performance.
        </p>
        <p className="primary-information">
          Serving the entire spectrum of the automobile segment – bikes,
          scooters, cars, light and heavy commercial vehicles, and tractors –
          Kyros Lubricants covers the wide range of engine oil, gear oils,
          greases and specialties comprehensively.
        </p>
        <p className="primary-information">
          Our new range of synthetic oils, leveraging cutting-edge research and
          development in advanced chemistry, increase the ability of the
          lubricants to keep engine parts slick, especially at high
          temperatures. The high-performance products help increase drain
          interval and minimize deposition of sludge, along with better
          performance throughout the operating range.
        </p>
      </div>
      <div className="images-information">
        <div className="images-container">
          <img
            src="https://www.bharatpetroleum.in/images/2-wheelers(1).jpg"
            alt=""
          />
          <h3>Bikes and Scooters</h3>
        </div>
        <div className="images-container">
          <img
            src="https://www.bharatpetroleum.in/images/275x255-auto-2.png"
            alt=""
          />
          <h3>Auto & CNG</h3>
        </div>
        <div className="images-container">
          <img src="https://www.bharatpetroleum.in/images/car(2).jpg" alt="" />
          <h3>Cars & SUVs</h3>
        </div>{" "}
        <div className="images-container">
          <img
            src="https://www.bharatpetroleum.in/images/Trucks-&-Buses.jpg"
            alt=""
          />
          <h3>Trucks & Buses</h3>
        </div>{" "}
        <div className="images-container">
          <img
            src="https://www.bharatpetroleum.in/images/Agriculture.jpg"
            alt=""
          />
          <h3>Agriculture</h3>
        </div>{" "}
        <div className="images-container">
          <img src="https://www.bharatpetroleum.in/images/Greases.jpg" alt="" />
          <h3>Greases</h3>
        </div>{" "}
        <div className="images-container">
          <img
            src="https://www.bharatpetroleum.in/images/Specialties.jpg"
            alt=""
          />
          <h3>Specialities</h3>
        </div>{" "}
        <div className="images-container">
          <img
            src="https://www.bharatpetroleum.in/images/Marine-Boats.jpg"
            alt=""
          />
          <h3>Marine Boats</h3>
        </div>{" "}
        <div className="images-container">
          <img
            src="https://www.bharatpetroleum.in/images/Gears-&-Transmission.jpg"
            alt=""
          />
          <h3>Gears & Transportation</h3>
        </div>{" "}
      </div>
    </div>
  );
}

export default LubricantAutoMobile;
