import React from "react";
// import { useSwipeable } from "react-swipeable";
// import { useSwipeable } from "react-swipeable";
// import SwipeableViews from "react-swipeable-views";

function SliderContent({ activeIndex, sliderImage }) {
  // const [currentIndex, setCurrentIndex] = useState(0);

  // const handleSwipe = (delta) => {
  //   if (delta < 0) {
  //     // Swiped to the left, move to the next slide
  //     const nextIndex = (currentIndex + 1) % sliderImage.length;
  //     setCurrentIndex(nextIndex);
  //   } else if (delta > 0) {
  //     // Swiped to the right, move to the previous slide
  //     const prevIndex = currentIndex === 0 ? sliderImage - 1 : currentIndex - 1;
  //     setCurrentIndex(prevIndex);
  //   }
  // };
  // const [currentIndex, setCurrentIndex] = useState(0);

  // const handleSwipe = (indexChange) => {
  //   const slideCount = sliderImage.length;
  //   const newIndex = (currentIndex + indexChange + slideCount) % slideCount;
  //   setCurrentIndex(newIndex);
  // };
  return (
    <section>
      {sliderImage.map((slide, index) => (
        <div
          key={index}
          className={index === activeIndex ? "slides active" : "inactive"}
        >
          <img className="slide-image" src={slide.urls} alt="" />
          {/* <h3 className="slide-text">{slide.text}</h3> */}
        </div>
      ))}
    </section>
  );
}

export default SliderContent;
