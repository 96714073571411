import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Industrial from "./Ind.jpg";
import Auto from "./Auto.jpg";
function Lubricants() {
  const lubricants = [
    // {
    //   name: "About",
    //   img: "https://coastalchem.com/wp-content/uploads/2020/07/lubricants-768x512.jpg",
    //   link: "/lubricant-about",
    //   text: "We offer best Lubricants – from Kyros Immpex Private Limited (KIPL) – reign eminently as a trusted brand in lubricants ,greases ,specialties or other materials in Indian and international markets.",
    // },
    {
      name: "Industrial Lubricants",
      img: Industrial,
      link: "/lubricant-industrial",
      text: "Kyros Lubricants are a trusted lubricant brand in the industrial segment, catering to specific requirements of textiles, power, sugar, steel, off-highway vehicles and many others industries.",
    },
    {
      name: "Automobile Lubricants",
      img: Auto,
      link: "/lubricant-automobile",
      text: "Serving the entire spectrum of the automobile segment – bikes, scooters, cars, light and heavy commercial vehicles – We have wide range of engine oil, gear oils, greases and specialties.",
    },

    // {
    //   name: "Infrastructure",
    //   img: "https://www.aiib.org/en/news-events/media-center/blog/2020/_img/AIIB-blog-header_Infrastructure-Embracing-Technology-1200px.jpg",
    //   link: "/lubricant-infra",
    //   text: "The four strategically located blending plants of Kyros Lubricants ensure availability of quality Kyros products across India market",
    // },
    // {
    //   name: "Connect",
    //   img: "https://cdn.britannica.com/63/126263-050-9EB6D2AC/Motor-oil-lubricant-automobile-engines.jpg",
    //   link: "/lubricant-connect",
    //   text: "Kyros Immpex is committed towards serving its customers in the best possible way by offering customer-friendly Fuel Stations across the length and breadth of the country.",
    // },
    // {
    //   name: "QOC Center",
    //   img: "https://5.imimg.com/data5/SELLER/Default/2022/10/DC/TX/TR/6144151/mak-qoc-center-250x250.jpg",
    //   link: "/lubricant-center",
    //   text: "Kyros Quik Oil Change centers, available at 6,000 KIPL fuel stations facilitate oil change right in the presence of customer.",
    // },
    // {
    //   name: "Partner with Kryos",
    //   img: "https://cdn.zaggle.in/images/web/zaggle/landing/webp/partner-with-us.webp",
    //   link: "/lubricant-partner",
    //   text: "All you need to know about being a Channel Partner with Kyros Lubricants.",
    // },
    // {
    //   name: "Profiency Testing",
    //   img: "https://www.charm.com/wp-content/uploads/2021/09/Featured-Image_EPIC-copy.png",
    //   link: "/lubricant-testing",
    //   text: "Kyros Immpex and Kyros Lubricants has 24 marketing laboratories spread across India. All these testing laboratories are equipped with the state-of-the-art test facilities armed with most modern equipment for calibration, testing, and validation.",
    // },
    // {
    //   name: "R&D Center",
    //   img: "https://www.sammobile.com/wp-content/uploads/2022/12/samsung-rd-center-vietnam.jpg",
    //   link: "/lubricant-rd",
    //   text: "Kyros R&D Centre, Mumbai, an epitome of cutting-edge innovation, is a state-of-the art facility with international and national accreditations to its credit.",
    // },
    // {
    //   name: "KryosEPedia",
    //   img: "https://leverageedublog.s3.ap-south-1.amazonaws.com/blog/wp-content/uploads/2020/05/08140736/Modes-of-Communication.png",
    //   link: "/lubricant-epedia",
    //   text: "Webinars on trends & development in Manufacturing Industries.",
    // },
  ];
  useEffect(() => {
    window.scroll(0, 0);
  });
  return (
    <div>
      <div className="intro-container">
        <div className="intro-text">
          <div className="intro">
            <div className="intro-content">
              <div style={{ marginTop: "5%" }}>
                <p className="primary-mainheading">Lubricants</p>
              </div>
              <br></br>
              <div className="intro-text-main">
                Lubricants - from Kyros Immpex Private Limited - A trusted brand
                in lubricants and greases in India and International markets.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="intro-section-bottom">
        {lubricants.map((item) => (
          <div
            className="intro-section-info"
            key={item.name}
            style={{
              background: `linear-gradient(rgba(0,0,0,0.3),rgba(0,0,0,0.3)) ,url(${item.img})`,
              backgroundSize: "100% 100%",

              backgroundRepeat: "no-repeat",
            }}
          >
            <div className="overlay">
              <h2>{item.name}</h2>

              <p>{item.text}</p>
              <Link
                className="secondary-button"
                to={item.link}
                style={{ textDecoration: "none", width: "12rem" }}
              >
                Explore More
              </Link>
            </div>
          </div>
        ))}
      </div>
      {/* <div className="intro-section-contents">
        <Link to="/lubricant-automobile">
          <div className="products-intro">Automobile Lubricants</div>
        </Link>
        <Link to="/lubricant-industrial">
          <div className="products-intro">Industrial Lubricants</div>
        </Link>
        <Link to="/lubricant-infra">
          <div className="products-intro">Infrastructure</div>
        </Link>
        <Link to="/lubricant-connect">
          <div className="products-intro">Kryos Connect</div>
        </Link>
        <Link to="/lubricant-center">
          <div className="products-intro">QOC Center</div>
        </Link>
        <Link to="/lubricant-intro">
          <div className="products-intro">Partner with Kryos</div>
        </Link>
        <Link to="/lubricant-testing">
          <div className="products-intro">Proficiency Testing</div>
        </Link>
        <Link to="/lubricant-rd">
          <div className="products-intro">R&D Center</div>
        </Link>
        <Link to="/lubricant-epedia">
          <div className="products-intro">KryosEpedia</div>
        </Link>
      </div> */}
    </div>
  );
}

export default Lubricants;
